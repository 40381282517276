import { useState } from "react";
import { useAuthContext } from "./useAuthContext";



export const useVerify = () => {
   const [success, setSuccess] = useState(null)
   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)
   
   const { user } = useAuthContext();

   //sent otp by email or mobile
   const sendOtp = async (val) => {
      setSuccess(null)
      setError(null)
      setIsLoading(true)

      let url = '';
      if (val === 'email') {
         url = `/api/user/verifyEmail`;
      } else {
         url = `/api/user/verifyMobile`;
      }

      const response = await fetch(url, {
         method: 'POST',
         headers:{
            'Content-Type':'application/json'
         },
         body: JSON.stringify(user )
      })

      const json = await response.json()

      if (!response.ok) {
         setIsLoading(false)
         setError(json.error)
      }
      if (response.ok) {
         setIsLoading(true)
         setSuccess(json.message)
      }
   }


   // check otp
   const verifyOtp = async (otp) => {

      const response = await fetch(`/api/user/verifyOTP`, {
         method: 'POST',
         headers:{
            'Content-Type':'application/json'
         },
         body: JSON.stringify({ user,otp })
      })

      const json = await response.json()

      if (!response.ok) {
         setIsLoading(false)
         setError(json.error)
      }
      if (response.ok) {
         setIsLoading(true)
         setSuccess(json.message)
      }

   }



   return { sendOtp, verifyOtp, isLoading, error, success }
}