import { useState } from "react";
import axios from "axios";
import getBaseUrl from '../../../hooks/http.js'


export const useResetPassword = () => {

   const [success, setSuccess] = useState(null)
   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)


   /*    resetPassword function   */
   const resetPassword = async (referenceValue, password) => {
      setSuccess(null)
      setError(null)
      setIsLoading(true)

      const body = { referenceValue, password }
      axios.post(`${getBaseUrl}/employer/resetPassword`, body)
         .then((res) => {
            console.log(res.data);
            if (res.status === 200) {
               setIsLoading(true)
               setSuccess(res.data.success)
            } else {
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false)
            setError(error.response.data.error);
         })
   }

   return {
      resetPassword, isLoading, error, setError, success,
   }
}