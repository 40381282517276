
import React, { useState, useEffect, useContext } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BsFillBellFill } from 'react-icons/bs';
import { FaAngleDown } from "react-icons/fa";
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import getBaseUrl from "../../hooks/http";
import { CountContext } from '../../context/CountContext';
import Badge from '@mui/material/Badge';

import './employeeNavbar.css';


const EmployeeNavbar = () => {
   const { logout } = useLogout()
   const { user } = useAuthContext()
   const { interviewCount, offerCount, selectedCandidate } = useContext(CountContext);
   const [employer, setEmployer] = useState({
      name: 'ABC',
      photo: '/uploads/userIcon.png'
   })
   const location = useLocation();
   const [isNavbarHidden, setIsNavbarHidden] = useState(false);
   const [isContractSign, setIsContractSign] = useState(false);

   useEffect(() => {
      if (user) {
         const UserName = user?.name || 'ABC';
         const UserPhoto = `${getBaseUrl}/candidates/getMediaFileFromDb/${user?.photo}` || '/uploads/userIcon.png';
         setEmployer({ ...employer, name: UserName, photo: UserPhoto })
      }
      if (user?.Is_AgreementSign == "No" || location.pathname === '/employer/profile/agreement' || location.pathname === '/employer/profile/agreement-sign') {
         setIsNavbarHidden(true);
         setIsContractSign(true);
      } else {
         setIsNavbarHidden(false);
         setIsContractSign(false);
      }
      // setIsNavbarHidden(location.pathname === '/employer/profile/agreement' || location.pathname === '/employer/profile/agreement-sign');
   }, [user, location.pathname])

   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };



   return (
      <div className='sticky-top'>
         <header className='employee_profile-navbar'>
            <Navbar collapseOnSelect expand="xl" bg="" variant="dark">
               <Container>
                  <Link to={isContractSign ? 'agreement' : 'interview'} className='text-decoration-none text-white navbar-brand'>
                     <img src="/images/HelperWish_Logo.svg" className="Helperwishlogo" alt="Logo" />
                     <img src="/images/HelperWish_CompnayName.svg" className="HelperwishlogoText" alt="Company Name" />
                  </Link>

                  <Navbar.Toggle aria-controls="responsive-navbar-nav" className='text-white' />

                  <Navbar.Collapse id="responsive-navbar-nav">
                     <Nav className={`me-auto ms-auto  ${isNavbarHidden ? 'hidden-navbar' : ''} menuNavbar`}>
                        <NavLink to='home' className='mx-3 nav-link'>
                           <Badge badgeContent={0} >
                              <span className="nav-text">Home</span>
                           </Badge>
                        </NavLink>
                        <NavLink to='browse-candidates' className='mx-3 nav-link'>
                           <Badge badgeContent={0} >
                              <span className="nav-text">Browse Candidates</span>
                           </Badge>
                        </NavLink>
                        <NavLink to='interview' className='mx-3 nav-link'>
                           {interviewCount > 0 ? (
                              <Badge badgeContent={interviewCount} color="primary">
                                 <span className="nav-text">Interviews</span>
                              </Badge>
                           ) : <Badge badgeContent={0}>
                              <span className="nav-text">Interviews</span>
                           </Badge>}

                        </NavLink>
                        <NavLink to='offers' className='mx-3 nav-link'>
                           {offerCount > 0 ? (
                              <Badge badgeContent={offerCount} color="primary">
                                 <span className="nav-text">Offers</span>
                              </Badge>
                           ) : <Badge badgeContent={0}>
                              <span className="nav-text">Offers</span>
                           </Badge>}
                        </NavLink>
                        <NavLink to='selected-candidate' className='mx-3 nav-link'>
                           {selectedCandidate > 0 ? (
                              <Badge badgeContent={selectedCandidate} color="primary">
                                 <span className="nav-text">Selected Candidate</span>
                              </Badge>
                           ) : <Badge badgeContent={0}>
                              <span className="nav-text">Selected Candidate</span>
                           </Badge>}
                        </NavLink>
                     </Nav>

                     <Nav className='align-items-lg-center  '>
                        <span className='ms-2 me-3 my-3'>
                           <BsFillBellFill color='#005EAB' className='bell emp-bell' />
                        </span>
                        <div className='mini-profile-wrapper'>
                           <img src={employer?.photo} className='mini-profile-photo' alt="profile"
                              onError={imageOnError} />
                        </div>
                        <div className="dropdown emp-dropdown">
                        <button className="dropbtn  pe-md-0 text-nowrap name">{employer?.name.length > 10 ? `${employer.name.slice(0, 10)}...` : employer?.name} <FaAngleDown /></button>
                           <div className="dropdown-content">
                              <NavLink to='account' className={`dropdown-item ${isNavbarHidden ? 'd-none' : ''}`} >My Account</NavLink>
                              <NavLink to='change-password' className={`dropdown-item ${isNavbarHidden ? 'd-none' : ''}`}>Change Password</NavLink>
                              <a className='dropdown-item cursor-pointer' onClick={() => logout()}>Logout</a>
                           </div>
                        </div>
                     </Nav>
                  </Navbar.Collapse>

               </Container>
            </Navbar>
         </header>
      </div>
   )
}

export default React.memo(EmployeeNavbar);