import React, { useState, useEffect,useContext } from 'react'
import axios from 'axios';
import { io } from 'socket.io-client';
import { MdLocationPin } from 'react-icons/md';
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import Modal from 'react-bootstrap/Modal';
import Datetime from 'react-datetime';
import { TfiEye } from 'react-icons/tfi';
import { AiOutlineClose } from 'react-icons/ai';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import getBaseUrl from '../../hooks/http'
import { CustomModal } from '../customModal';
import { CountContext } from "../../context/CountContext";
import { capitalizeFirstLetter, urlExists } from '../../utils/Helper';
import './candidateCard.css';


const CandidateCard = (props) => {

   const [details, setDetails] = useState(null)
   const [isFileExists, setIsFileExists] = useState(false);
   const [videoUrl, setVideoUrl] = useState("")
   const { updateInterviewCount } = useContext(CountContext);

   //for boot modals ----------------------------
   const [helperData, setHelperData] = useState({
      video: false,
      letter: false,
      refLetter: ''
   })
   const [show, setShow] = useState({
      viewMore: false,
      helper: false
   });
   const handleClose = (modal) => {
      if (modal === 'viewMoreDetailsModal') {
         setShow({ ...show, viewMore: false });
      } else {
         setShow({ ...show, helper: false });
      }
      setIsFileExists(false);
      setHelperData({ video: false, letter: false, refLetter: '' });
   }
   const handleShow = async (modal, data = null, refLetter = null) => {
      if (Modal === 'viewMoreDetailsModal') {
         setShow({ ...show, viewMore: true })
      } else {
         setShow({ ...show, helper: true })
         if (data === 'video') setHelperData({ video: true, letter: false });
         if (data === 'letter') {
            const fileStatus = await checkFileExists(refLetter);
            setIsFileExists(fileStatus);
           let imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${refLetter}`;
            setHelperData({ video: false, letter: true, refLetter:imgView  });
         }
      }
   }
   //--------------------------------------------
   let { candidates } = props.value
   let { setCandidates } = props.onChange

   const checkFileExists = async (refLetter) => {
      try {
         const response = await fetch(refLetter);
         if (response.ok) {
            return true;
         } else {
            return false;
         }
      } catch (error) {
         console.error('Error checking document URL:', error);
         return false;
      }
   };

   //handle Rescheduled Date Change
   const handleRescheduledDateChange = (date, i) => {
      const currentDateTime = new Date();

      const list = [...candidates];
      list[i]['reschedule']['date'] = new Date(date);
      list[i]['reschedule']['error'] = false;
      list[i]['reschedule']['error2'] = false;
      list[i]['reschedule']['error3'] = false;

      setCandidates(list)
   }

   // get position
   const position = (e, sl = false) => {
      const target = (sl) ? e : e.target;
      let bodyRect = document.body.getBoundingClientRect();
      let btnRect = target.getBoundingClientRect();
      //reschedule calendar popup width and height
      const popupWidth = 320;
      const popupHeight = 430;

      const tableWrapper = document.querySelector('.box');
      const tableWrapperReact = tableWrapper.getBoundingClientRect();

      let btnOffsetTop = btnRect.top - bodyRect.top;
      let btnOffsetLeft = btnRect.left - bodyRect.left;

      let scroll = document.documentElement.scrollTop || document.body.scrollTop;
      let top = (btnOffsetTop - popupHeight - 10) - scroll;
      let left = (btnOffsetLeft + (target.offsetWidth / 2) - (popupWidth / 2));

      // if (tableWrapperReact.width < btnRect.left) left = btnRect.left - popupWidth;
      if (btnRect.top < popupHeight) top = (btnRect.top / 2); left = btnRect.left - popupWidth;
      if (btnRect.left < popupWidth + 2) left = 'calc(50% - 160px)';
      if ((tableWrapperReact.width / 2) > popupWidth + 2) left = btnRect.right;


      return { top, left };
   }
   // handle open Rescheduled Date Pickier
   const openRescheduledDatePickier = (e, i) => {

      const { top, left } = position(e);

      const list = [...candidates];
      const scheduleDate = list[i]['reschedule']['date'];
      const currentDateTime = new Date();

      for (let index = 0; index < list.length; index++) {
         if (index !== i) {
            list[index]['reschedule']['isShow'] = false;
            list[i]['reschedule']['error'] = false;
            list[i]['reschedule']['error2'] = false;
            list[i]['reschedule']['error3'] = false;
         }
      }
      list[i]['reschedule']['isShow'] = (list[i].reschedule.isShow) ? false : true;
      list[i]['reschedule']['top'] = top;
      list[i]['reschedule']['left'] = left;
      list[i]['reschedule']['date'] = '';
      setCandidates(list)
   }
   // closeRescheduledDatePickier
   const closeRescheduledDatePickier = (i) => {
      const list = [...candidates];
      list[i]['reschedule']['isShow'] = false;
      list[i]['reschedule']['error2'] = false;
      list[i]['reschedule']['error'] = false;
      list[i]['reschedule']['error3'] = false;
      setCandidates(list)
   }

   // handleRescheduleClick
   const handleRescheduleClick = (i) => {

      const list = [...candidates];

      var preDate = new Date(list[i].date);
      var newDate = new Date(list[i].reschedule.date);
      var currentDate = new Date();
      const timeDifference = newDate - currentDate;
      const timeDifference2 = Math.round((timeDifference / 1000) / 60);

      if (preDate.getTime() === newDate.getTime()) {
         list[i]['reschedule']['error'] = true;
         setCandidates(list);
      } else if (timeDifference2 <= 30) {
         list[i]['reschedule']['error2'] = true;
         setCandidates(list);
      } else if (newDate.toString() === 'Invalid Date') {
         list[i]['reschedule']['error3'] = true;
         setCandidates(list);
      } else {
         list[i]['reschedule']['isShow'] = false;
         setCandidates(list);
         //WA msg
         interviewInvitationToCnd(i);
      }
   }

   //Code added by Namrata
   const handleStatusUpdate = (cndId, status) => {
      const url = `${getBaseUrl}`.replace(/\/api/g, '')
      const socket = io.connect(url);
      socket.emit('employerUpdate', { cndId: cndId, status: status });
      //console.log('employerUpdate');
   };

   // interviewInvitationToCnd to candidate
   const interviewInvitationToCnd = async (index) => {
      try {
         const list = [...candidates];
         const listData = list[index];
         const user = JSON.parse(localStorage.getItem('user'))
         const { token } = user;
         let config = {
            headers: { Authorization: `Bearer ${user.token}`, },
         };
         const res = await axios.post(`${getBaseUrl}/employer/interviewInvitationToCnd`, listData, config);
         if (res.status === 200) {
            //closeRescheduledDatePickier
            closeRescheduledDatePickier(index);
            // inProgress
            list[index]['inProgress'] = 'YES';
            setCandidates(list)
            handleStatusUpdate(listData.candID, 'reshedule')
            try {
               let config = {
                  headers: { Authorization: `Bearer ${user.token}`, },
               };
               const getInterviewsCount=await axios.get(`${getBaseUrl}/interviews/EmployergetCount`, config)
               updateInterviewCount(getInterviewsCount.data.interviewCount)
            }catch(e){

            } 

         }

      } catch (error) {
         console.log(error);
      }
   };

   //window scroll change calendar position
   const handleWindowOnScroll = () => {

      if (candidates !== null) {
         const list = [...candidates];
         const icon = document.querySelectorAll('.btn-scheduleInterview')

         for (let i = 0; i < list.length; i++) {
            const isShow = list[i].reschedule.isShow;
            if (icon.length > 0 && isShow) {
               icon.forEach(async (element, i) => {
                  const { top, left } = await position(element, true);
                  list[i]['reschedule']['top'] = top;
                  list[i]['reschedule']['left'] = left;
                  setCandidates(list);
               });
            }
         }
      }
   };

   //handleViewDetails
   const handleViewDetails = async (i) => {
      const list = [...candidates];
      list[i]["photoPreviewUrl"] = `${getBaseUrl}/candidates/getMediaFileFromDb/${list[i].photo}`;
      list[i]["videoPreviewUrl"] = `${getBaseUrl}/candidates/getMediaFileFromDb/${list[i].video}`;
      list[i]["fullPhotoPreviewUrl"] = `${getBaseUrl}/candidates/getMediaFileFromDb/${list[i].fullPhoto}`;
      setDetails(list[i]);
      setShow({ ...show, viewMore: true })

   }

   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };

   // handle Zoom Effect 
   const handleZoomIn = () => {
      const docViewerImage = document.getElementById('image-img');
      docViewerImage.style.minWidth = `${(docViewerImage.offsetWidth - 20)}px`;
   }

   let count = 1;
   const handleZoomOut = () => {
      const docViewerImage = document.getElementById('image-img');
      if (count <= 5) docViewerImage.style.minWidth = `${(docViewerImage.offsetWidth + 20)}px`; count++;
   }


   useEffect(() => {
      window.addEventListener('scroll', handleWindowOnScroll)
      return () => {
         window.removeEventListener('scroll', handleWindowOnScroll);
      }
   }, [])





   return (
      <div className='box'>
         <div className="row gx-4 gy-1"> 
         {
            candidates && candidates.map((cnd, index) => {
               const cardClass = index % 2 === 0 ? 'even-card' : 'odd-card';
               return (
                  <div className='col-sm-6  mb-2' key={index}>
                  <div className='CandidateCard mb-2'>
                     <div className={`candidate-info ${cnd.reschedule.isShow && 'border-3-blue'}`} >
                        <div className='card-body'>
                           <div className={`d-block d-md-flex description-1 ${cardClass}`}>
                              <div className='profile-wrapper'>
                                 <img src={cnd?.photo ? `${getBaseUrl}/candidates/getMediaFileFromDb/${cnd?.photo}` : '/uploads/userIcon.png'} onError={imageOnError} alt='profile' className='profile' />
                              </div>
                              <div className='flex-grow-1 px-2'>
                                 <p className='name text-center text-md-start'>{cnd.name}</p>
                                 <div className='d-flex justify-content-center justify-content-md-start align-items-center mt-1'>
                                    <p className='availability text-center text-sm-start'>Availability: {cnd.availability}</p>
                                 </div>
                                 <div className='d-flex gap-4 justify-content-center justify-content-md-start align-items-center'>
                                 <p className='age'>Age – {cnd.age}yrs</p>
                                 <p className='country mt-2'>
                                       <MdLocationPin className='location-icon' />{cnd.country}
                                    </p>
                                 </div>
                              </div>
                           </div>
                           <div className='d-flex flex-column flex-md-row description-2'>
                             
                              <div className='summary flex-grow-1  text-md-start'>
                                 <p className=''>Has worked in Singapore? <span>{cnd.hasworkedInSingapore}</span></p>
                                 <p className='skill'> Skills: <span>{cnd.skills.toString()}</span></p>
                                 <p className='cooking'>Cooking: <span>{cnd.cooking.toString()}</span></p>
                                 <p className='exp-salary'>Expected Salary: <span> {cnd.expectedSalary}</span></p>
                              </div>
                              
                           </div>
                           <div className='d-flex justify-content-center'>
                              <button className='btn-red-reshedule btn-scheduleInterview'
                                    onClick={(event) => openRescheduledDatePickier(event, index)}
                                    disabled={(cnd?.inProgress === 'YES') ? true : false}>
                                    {(cnd?.inProgress === 'YES')
                                       ? 'In Progress' : 'Schedule Interview'}
                                 </button>
                                  <button className='btn-red-reshedule btn-viewMore' onClick={() => handleViewDetails(index)}>
                                    View more details
                                 </button>
                                 </div>
                        </div>
                     </div>
                  </div>
                  </div>
               )
            })
         }
         {
            (candidates?.length === 0) && <div className='CandidateCard mb-5'>
               <div className='candidate-info'>
                  <div className='card-body'>
                     <p className='text-center p-5 fw-bold'>Record Not Found.</p>
                  </div>
               </div>
            </div>
         }
         </div>

         {/* reschedule-popup */}
         {candidates && candidates.map((candidates, index) =>
            <CustomModal key={index} isOpen={candidates.reschedule.isShow} onClose={() => closeRescheduledDatePickier(index)}>
               <div className={`reschedule-popup`}
                  style={{ top: candidates.reschedule.top, left: candidates.reschedule.left }}
               >

                  <div className='reschedule-popup-date bg-blue text-white py-2 text-center'>
                     DateTime:<br />
                     {candidates.reschedule.date.toLocaleString('en-GB', {
                        year: 'numeric', month: '2-digit', day: '2-digit',
                        hour: '2-digit', minute: '2-digit', hour12: true
                     }).replace(/00:/g, '12:').replace(/,/g, ' at ')
                     }
                     <br />
                     {
                        `${candidates.reschedule.date}`.substring(
                           `${candidates.reschedule.date}`.indexOf('('),
                           `${candidates.reschedule.date}`.lastIndexOf(')') + 1
                        )
                     }
                  </div>
                  <Datetime
                     className=''
                     value={candidates.reschedule.date}
                     onChange={(date) => handleRescheduledDateChange(date, index)}
                     dateFormat='DD/MM/YYYY'
                     timeFormat='hh:mm A' // Set time format to AM/PM
                     isValidDate={(current) => current.isAfter(new Date()) || current.isSameOrAfter(new Date().setDate(new Date().getDate() - 1))} // Set the minimum date
                     input={false}
                  />
                  {candidates.reschedule.error &&
                     <div className='bg-white text-danger text-center fs-6 mb-2 text-center'>
                        Change date or time.
                     </div>
                  }
                  {candidates.reschedule.error2 &&
                     <div className='bg-white text-danger fs-6 mb-2 text-center'>Time selected is not allowed.</div>
                  }
                  {candidates.reschedule.error3 &&
                     <div className='bg-white text-danger fs-6 mb-2 text-center'>Please select Date and Time.</div>
                  }

                  <div className='d-flex justify-content-center mt-2'>
                     <button className='btn btn-primary btn-sm me-1'
                        onClick={() => handleRescheduleClick(index)}>SCHEDULE
                     </button>
                     <button className='btn btn-secondary btn-sm ms-1'
                        onClick={() => closeRescheduledDatePickier(index)}>
                        cancel
                     </button>
                  </div>
               </div>
            </CustomModal>
         )}



         {/* viewMoreDetailsModal */}
         <Modal size='lg' show={show.viewMore} onHide={() => handleClose('viewMoreDetailsModal')} backdrop='static' id='viewMoreDetailsModal'>
            <Modal.Body className='p-0 '>
               <div className='position-relative'>
                  <AiOutlineClose onClick={() => handleClose('viewMoreDetailsModal')}
                     className='icon close-icon position-absolute' />
               </div>

               <div className='candidate-details pb-5'>
                  <div className='description-1 d-lg-flex '>
                     <div className='profile-wrapper d-block'>
                        <img src={details?.photoPreviewUrl} onError={imageOnError}
                           alt='profile' className='profile cursor-pointer' onClick={() => handleShow('helperModal', 'letter', `${details?.photo}`)} />
                     </div>
                     <div className='flex-grow-1 d-flex flex-column justify-content-between mt-2 mt-lg-0 p-1'>
                        <p className='name text-center text-lg-start '>{details?.name}</p>
                        <div className='d-lg-flex'>
                           <p className='dob me-lg-5 text-center text-lg-start'>
                              <span className='text-nowrap' > Date of Birth :</span>
                              <span className='text-nowrap'>{details?.dob}</span>
                           </p>
                           <p className='age text-center text-lg-start'>Age :{details?.age} years</p>
                        </div>
                     </div>
                  </div>

                  <div className='description-2 px-3'>
                     <p className='nationality'>Nationality : <span>{details?.nationality}</span></p>
                     <p className='availability'> Availability : <span>{details?.availability}</span></p>
                     <p className='maritalStatus'>Marital Status: <span>{details?.maritalStatus}</span></p>
                     <div>
                        <p>No. of kids : <span>{details?.childAge.length}</span> </p>
                        {
                           details?.childAge &&
                           <div className='d-flex flex-wrap justify-content-center justify-content-sm-start '>
                              {
                                 details?.childAge.map((childAge, index) => (
                                    <p className='ms-2' key={index}>
                                       kid{index + 1}: <span>{childAge}yrs</span>
                                    </p>
                                 ))
                              }
                           </div>
                        }
                     </div>
                  </div>

                  <div className='workDetails '>
                     <h3 className='px-3'>Work Details:</h3>
                     <div className='d-lg-flex flex-row-reverse justify-content-end px-3 gap-50'>
                        <div className='video-wrapper text-center d-flex flex-column'>
                           {
                              (details?.video) ? <>
                                 <video src={details?.videoPreviewUrl} className='cursor-pointer border border-secondary  mx-auto mx-lg-0 mb-2 mt-auto' />
                                 <button className='btn btn-sm btn-primary mt-auto align-self-center' onClick={() => handleShow('helperModal', 'video')}>View Video</button>
                              </>
                                 : <img src='/images/record-icon.png' onError={imageOnError} alt='profile' />
                           }
                        </div>
                        <div className='video-wrapper d-flex flex-column align-items-center'>
                           {<img src={details?.fullPhotoPreviewUrl} onError={imageOnError} alt='profile' className='cursor-pointer  mx-auto mb-2' />}
                           <button className='btn btn-sm btn-primary mt-auto' onClick={() => handleShow('helperModal', 'letter', `${details?.fullPhoto}`)}>View Photo</button>
                        </div>
                        <div>
                           <p className='location'>
                              <span className='text-nowrap me-1'>Present Location : </span>
                              <span className='text-nowrap'>{details?.country || '-'}</span>
                           </p>
                           <p className='experience'>
                              <span className='text-nowrap text-dark me-1'>Total Experience :</span>
                              <span className='text-nowrap'>
                                 {details?.yearOfExperience.year || 0} Years {details?.yearOfExperience.month || 0} Months
                              </span>
                           </p>
                           <p className='singaporeExperience'>
                              <span className='text-nowrap text-dark me-1'>Singapore Experience :</span>
                              <span className='text-nowrap'>
                                 {details?.singaporeExperience?.year || 0} Years {details?.singaporeExperience?.month || 0} Months
                              </span>
                           </p>
                        </div>
                     </div>
                     {(details?.workExperience.length > 0) &&
                        <div className='table-responsive px-3'>
                           <Table className='table table-bordered mt-3'>
                              <Thead className='table-dark text-center'>
                                 <Tr>
                                    <Th>Period</Th>
                                    <Th>Country</Th>
                                    <Th>Duties</Th>
                                    <Th className='text-nowrap'>Any Ref.Letter</Th>
                                 </Tr>
                              </Thead>
                              <Tbody className='text-center'>
                                 {
                                    details?.workExperience.map((workExperience, index) => (
                                       <Tr key={index}>
                                          <Td className='period tbl-td '>
                                             <span className='text-nowrap'>
                                                {
                                                   new Date(workExperience.from).toLocaleString('en-GB', {
                                                      year: 'numeric', month: 'short'
                                                   }).replace(/ /g, '-')
                                                }
                                             </span>
                                             <span className='mx-2 text-nowrap'>to</span>
                                             <span className='text-nowrap'>
                                                {
                                                   (workExperience.present) ? 'Present' : new Date(workExperience.to).toLocaleString('en-GB', {
                                                      year: 'numeric', month: 'short'
                                                   }).replace(/ /g, '-')
                                                }
                                             </span>
                                          </Td>
                                          <Td className='tbl-td'>{capitalizeFirstLetter(workExperience.country) || '-'}</Td>
                                          <Td>{workExperience.duties.toString() || '-'}</Td>
                                          <Td className='tbl-td'>
                                             {
                                                (workExperience?.refLetter) ?
                                                   <>
                                                      <span className='eye-icon mx-2 cursor-pointer' onClick={() => handleShow('helperModal', 'letter', `${workExperience?.refLetter}`)}>
                                                         <TfiEye />
                                                      </span>
                                                   </>
                                                   : 'No'
                                             }

                                          </Td>
                                       </Tr>
                                    ))
                                 }
                              </Tbody>
                           </Table>
                        </div>
                     }
                  </div>

                  <div className='skills'>
                     <h3 className='px-3'>Skills/Duties:</h3>
                     <p className='px-3'>
                        <span className='text-nowrap me-1'>Languages: </span>
                        <span>{details?.languages.toString().replace(/,/g, ', ') || '-'}</span>
                     </p>
                     <p className='px-3'>
                        <span className='text-nowrap me-1'>Skills :  </span>
                        <span>{details?.skills.toString().replace(/,/g, ', ') || '-'}</span>
                     </p>
                     <p className='px-3'>
                        <span className='text-nowrap me-1'>Personality :</span>
                        <span>{details?.personality.toString().replace(/,/g, ', ') || '-'}</span>
                     </p>
                     <p className='px-3'>
                        <span className='text-nowrap me-1'>Cooking : </span>
                        <span>{details?.cooking.toString().replace(/,/g, ', ') || '-'}</span>
                     </p>
                  </div>
               </div>
            </Modal.Body>
         </Modal>


         {/* helpermodal for video and ref.letter   */}
         <Modal show={show.helper} onHide={() => handleClose('helperModal')} backdrop='static' id='helperModal'
            aria-labelledby='contained-modal-title-vcenter' centered dialogClassName={`w-min-content ${(helperData?.refLetter?.includes('pdf')) ? 'pdf' : ''}`}
         >
            <Modal.Body className='p-0 justify-content-end min-h-100'>
               <div className='position-relative'>
                  <AiOutlineClose onClick={() => handleClose('helperModal')}
                     className='icon close-icon position-absolute' />
               </div>
               <div>
                  {(helperData?.letter && (!helperData?.refLetter?.includes('pdf'))) &&
                     <div className='d-flex justify-content-center gap-10 p-3'>
                        <button className='btn-zoom zoon-in' onClick={handleZoomIn}><FiZoomOut /> </button>
                        <button className='btn-zoom zoom-out' onClick={handleZoomOut}><FiZoomIn /></button>
                     </div>
                  }
                  {
                     helperData?.video && <video src={details?.videoPreviewUrl} controls className='w-100'></video>
                  }

                  {(helperData?.letter) && <DocViewer
                     className="doc-viewer"
                     documents={[{ uri: helperData?.refLetter, fileName: 'Reference_letter', }]}
                     pluginRenderers={DocViewerRenderers}
                     config={{
                        header: {
                           disableHeader: true,
                           disableFileName: true,
                           retainURLParams: true,
                        }
                     }} />
                  }
                  {/*((!isFileExists) && (helperData?.letter)) && <p className='text-center p-3'>Error: Failed to load the document.</p>*/}
               </div>
            </Modal.Body>
         </Modal>
      </div>
   )
}

export default React.memo(CandidateCard);