import React from 'react'
import { NavLink } from 'react-router-dom'


const MultiTabError = () => {
   return (
      <div className='errorPage d-flex flex-column justify-content-center align-items-center h-100'>
         <h1 className='text-center p-2'>The required action is not allowed.</h1>
         <NavLink to='/' className='btn btn-primary' role='button'>Back To Home</NavLink>
      </div>
   )
}

export default MultiTabError;