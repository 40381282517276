import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import getBaseUrl from '../../../../hooks/http';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { Slider } from '../../../../components/slider/';
import './empHome.css';



const EmpHome = () => {

   const { user } = useAuthContext();
   const [interviews, setInterviews] = useState([])
   

   //fetch interviews data -------------------
   const getInterviews = async () => {
      try {
         if (user !== null) {
            let config = {
               headers: {
                  Authorization: `Bearer ${user.token}`,
               },
            };
            const response = await axios.get(`${getBaseUrl}/interviews/employer/`, config)
            console.log(response.data);
            if (response.status === 200) {
               const data = await response.data.interviewData;

               if (data) {
                  const interviewsData = data.map(dt => {
                     const InterDate = new Date(dt.Interview_Date_Time);
                     const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                     const newDateTime = InterDate.toLocaleString('en-US', { timeZone: `${timezone}` })
                     const Interview_Date_Time = (newDateTime) ? new Date(newDateTime) : '';
                     return {
                        id: dt?._id,
                        date: Interview_Date_Time,
                        reschedule: {
                           date: new Date(Interview_Date_Time),
                           isShow: false
                        },
                        status: dt?.Interview_Status,
                        EmpId: localStorage.getItem('empId'),
                        candID: dt?.Candidate_UserID,
                        candidate_id: localStorage.getItem('CandidateId'),
                        empTempId: dt?.Employer_TempId,
                        candidateName: dt?.candidateDetails[0]?.name.data ?? 'Unknown'
                     }
                  });

                  setInterviews(interviewsData)
               }
            }
         }
      } catch (error) {
         console.log(error.message)
      }
   }

   useEffect(() => {
      if (user !== null) {
         getInterviews();
      }
   }, [user])







   return (<>
      <div className="container">
         <div className='emp-home'>
            <div className="carousel-wrapper box-shadow-bottom">
               <Slider />     
            </div>

            <div className='current-interview py-4'>
               <h3 className='text-center'>Current Interviews in Progress</h3>
               <div className="row">
                  <div className="col">
                     <div className="table-responsive interview-list candidate_interview-list box">

                        <Table className="table table-striped m-0">
                           <Thead className='table-dark text-center'>
                              <Tr>
                                 <Th className='py-3' scope="col">#</Th>
                                 <Th></Th>
                                 <Th className='py-3' scope="col">Candidate</Th>
                                 <Th className='py-3' scope="col">Interview on</Th>
                                 <Th className='py-3' scope="col">Status</Th>

                              </Tr>
                           </Thead>
                           <Tbody className='text-center'>
                              {interviews.map((interview, index) =>
                                 <Tr key={index} className={
                                    `${interview.reschedule.isShow && 'active'}`
                                 }>
                                    <Td className='px-3'>{index + 1}</Td>
                                    <Td>
                                       <div className="profile-picture">
                                          {interview.candidateName.charAt(0).toUpperCase()}
                                       </div>
                                    </Td>
                                    <Td>{interview.candidateName.length > 20 ? `${interview.candidateName.slice(0, 20)}...` : interview.candidateName}</Td>
                                    <Td className='interview-on'>
                                       {
                                          interview.date.toLocaleString('en-GB', {
                                             year: 'numeric', month: '2-digit', day: '2-digit',
                                             hour: '2-digit', minute: '2-digit', hour12: true
                                          }).replace(/,/g, ' on ').replace(/00:/g, '12:')
                                       }
                                       <br />
                                       {
                                          `${interview.date}`.substring(
                                             `${interview.date}`.indexOf("("),
                                             `${interview.date}`.lastIndexOf(")") + 1
                                          )
                                       }
                                    </Td>
                                    <Td className='status'>{interview.status}</Td>

                                 </Tr>
                              )}

                              {
                                 (interviews.length === 0) && <Tr>
                                    <Th colSpan='6'>Record Not Found.</Th>
                                 </Tr>
                              }
                           </Tbody>
                        </Table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </>

   )
}

export default EmpHome