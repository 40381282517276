
import { useState } from "react";
import axios from 'axios'


export const useIpDetails = () => {

   const [ip, setIp] = useState('')
   const [countryCallingCode, setCountryCallingCode] = useState('')
   const countries = [
      {
         "name": "Singapore",
         "dial_code": "+65",
         "code": "SG",
         "mobileLength ":"8"
      },
      {
         "name": "India",
         "dial_code": "+91",
         "code": "IN",
         "mobileLength ":"10"
      },
      {
         "name": "Sri Lanka",
         "dial_code": "+94",
         "code": "LK"
      },
      {
         "name": "Myanmar",
         "dial_code": "+95",
         "code": "MM"
      },
      {
         "name": "Indonesia",
         "dial_code": "+62",
         "code": "ID"
      }
   ]

   const getUserIpDetails = async () => {
      
      //const res = await axios.get('https://geolocation-db.com/json/')
      /*const res = await axios.get('https://ipapi.co/json/')*/
        const res= await axios.get("https://api.country.is/")
      setIp(res.data.ip)
      // the code you're looking for
      const code = res.data.country;
      // iterate over each element in the array onject
      for (let i = 0; i < countries.length; i++) {
         // look for the entry with a matching `code` value
         if (countries[i].code === code) {
            // we found it
            setCountryCallingCode(countries[i].dial_code)
            break;
         }
      }
   }

   return { getUserIpDetails, ip, countryCallingCode, setCountryCallingCode, countries }
}