import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";






function AuthenticationLayout() {
   const navigate = useNavigate();
   const location = useLocation();
   const [activeForm, setActiveForm] = useState({
      login: false,
      register: false,
   });

   useEffect(() => {
      setActiveForm(previousState => {
         return { ...previousState, register: false }
      });
      
      const rightSiderArr = [
         '/candidate/register',
         '/candidate/send-verification-code',
         '/candidate/verify-code',
         '/candidate/verification-success'
      ]


      if (rightSiderArr.includes(`${location.pathname}`)) {
         setActiveForm(previousState => {
            return { ...previousState, register: true }
         });
      }

      setActiveForm(previousState => {
         return { ...previousState, login: false }
      });
      if (`${location.pathname}` === `/candidate/login`) {
         setActiveForm(previousState => {
            return { ...previousState, login: true }
         });
      }
   }, [location])




   return (
      <>
         <div className='container'>
            <div className='authentication-container h-100 d-flex flex-column justify-content-center'>
               <div className='authentication-wrapper px-0 px-lg-5 py-5'>
                  <div className='authentication-info h-100 d-flex flex-wrap '>
                     <div className='info login-info text-center pe-0 pe-lg-5'>
                        <p className='fs-4'>I have an Account</p>
                        <button className={`btn btn-outline-light m-2 show-login 
                           ${(activeForm.login ? 'btn-active' : '')}
                        `}
                           onClick={() => { navigate("/candidate/login"); }}>
                           Login
                        </button>
                     </div>
                     <div className='info register-info text-center ps-0 ps-lg-5'>
                        <p className='fs-4'>Don't have an Account ?</p>
                        <button className={`btn btn-outline-light m-2 show-register
                           ${(activeForm.register ? 'btn-active' : '')}
                        `}
                           onClick={() => { navigate("/candidate/register"); }}>
                           Sign Up
                        </button>
                     </div>



                     <div className={
                        `white-panel px-4 px-sm-5 py-4 ${(activeForm.register ? 'register-active' : '')}`
                     }>
                        
                        <Outlet />
                        
                     </div>
                  </div>


               </div>
            </div>
         </div>
      </>
   )


}

export default AuthenticationLayout;