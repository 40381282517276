// Function to generate OTP
const generateOTP = (len) => {
   // Declare a digits variable which stores all digits
   var digits = '0123456789';
   let OTP = '';
   for (let i = 0; i < len; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
   }
   return OTP;
}

export default generateOTP