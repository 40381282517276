import { useState } from "react";
import getBaseUrl from '../../../hooks/http.js'
import axios from "axios";



export const useVerifyCode = () => {

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)
   const [success, setSuccess] = useState(null)


   const register = async (name,dialingCode,phoneNumber, password) => {

      setSuccess(null)
      setError(null); 
      setIsLoading(true)

      phoneNumber = phoneNumber.replace(/[+()]/g, "");

      const body = { name,dialingCode, phoneNumber, password }
      
      axios.post(`${getBaseUrl}/candidates/register`, body)
         .then((res) => {
            if (res.status === 200) {
               setSuccess(true)
               //remove localStorage
               localStorage.removeItem("otp");
               localStorage.removeItem('dialingCode')
               localStorage.removeItem('phoneNumber')
               localStorage.removeItem('password')
               localStorage.removeItem('Name')
               //redirect verification success
               // navigate('/candidate/verification-success')
            } else {
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            console.log(error);
         })
   }

   return { register, error, isLoading, success }
}