import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import getBaseUrl from '../../../hooks/http';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { TfiEye } from "react-icons/tfi";
import { AiOutlineClose } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';


import './adminOffer.css'




const AdminOffer = () => {
  const { user } = useAuthContext();

  const [offers, setOffers] = useState([]);
  const [offersHistory, setOffersHistory] = useState([]);
  const [offerPdfViewer, setOfferPdfViewer] = useState({
    show: false,
    url: '',
    fullscreen: false,
  })

  useEffect(() => {
    getOffers();
  }, [user])

  /*-----------------------------------------------*/
  const getOffers = async () => {
    if (user !== null) {

      let config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      try {
        const response = await axios.get(`${getBaseUrl}/admin/offers`, config);
        console.log(response.data);
        if (response.status === 200) {
          const data = await response.data.offers;

          const offersData = data.map(dt => {
            //convert interview date time to user  local timezone
            const dateTime = new Date(dt.interview_date_time);
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const newDateTime = dateTime.toLocaleString('en-US', { timeZone: `${timezone}` })
            const interview_date_time = (newDateTime) ? new Date(newDateTime) : '';

            return {
              id: dt._id,
              date: interview_date_time,
              status: dt.status,
              pdf: dt.offerPdf,
              empId: dt.employer_id,
              cndName: dt.candidateDetails[0].name,
              empName: dt.employerDetails[0].name,
            }
          });

          setOffers(offersData);

        }
      } catch (error) {
        console.log(error)
      }
    }
  }


  //handle offer pdf 
  const handleOfferPdf = (offerData) => {
    const offerPdf = offerData.pdf;
    const employerId = offerData.empId;
    const filePath = `/public/${employerId}/offers/${offerPdf}`;
    setOfferPdfViewer({ ...offerPdfViewer, show: true, url: filePath });
  }



  const dateFormating = (date) => {
    return <>
      {
        date.toLocaleString('en-GB', {
          year: 'numeric', month: '2-digit', day: '2-digit',
          hour: '2-digit', minute: '2-digit', hour12: true
        }).replace(/,/g, ' on ').replace(/00:/g, '12:')
      }
      <br />
      {
        `(${`${date}`.split(/[()]/)[1]})`
      }
    </>
  }

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'empName',
        header: 'Employer Name',
        size: 150,
      },
      {
        accessorKey: 'cndName',
        header: 'Candidate Name',
        size: 150,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 200,
        filterVariant: 'multi-select',
        filterFn: (row, id, filterValue) => {
          return (filterValue.length > 0) ? filterValue.includes(row.getValue(id)) : ''
        },
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.date),
        header: 'Interview Conducted on',
        filterVariant: 'datetime-range',
        Cell: ({ cell }) => (
          <>{dateFormating(cell.getValue())}</>
        ),
      },
      {
        header: 'Action',
        size: 200,
        Cell: ({ row }) => (
          <div className='icon icon-view d-inline-flex flex-column' onClick={() => handleOfferPdf(row.original)}>
            <TfiEye className='fs-4' />
            <span className="text">View OFFER</span>
          </div>
        ),
      }
    ],
    [],
  );

  const table = useMaterialReactTable({
    data: offers,
    columns,
    enableFacetedValues: true,
    initialState: { showColumnFilters: true },
    columnFilterDisplayMode: 'popover',
    muiTableHeadCellProps: { align: 'center', },
    muiTableBodyCellProps: { align: 'center', },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(4n) > td': {
          backgroundColor: '#ccc',
        },
        '& tr:nth-of-type(4n - 1) > td': {
          backgroundColor: '#ccc',
        },
      },
    },

  });




  return (
    <div className='adminOffer'>
      <div className="content">
        <h3 className='title mb-4'>Offers</h3>
        <div className="offerList container">
          <MaterialReactTable table={table} />
        </div>
      </div>


      {/* viewModal   */}
      < Modal fullscreen={offerPdfViewer.fullscreen} show={offerPdfViewer.show} onHide={() => setOfferPdfViewer({ ...offerPdfViewer, show: false })}
        backdrop="static" id='viewModal' aria-labelledby="contained-modal-title-vcenter" centered scrollable dialogClassName="w-min-content">
        <Modal.Header className='p-0 justify-content-end'>
          <div className="position-relative">
            <AiOutlineClose onClick={() => setOfferPdfViewer({ ...offerPdfViewer, show: false })}
              className='icon close-icon position-absolute' />
          </div>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div>
            {
              (typeof offerPdfViewer.url === 'string' && offerPdfViewer.url.includes('.pdf')) ?
                <div className=''>
                  <DocViewer
                    documents={[{ uri: offerPdfViewer.url }]}
                    pluginRenderers={DocViewerRenderers}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: true,
                      }
                    }}
                  />
                </div>
                : <p className='text-center p-2'>File Not Found.</p>

            }
          </div>
        </Modal.Body>
      </Modal >




    </div >
  )
}

export default AdminOffer