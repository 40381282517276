import React from 'react'
import { NavLink } from 'react-router-dom'


const LoginError = () => {
   return (
      <div className='errorPage d-flex flex-column justify-content-center align-items-center h-100'>
         <h1 className='text-center p-2'>Your are already log in.</h1>
         <NavLink to='/' className='btn btn-primary' role='button'>Back To Home</NavLink>
      </div>
   )
}

export default LoginError;