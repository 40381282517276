import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import getBaseUrl from '../../../hooks/http';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Typography } from '@mui/material';

import './adminInterview.css'






const AdminInterview = () => {
  const { user } = useAuthContext();

  const [interviews, setInterviews] = useState([]);
  const [interviewsHistory, setInterviewsHistory] = useState([]);
  useEffect(() => {
    getInterviews();
  }, [user])

  /*-----------------------------------------------*/
  const getInterviews = async () => {
    if (user !== null) {

      let config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      try {
        const response = await axios.get(`${getBaseUrl}/admin/interviews`, config);

        if (response.status === 200) {
          const data = await response.data.interviews;
          console.log(data);
          const interviewsData = data.map(dt => {
            //convert interview date time to user  local timezone
            const dateTime = new Date(dt.Interview_Date_Time);
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const newDateTime = dateTime.toLocaleString('en-US', { timeZone: `${timezone}` })
            const Interview_Date_Time = (newDateTime) ? new Date(newDateTime) : '';

            return {
              id: dt._id,
              date: Interview_Date_Time,
              status: dt.Interview_Status,
              cndName: dt.candidateDetails[0]?.name || '',
              empName: dt.employerDetails[0]?.name || '',
              empReqNo: dt.Employer_Request_No,
              cndReqNo: dt.Candidate_Request_No,
              isInterviewSet: dt.Is_InterviewSet,
              isHostRequired: dt.Is_HostRequired,
              interviewLink: dt.Interview_Link,

            }

          });

          setInterviews(interviewsData);

        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const dateFormating = (date) => {
    return <>
      {
        date.toLocaleString('en-GB', {
          year: 'numeric', month: '2-digit', day: '2-digit',
          hour: '2-digit', minute: '2-digit', hour12: true
        }).replace(/,/g, ' on ').replace(/00:/g, '12:')
      }
      <br />
      {
        `(${`${date}`.split(/[()]/)[1]})`
      }
    </>
  }

  const joinMeeting = (row) => {
    // console.log(row.original.date);
    const currentTime = new Date();
    const scheduleDate = new Date(row.original.date);
    const dateDiff = currentTime - scheduleDate;
    const timeDiff = Math.round((dateDiff / 1000) / 60);
    console.log("timeDiff : ", timeDiff);
    //display join meeting button before 30 minute and after 3o minutes of scheduled time
    return <>
      {
        ((timeDiff > -15) && (timeDiff < 30)) ? <a className="btn btn-sm" href={`https://teams.microsoft.com/l/meetup-join/${row.original.interviewLink}`} role="button" target="_blank" style={{ background: '#95D03A', color: '#fff' }} rel="noopener noreferrer">Join Meeting</a>
          : null
      }
    </>
  }

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'empName',
        header: 'Employer Name',
        size: 150,
      },
      {
        accessorKey: 'cndName',
        header: 'Candidate Name',
        size: 150,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 200,
        filterVariant: 'multi-select',
        filterFn: (row, id, filterValue) => {
          return (filterValue.length > 0) ? filterValue.includes(row.getValue(id)) : ''
        },
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.date),
        header: 'Date',
        filterVariant: 'datetime-range',
        Cell: ({ cell, row }) => (
          <>
            {dateFormating(cell.getValue())}
            {joinMeeting(row)}
          </>
        ),
      }
    ],
    [],
  );

  const table = useMaterialReactTable({
    data: interviews,
    columns,
    enableExpandAll: false,
    enableExpanding: true,
    enableFacetedValues: true,
    initialState: { showColumnFilters: true },
    columnFilterDisplayMode: 'popover',
    muiTableHeadCellProps: { align: 'center', },
    muiTableBodyCellProps: { align: 'center', },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(4n) > td': {
          backgroundColor: '#ccc',
        },
        '& tr:nth-of-type(4n - 1) > td': {
          backgroundColor: '#ccc',
        },
      },
    },
    renderDetailPanel: ({ row }) =>
      row.original.empName ? (
        <Box
          sx={{
            display: 'grid',
            margin: 'auto',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
          }}
        >
          <Typography>Emp. Req. No: {row.original.empReqNo}</Typography>
          <Typography>Cnd. Req. No: {row.original.cndReqNo}</Typography>
        </Box>
      ) : null,

  });



  return (
    <div className='adminInterview container'>
      <div className="content">
        <h3 className='title mb-4'>Interview</h3>
        <div className="interviewList container">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div >
  )
}

export default AdminInterview