import React from 'react'
import { useNavigate } from "react-router-dom";


const Home = () => {
  let navigate = useNavigate();

  return (
    <>
      <div className='Home'>
        <div className='container'>
          <div className='d-flex justify-content-center align-items-center login-btn-wrapper'>
            <div className='d-flex flex-wrap justify-content-center w-100'>
              <button type="button" className="btn btn-red btn-candidate-login m-4"
                onClick={() => navigate("/candidate/login")}>
                Candidate Login
              </button>
              <button type="button" className="btn btn-blue btn-employer-login m-4"
                onClick={() => navigate("/employer/login")}>
                Employer Login
              </button>
              <div className='d-flex flex-wrap justify-content-center w-100'>
                <button type="button" className="btn btn-warning btn-admin-login m-4"
                  onClick={() => navigate("/admin")}>
                  Omniwish Login
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
