import React, { useEffect } from 'react'

import OTPInput from "otp-input-react";
import { useState } from 'react';
import { BsWhatsapp } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
import { useNavigate } from 'react-router-dom';
import { useWhatsAppHelper } from '../../../hooks/useWhatsAppHelper';
import { useMailHelper } from '../../../hooks/useMailHelper';



function VerifyCode() {
   const navigate = useNavigate()
   const { sendMsg } = useWhatsAppHelper();
   const { sendMail } = useMailHelper();

   const [error, setError] = useState(null)
   const [OTP, setOTP] = useState('');
   const [phoneNumber, setPhoneNumber] = useState('')
   const [email, setEmail] = useState('')

   useEffect(() => {
      const regex = /^\d+$/;
      let sentOtpToValue = localStorage.getItem("sentOtpTo");

      if (regex.test(sentOtpToValue)) {
         setPhoneNumber(localStorage.getItem("phoneNumber"));
      } else {
         setEmail(localStorage.getItem("email"));
      }
   }, [])



   //handle verify click
   const handelVerifyClick = () => {
      const confirm_otp = localStorage.getItem("otp");

      if (OTP === '') {
         setError('OTP is required.')
      } else {
         if (confirm_otp === OTP) {
            navigate('/employer/reset-password')
         } else {
            setError('Invalid OTP.')
         }
      }
   }

   // Function to generate OTP
   const generateOTP = (len) => {
      // Declare a digits variable which stores all digits
      var digits = '0123456789';
      let OTP = '';
      for (let i = 0; i < len; i++) {
         OTP += digits[Math.floor(Math.random() * 10)];
      }
      return OTP;
   }

   //handle resend click
   const handelResendClick = () => {
      const regex = /^\d+$/;
      const sentOtpToValue = localStorage.getItem("sentOtpTo");
      //get otp
      const otp = generateOTP(6)
      //store the otp to localstorage
      localStorage.setItem('otp', otp)
      //check sentOtpToValue is num or mail id
      if (regex.test(sentOtpToValue)) {
         // send otp through whatsapp
         sendMsg(sentOtpToValue, otp);
      } else {
         // send otp through mail
         sendMail(sentOtpToValue, otp)
      }
   }

   return (
      <div className='EmpverifyCode text-center'>
         <h2 className='mb-4 text-center text-dark'>Reset Password</h2>
         <h3 className='title mb-4'>Enter verification code</h3>
         <p className='message'>
            Your verification code is sent by {(phoneNumber) ? 'WhatsApp' : 'Email'} to
            <br />
            {(phoneNumber) ? <BsWhatsapp color="#25D366" /> : <TfiEmail color="#25D366" />}
            &nbsp;
            {(phoneNumber) ? phoneNumber : email}
         </p>

         <div className="d-flex justify-content-center my-5 otp-wrapper h4">
            <OTPInput value={OTP} onChange={setOTP} autoFocus="true" OTPLength={6}
               otpType="number" disabled={false} />
         </div>
         {error && <p className='text-danger text-center'>{error}</p>}
         <p className='resend-msg'>
            Did not receive the code?
            <span className='resend-span px-2'>
               <button onClick={handelResendClick}>Resend</button>
            </span>
         </p>
         <button className='btn btn-blue btn-verify' onClick={handelVerifyClick}>VERIFY</button>
      </div>
   )
}

export default VerifyCode;