const getBaseUrl = () => {
  
  let url;
  if (`${process.env.NODE_ENV}` === 'production') {
    url = `${process.env.REACT_APP_API_PROD_URI}/api`;
  } else {
    url = `${process.env.REACT_APP_API_DEV_URI}/api`;
  }
  console.log(process.env.NODE_ENV);
  return url;
}

export default getBaseUrl();
