import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SendOtpByEmail from './SendOtpByEmail';
import SendOtpByWhatsApp from './SendOtpByWhatsApp';



const VerificationCode = () => {
   const [byWhatsApp, setByWhatsApp] = useState(false)
   const [byEmail, setByEmail] = useState(false)

   useEffect(() => {
      const sentOtpTo = localStorage.getItem("sentOtpTo");
      const regex = /^\d+$/;
      
      if(regex.test(sentOtpTo)){
         setByWhatsApp(true)
      }else{
         setByEmail(true)
      }
   }, [])


  return (
    <div className='employee__resetPassword__verificationCode'>

      {byWhatsApp && <SendOtpByWhatsApp/>}
      {byEmail && <SendOtpByEmail/>}
    </div>
  )
}

export default VerificationCode