import React from 'react'
import { Outlet } from 'react-router-dom'
import { CandidateNavbar } from '../components/candidateNavbar'
import { useNavigate } from "react-router-dom";

const CandidateLayout = () => {
   const naviagte = useNavigate();
   const handleClick = (e) => {
      let Sessiondata = sessionStorage.getItem("ssid");
      const usr = JSON.parse(localStorage.getItem('user'))
      if(usr?.sessionId!==Sessiondata)
      {
         naviagte('/MultiBrowserError')
        // return <Navigate to={"/"} />;
      }
     
    }
   return (
      <div className='candidateLayout bg-white h-100' onClick={handleClick}>
         <CandidateNavbar/>
         <div className='CandidateBody'>
            <Outlet/>
         </div>
      </div>
   )
}

export default CandidateLayout