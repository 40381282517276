import React, { useState } from 'react'
import { useResetPassword } from './useResetPassword';
import { useNavigate } from 'react-router-dom';
import { TfiEye } from "react-icons/tfi";
import { FaRegEyeSlash } from "react-icons/fa";
import "./resetPassword.css";



function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword, isLoading, error, setError, success } = useResetPassword()

  //hide/show inp text
  const [isShown, setIsSHown] = useState({
    password: false,
    cPassword: false
  });
  const togglePassword = (field) => {
    if (field === 'password') {
      setIsSHown({ ...isShown, password: !isShown.password });
    }
    if (field === 'cPassword') {
      setIsSHown({ ...isShown, cPassword: !isShown.cPassword });
    }
  };
  /*-------------------------------------------------------------*/

  const [state, setState] = useState({
    password: "",
    confirmPassword: ""
  })

  //handle input change
  const handleInputChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  //handle submit click
  const handleSubmit = (e) => {
    e.preventDefault();

    const referenceValue = localStorage.getItem("sentOtpTo");
    const { password, confirmPassword } = state

    if (password === confirmPassword) {
      resetPassword(referenceValue, password)
    } else {
      setError('The password confirmation does not match.')
    }
  }


  return (
    <div className='resetpassword-wrapper'>
      {(!success) ?
        <div className='resetpassword-form-wrapper'>
          <h2 className='mb-4 text-center'>Reset Password</h2>
          <form className='form-resetpassword' onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="d-flex">
                <div className="floating-control m-0 mb-1">
                  <input type={isShown.password ? "text" : "password"} name="password" id="password" className="floating-input"
                    placeholder="none" autoComplete='off' required
                    onChange={handleInputChange} value={state.password} />
                  <label htmlFor="password" className="floating-label">New Password</label>
                </div>
                <span className="input-group-text fs-4 cursor-pointer" onClick={() => togglePassword('password')}>
                  {isShown.password ? <FaRegEyeSlash /> : <TfiEye />}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex">
                <div className="floating-control m-0 mb-1">
                  <input type={isShown.cPassword ? "text" : "password"} name="confirmPassword" id='confirmPassword' className="floating-input"
                    placeholder="none" autoComplete='off' required
                    onChange={handleInputChange} value={state.confirmPassword} />
                  <label htmlFor="confirmPassword" className="floating-label">Confirm Password</label>
                </div>
                <span className="input-group-text fs-4 cursor-pointer" onClick={() => togglePassword('cPassword')}>
                  {isShown.cPassword ? <FaRegEyeSlash /> : <TfiEye />}
                </span>
              </div>
            </div>

            {error && <p className='text-danger text-center'>{error}</p>}
            {success && <div className='success'>{success}</div>}

            <div className='mt-1 mb-3 text-center'>
              <button disabled={isLoading} type="submit" className="btn btn-blue">
                Reset password
              </button>
            </div>
          </form>
        </div>
        :
        <div className='employee__resetPassword__success'>
          <p>
            Password Reset Successfully !! <br />
            Please Login to your account
          </p>
          <button type="button" className="btn btn-blue"
            onClick={() => { navigate('/employer/login') }}
          >
            ok
          </button>
        </div>
      }
    </div>
  )
}

export default ResetPassword