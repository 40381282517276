import { useState } from "react";
import axios from "axios";
import getBaseUrl from '../../../../hooks/http'
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";


export const useChangePassword = () => {
   const naviagte = useNavigate();
   const { user } = useAuthContext();


   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)


   /*    change Password function   */
   const changePassword = async (oldPassword, newPassword, confirmPassword) => {

      setError(null)
      setIsLoading(true)

      let config = {
         headers: {
            Authorization: `Bearer ${user.token}`,
         },
      };

      const body = { oldPassword, newPassword, confirmPassword }
      axios.post(`${getBaseUrl}/candidates/changePassword`, body, config)
         .then((res) => {
            console.log(res.data);
            if (res.status === 200) {
               setIsLoading(true)
               setError(null)
               /* redirect */
               naviagte("/candidate/profile/personal-details");
            } else {
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false)
            setError(error.response.data.error);
         })
   }

   return { changePassword, isLoading, error }
}