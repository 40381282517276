import React from "react";

import { TfiEmail } from "react-icons/tfi";
import { useState } from "react";
import { useEffect } from "react";
import { useMailHelper } from '../../../hooks/useMailHelper'
import { useNavigate } from "react-router-dom";





function SendOtpByEmail() {

   const { sendMail, error, isLoading } = useMailHelper();
   const [email, setEmail] = useState('')
   const navigate = useNavigate();

   useEffect(() => {
      const email = localStorage.getItem("email");
      setEmail(email);
   }, [])



   // Function to generate OTP
   const generateOTP = (len) => {
      // Declare a digits variable which stores all digits
      var digits = '0123456789';
      let OTP = '';
      for (let i = 0; i < len; i++) {
         OTP += digits[Math.floor(Math.random() * 10)];
      }
      return OTP;
   }

   //handle Send Click
   const handleSendClick = () => {

      const to_mail = email;
      const otp = generateOTP(6)
      //store the otp to localstorage
      localStorage.setItem('otp', otp)
      // send otp to mail
      const redirect_to = '/employer/reset-password-verify-code';
      sendMail(to_mail, otp, redirect_to)
   }





   return (
      <div className="employee_sendVerificationCode">
         <div className="wrapper">
            <h2 className='mb-4 text-center'>Reset Password</h2>
            {error && <p className='text-danger text-center'>{error}</p>}
            <p className="message text-center">
               One time Password is sent by Email to
               <br />
               <TfiEmail color="#25D366" /> {email}
            </p>
            <div className="d-flex flex-wrap justify-content-evenly mt-5">
               <button className="btn btn-outline-secondary mb-3"  onClick={() => { navigate('/employer/login'); }}>CANCEL</button>
               <button disabled={isLoading} className="btn btn-blue mb-3" onClick={handleSendClick}>SEND</button>
            </div>
         </div>
      </div>
   );
}






export default SendOtpByEmail
