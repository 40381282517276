import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Spinner } from 'react-bootstrap';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { TfiEye } from "react-icons/tfi";

import getBaseUrl from '../../../hooks/http';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useSocketContext } from '../../../hooks/useSocketContext';
import { capitalizeFirstLetter } from '../../../utils/Helper';

import './dashboard.css'




const Dashboard = () => {

   const { user } = useAuthContext();
   const socket = useSocketContext();

   const selectedCandidateIdRef = useRef(null)
   const [candidates, setCandidates] = useState([]); //all candidates data
   const [candidateData, setCandidateData] = useState(null);// selected/viewed candidate data
   const [detailsModal, setDetailsModal] = useState(false);
   const [dataStatus, setDataStatus] = useState({})//fields r added dynamically
   const [messageModal, setMessageModal] = useState(false);
   const [dashboardData, setDashboardData] = useState({
      interviews: 0,
      offers: 0,
      onboardings: 0
   })

   const [viewModal, setViewModal] = useState({
      show: false,
      url: '',
      type: ''
   })
   const [approveConfirmModal, setApproveConfirmModal] = useState({
      show: false,
      index: '',
      doc: ''
   });
   const [rejectConfirmModal, setRejectConfirmModal] = useState({
      show: false,
      value: '',
      error: '',
      index: '',
      doc: ''
   });

   const [isLoading, setIsLoading] = useState(false)


   const handleAdminDashboardUpdate = data => {
      getCandidates();
   }

   const handleVideoUploadingStatus = data => {
      getCandidates();
   }


   useEffect(() => {
      getCandidates()
      //socket io
      socket.on('adminDashboardUpdate', handleAdminDashboardUpdate);
      socket.on('videoUploadingStatusUpdate', handleVideoUploadingStatus);
      return () => {
         socket.off('adminDashboardUpdate', handleAdminDashboardUpdate);
         socket.off('videoUploadingStatusUpdate', handleVideoUploadingStatus);
      };
   }, [user])

   //get Candidates records
   const getCandidates = async () => {
      try {
         let config = {
            headers: { Authorization: `Bearer ${user.token}`, },
         };
         const result = await axios.get(`${getBaseUrl}/admin/dashboard/`, config);
         console.log(result.data);
         const { interviews, offers, onboardings, personalData } = result.data
         setDashboardData({ ...dashboardData, interviews, offers, onboardings });
         // Process the response data to generate photo and video previews
         const processedCandidates = personalData.map(candidate => {
            // Assuming candidate object has fields for photoUrl and videoUrl
            const photoPreviewUrl = (!candidate.photo || candidate.photo === null || candidate.photo === "") ? '/uploads/userIcon.png' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.photo.data}`;
            const newProfilePreview = (!candidate.photo || candidate.photo === null || candidate.photo === "") ? '/uploads/userIcon.png' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.photo.unverifydata}`;
            const fullPhotoPreview = (!candidate.fullPhoto || candidate.fullPhoto === null || candidate.fullPhoto === "") ? '/uploads/userIcon.png' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.fullPhoto.data}`;
            const newfullPhotoPreview = (!candidate.fullPhoto || candidate.fullPhoto === null || candidate.fullPhoto === "") ? '/uploads/userIcon.png' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.fullPhoto.unverifydata}`;
            const summaryVideoPreview = (!candidate.video || candidate.video === null || candidate.video === "") ? '' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.video.data}`;
            const summaryNewVideoPreview = (!candidate.video || candidate.video === null || candidate.video === "") ? '' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.video.unverifydata}`;
            const docPassportPreview = (!candidate.passport || candidate.passport === null || candidate.passport === "") ? '' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.passport.data}`;
            const docNewPassportPreview = (candidate.passport) ? ((candidate.passport.unverifydata) ? `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.passport.unverifydata}` : '') : "";
            const doceduCertiPreview = (!candidate.educationCertificates || candidate.educationCertificates === null || candidate.educationCertificates === "") ? '' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.educationCertificates.data}`;
            const docneweduCertiPreview = (candidate.educationCertificates) ? ((candidate.educationCertificates.unverifydata) ? `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.educationCertificates.unverifydata}` : "") : "";
            const docMedicalReportPreview = (!candidate.medicalReport || candidate.medicalReport === null || candidate.medicalReport === "") ? '' : `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.medicalReport.data}`;
            const docNewMedicalReportPreview = (candidate.medicalReport) ? ((candidate.medicalReport.unverifydata) ? `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.medicalReport.unverifydata}` : "") : "";

            return {
               ...candidate,
               photoPreviewUrl,
               newProfilePreview,
               fullPhotoPreview,
               newfullPhotoPreview,
               summaryVideoPreview,
               summaryNewVideoPreview,
               docPassportPreview,
               docNewPassportPreview,
               doceduCertiPreview,
               docneweduCertiPreview,
               docMedicalReportPreview,
               docNewMedicalReportPreview,
            }
         });

         console.log("processedCandidates", processedCandidates);

         setCandidates(processedCandidates);

         //sort data for selected candidate to approve or reject
         if (selectedCandidateIdRef.current) {
            const filteredData = processedCandidates.filter((cnd) => cnd.candidateId === selectedCandidateIdRef.current);
            console.log('filteredData', filteredData);
            setCandidateData(filteredData[0]);
         }
      } catch (error) {
         console.log(error);
      }
   }

   //open/close detailsmodal 
   const handleDetailsModal = (i) => {
      setDetailsModal(!detailsModal);
      setCandidateData(candidates[i]);
      setDataStatus({});
      selectedCandidateIdRef.current = candidates[i].candidateId;
   }


   //open/close Approve Confirm Modal
   const handleApproveConfirmModal = (i = '') => {

      if (candidateData?.candidate_info[0]?.Verification_counter > 0 && candidateData?.[i]?.unverifydata === '') {
         setMessageModal(true); return 0;
      }
      setApproveConfirmModal({
         ...approveConfirmModal,
         show: !approveConfirmModal.show,
         index: i,
         doc: i
      })
   }

   //handle Doc Approve
   const handleDocApprove = async () => {
      try {
         const id = candidateData?.candidateId
         const doc = approveConfirmModal?.doc
         setDataStatus({ ...dataStatus, [doc]: { ...dataStatus[doc], status: 'verify' }, candidateId: id })
         //modal open/close
         setApproveConfirmModal({
            ...approveConfirmModal,
            show: !approveConfirmModal.show,
         })

      } catch (error) {
         console.log(error);
      }
   }

   //handle Doc Approve
   const handleFinalDocApprove = async () => {
      try {
         const id = candidateData?.candidateId
         const body = { id }
         const result = await axios.post(`${getBaseUrl}/admin/finalApproveData`, body)
         setApproveConfirmModal({
            ...approveConfirmModal,
            show: !approveConfirmModal.show,
         })

         await getCandidates();
      } catch (error) {
         console.log(error);
      }
   }

   //open/close reject Confirm Modal
   const handleRejectConfirmModal = (i = '') => {
      console.log(i);
      if (candidateData?.candidate_info[0]?.Verification_counter > 0 && candidateData?.[i]?.unverifydata === '') {
         setMessageModal(true); return 0;
      }
      setRejectConfirmModal({
         ...rejectConfirmModal,
         show: !rejectConfirmModal.show,
         value: '',
         index: i,
         doc: i
      })
   }

   //handle Doc Rejection
   const handleDocRejection = async () => {
      try {
         if (rejectConfirmModal.value !== '') {
            const id = candidateData?.candidateId;
            const doc = rejectConfirmModal?.doc;
            const reason = rejectConfirmModal?.value

            setDataStatus({ ...dataStatus, [doc]: { ...dataStatus[doc], status: 'reject', rejectionReason: reason }, candidateId: id })
            //modal open/close
            setRejectConfirmModal({
               ...rejectConfirmModal,
               show: !rejectConfirmModal.show,
            })

         } else {
            setRejectConfirmModal({ ...rejectConfirmModal, error: 'this field is Required.' })
         }

      } catch (error) {
         console.log(error);
      }
   }


   //handle doc img view model
   // show img view
   const handleImgView = (field) => {
      if (typeof field === 'string') {
         const validImageTypes = ['jpg', 'jpeg', 'png', 'webp'];
         const validOtherTypes = ['pdf'];

         let imgView;

         if (validImageTypes.includes(field)) {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: field, type: 'image' })
         } else if (validOtherTypes.includes(field) || field.includes('.pdf')) {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'pdf' })
         } else {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'image' })
         }
      }
      else {
         const fileType = field.type;
         const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
         const validOtherTypes = ['application/pdf'];
         let imgView;

         if (validImageTypes.includes(fileType)) {
            imgView = URL.createObjectURL(field);
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'image' })
         } else if (validOtherTypes.includes(fileType) || field.includes('.pdf')) {
            imgView = field;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'pdf' })
         } else {
            imgView = field;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'image' })
         }
      }


   }
   //handle imageOnError
   const imageOnError = (event) => {
      console.log('image have  error...')
      event.currentTarget.src = '/uploads/userIcon.png';
   };

   // handle allApprove Onchange
   const handleAllApproveOnchange = (e) => {
      const id = candidateData?.candidateId;
      setDataStatus({ ...dataStatus, allApprove: e.target.checked, candidateId: id })
   }


   // handle Save status -------------
   const handleSave = async () => {
      try {
         setIsLoading(true);
         const body = dataStatus;
         if (Object.keys(body).length > 0) {
            await axios.post(`${getBaseUrl}/admin/verifyData`, body)
            setIsLoading(false);
            getCandidates();
            handleDetailsModal(); //close deatils modal
         } else {
            setIsLoading(false);
            handleDetailsModal();//close deatils modal
         }
      } catch (error) {
         setIsLoading(false);
         console.log(error);
      }
   }







   return (
      <div className='Dashboard'>
         <div className="content p-2 mb-3">
            <h3 className='title'>Dashboard</h3>
            <div className='d-flex flex-wrap justify-content-around my-5'>
               <div className="card d-flex flex-column align-items-center px-5 py-4">
                  <span>Interview in-progress</span>
                  <span>{dashboardData.interviews}</span>
               </div>
               <div className="card d-flex flex-column align-items-center px-5 py-4">
                  <span>Offers in-progress</span>
                  <span>{dashboardData.offers}</span>
               </div>
               <div className="card d-flex flex-column align-items-center px-5 py-4">
                  <span>Onboard in-progress</span>
                  <span>{dashboardData.onboardings}</span>
               </div>
            </div>
            <div className='table-responsive'>
               <h4 className='title'>List for Verification</h4>
               <Table className="table table-bordered table-striped m-0 tb">
                  <Thead className='text-center'>
                     <Tr>
                        <Th className='py-3' scope="col">#</Th>
                        <Th className='py-3' scope="col">CANDIDATE NAME</Th>
                        <Th className='py-3' scope="col">COUNTRY</Th>
                        <Th className='py-3' scope="col">STATUS</Th>
                        <Th className='py-3' scope="col">ACTION</Th>
                     </Tr>
                  </Thead>
                  <Tbody className="text-center">
                     {
                        candidates.map((candidate, i) => (
                           <Tr key={i}>
                              <Td>{i + 1}</Td>
                              <Td>{candidate?.name?.data}</Td>
                              <Td>{candidate?.currentCountry?.data}</Td>
                              <Td>
                                 {
                                    Object.keys(candidate).map((field) => {
                                       return <p className='m-0' key={field}>{(candidate[field]?.status === 'reject') && `${capitalizeFirstLetter(field)} - ${candidate[field]?.rejectionReason}`}</p>
                                    })
                                 }
                              </Td>
                              <Td>
                                 <div className='d-flex justify-content-evenly'>
                                    <div className="icon icon1 mx-1" onClick={() => handleDetailsModal(i)}>
                                       <TfiEye className='text-blue fs-3' />
                                       <span className="text icon-text">view</span>
                                    </div>
                                 </div>
                              </Td>
                           </Tr>
                        ))
                     }
                  </Tbody>
               </Table>

            </div>
         </div>


         {/* details modal */}
         <Modal size="lg" show={detailsModal} onHide={handleDetailsModal} id='detailsModal'>
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative cursor-pointer">
                  <AiOutlineClose onClick={handleDetailsModal}
                     className='icon close-icon position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body>
               <div className="row">

                  <div className="col-12 my-4">
                     <div className='table-responsive'>
                        <h5 className='title'>Details</h5>
                        <p>Verification Count : {candidateData?.candidate_info[0]?.Verification_counter}</p>
                        <Table className="table table-bordered table-striped m-0 tb">
                           <Thead className='text-center'>
                              <Tr>
                                 <Th className='py-3' scope="col" rowSpan={2}>TITLE</Th>
                                 <Th className='py-3' scope="col" rowSpan={2}>DATA</Th>
                                 <Th className='py-3' scope="col" rowSpan={2}>UNVERIFIED DATA</Th>
                                 <Th className='py-3' scope="col" rowSpan={2}>STATUS</Th>
                                 <Th className='py-3' scope="col" rowSpan={2}>REASON OF REJECTION</Th>
                                 <Th className='py-3' scope="col" >ACTION</Th>
                              </Tr>
                           </Thead>
                           <Tbody className="text-center">
                              <Tr>
                                 <Td>Is Gender Female ?</Td>
                                 <Td>{(candidateData?.gender?.data) ? "True" : null}</Td>
                                 <Td> </Td>
                                 <Td> </Td>
                                 <Td>  </Td>
                                 <Td>  </Td>
                              </Tr>
                              <Tr>
                                 <Td>Profile Photo</Td>
                                 <Td>
                                    <div className="profile_photo  mb-3 mb-sm-0 mb-lg-3 d-flex flex-column align-items-center">
                                       <img src={candidateData?.photoPreviewUrl} onError={imageOnError} alt="profile" className='profile_photo-img mb-3' />
                                       {
                                          (candidateData?.photo?.data) && <button className='btn btn-sm btn-primary' onClick={() => handleImgView(candidateData?.photo?.data)}>View Photo</button>
                                       }
                                    </div>
                                 </Td>
                                 <Td>
                                    <div className="profile_photo  mb-3 mb-sm-0 mb-lg-3 d-flex flex-column align-items-center">
                                       {/* <img src={candidateData?.photo?.unverifydata || '/uploads/userIcon.png'} alt="profile" className='profile_photo-img mb-3' />
                                       {
                                          (candidateData?.photo?.unverifydata) && <span className='btn btn-primary' onClick={() => handleImgView(candidateData?.photo?.unverifydata)}>View Photo</span>
                                       }*/}
                                       {candidateData?.photo?.unverifydata ? (
                                          <img src={candidateData?.newProfilePreview} alt="profile" className='profile_photo-img mb-3' />
                                       ) : (
                                          <p></p>
                                       )}
                                       {candidateData?.photo?.unverifydata ? (
                                          (candidateData?.photo?.unverifydata) && <span className='btn btn-primary btn-sm' onClick={() => handleImgView(candidateData?.photo?.unverifydata)}>View Photo</span>
                                       ) : (
                                          <p></p>
                                       )}
                                    </div>
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.photo?.status)
                                          ? (dataStatus?.photo?.status === 'reject') ? dataStatus?.photo?.status : dataStatus?.photo?.status
                                          : (candidateData?.photo?.status === 'reject') ? candidateData?.photo?.status : candidateData?.photo?.status
                                    }

                                 </Td>
                                 <Td>{
                                    (dataStatus?.photo?.status)
                                       ? (dataStatus?.photo?.status === 'reject') ? dataStatus?.photo?.rejectionReason : ""
                                       : (candidateData?.photo?.status === 'reject') ? candidateData?.photo?.rejectionReason : ""
                                 }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('photo')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('photo')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Full Photo</Td>
                                 <Td>
                                    <div className="full_photo  mb-3 mb-sm-0 mb-lg-3 d-flex flex-column align-items-center">
                                       <img src={candidateData?.fullPhotoPreview || '/uploads/userIcon.png'} onError={imageOnError} alt="profile" className='full_photo-img mb-3' />
                                       {
                                          (candidateData?.fullPhotoPreview) && <button className='btn btn-primary btn-sm' onClick={() => handleImgView(candidateData?.fullPhoto?.data)}>View Photo</button>
                                       }
                                    </div>
                                 </Td>
                                 <Td>
                                    <div className="full_photo  mb-3 mb-sm-0 mb-lg-3 d-flex flex-column align-items-center">

                                       {/* <img src={candidateData?.fullPhoto?.unverifydata || '/uploads/userIcon.png'} alt="profile" className='full_photo-img mb-3' />
                                       {
                                          (candidateData?.fullPhoto?.unverifydata) && <span className='btn btn-primary' onClick={() => handleImgView(candidateData?.fullPhoto?.unverifydata)}>View Photo</span>
                                       }*/}
                                       {candidateData?.fullPhoto?.unverifydata ? (
                                          <img src={candidateData?.newfullPhotoPreview} alt="profile" className='full_photo-img mb-3' />
                                       ) : (
                                          <p></p>
                                       )}
                                       {candidateData?.fullPhoto?.unverifydata ? (
                                          (candidateData?.fullPhoto?.unverifydata) && <button className='btn btn-primary' onClick={() => handleImgView(candidateData?.fullPhoto?.unverifydata)}>View Photo</button>
                                       ) : (
                                          <p></p>
                                       )}
                                    </div>
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.fullPhoto?.status)
                                          ? (dataStatus?.fullPhoto?.status === 'reject') ? dataStatus?.fullPhoto?.status : dataStatus?.fullPhoto?.status
                                          : (candidateData?.fullPhoto?.status === 'reject') ? candidateData?.fullPhoto?.status : candidateData?.fullPhoto?.status
                                    }

                                 </Td>
                                 <Td>{
                                    (dataStatus?.fullPhoto?.status)
                                       ? (dataStatus?.fullPhoto?.status === 'reject') ? dataStatus?.fullPhoto?.rejectionReason : ""
                                       : (candidateData?.fullPhoto?.status === 'reject') ? candidateData?.fullPhoto?.rejectionReason : ""
                                 }

                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('fullPhoto')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('fullPhoto')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Video</Td>
                                 <Td>
                                    <div className=" d-flex flex-column align-items-center ">
                                       <div className='record-wrapper'>
                                          {
                                             (candidateData?.video?.data)
                                                ? <video src={candidateData.summaryVideoPreview} controls className='w-100'></video>
                                                : <img src="/images/record-icon.png" alt="record" className='img-fluid' />
                                          }
                                       </div>
                                       {/* <span className='btn btn-primary'>View Video</span> */}
                                    </div>
                                 </Td>
                                 <Td>
                                    <div className=" d-flex flex-column align-items-center ">
                                       <div className='record-wrapper '>
                                          {(candidateData?.video?.unverifydata && (candidateData?.videoUploadingStatus?.unverifydata === "uploaded")) ? (
                                             <video src={candidateData.summaryNewVideoPreview} controls className='w-100'></video>
                                          ) : (
                                             <p>{candidateData?.videoUploadingStatus?.unverifydata}</p>
                                          )}

                                          {/*{
                                             (candidateData?.video?.unverifydata)
                                                ? <video src={candidateData?.video?.unverifydata} controls className='w-100'></video>
                                                : <img src="/images/record-icon.png" alt="record" className='img-fluid' />
                                          }*/}
                                       </div>
                                    </div>
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.video?.status)
                                          ? (dataStatus?.video?.status === 'reject') ? dataStatus?.video?.status : dataStatus?.video?.status
                                          : (candidateData?.video?.status === 'reject') ? candidateData?.video?.status : candidateData?.video?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.video?.status)
                                          ? (dataStatus?.video?.status === 'reject') ? dataStatus?.video?.rejectionReason : ""
                                          : (candidateData?.video?.status === 'reject') ? candidateData?.video?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('video')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('video')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Type Of Candidate </Td><Td>{candidateData?.typeOfCandidate?.data}</Td>
                                 <Td>{candidateData?.typeOfCandidate?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.typeOfCandidate?.status)
                                          ? (dataStatus?.typeOfCandidate?.status === 'reject') ? dataStatus?.typeOfCandidate?.status : dataStatus?.typeOfCandidate?.status
                                          : (candidateData?.typeOfCandidate?.status === 'reject') ? candidateData?.typeOfCandidate?.status : candidateData?.typeOfCandidate?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.typeOfCandidate?.status)
                                          ? (dataStatus?.typeOfCandidate?.status === 'reject') ? dataStatus?.typeOfCandidate?.rejectionReason : ""
                                          : (candidateData?.typeOfCandidate?.status === 'reject') ? candidateData?.typeOfCandidate?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('typeOfCandidate')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('typeOfCandidate')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Name</Td>
                                 <Td>{candidateData?.name?.data}</Td>
                                 <Td>{candidateData?.name?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.name?.status)
                                          ? (dataStatus?.name?.status === 'reject') ? dataStatus?.name?.status : dataStatus?.name?.status
                                          : (candidateData?.name?.status === 'reject') ? candidateData?.name?.status : candidateData?.name?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.name?.status)
                                          ? (dataStatus?.name?.status === 'reject') ? dataStatus?.name?.rejectionReason : ""
                                          : (candidateData?.name?.status === 'reject') ? candidateData?.name?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('name')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('name')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Address</Td><Td>{candidateData?.address?.data}</Td>
                                 <Td>{candidateData?.address?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.address?.status)
                                          ? (dataStatus?.address?.status === 'reject') ? dataStatus?.address?.status : dataStatus?.address?.status
                                          : (candidateData?.address?.status === 'reject') ? candidateData?.address?.status : candidateData?.address?.status

                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.address?.status)
                                          ? (dataStatus?.address?.status === 'reject') ? dataStatus?.address?.rejectionReason : ""
                                          : (candidateData?.address?.status === 'reject') ? candidateData?.address?.rejectionReason : ""

                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('address')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('address')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Education Country</Td>
                                 <Td>{candidateData?.educationalDetailsCountry?.data}</Td>
                                 <Td>{candidateData?.educationalDetailsCountry?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.educationalDetailsCountry?.status)
                                          ? (dataStatus?.educationalDetailsCountry?.status === 'reject') ? dataStatus?.educationalDetailsCountry?.status : dataStatus?.educationalDetailsCountry?.status
                                          : (candidateData?.educationalDetailsCountry?.status === 'reject') ? candidateData?.educationalDetailsCountry?.status : candidateData?.educationalDetailsCountry?.status

                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.educationalDetailsCountry?.status)
                                          ? (dataStatus?.educationalDetailsCountry?.status === 'reject') ? dataStatus?.educationalDetailsCountry?.rejectionReason : ""
                                          : (candidateData?.educationalDetailsCountry?.status === 'reject') ? candidateData?.educationalDetailsCountry?.rejectionReason : ""

                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('educationalDetailsCountry')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('educationalDetailsCountry')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Highest Education</Td>
                                 <Td>{candidateData?.HighestEducation?.data}</Td>
                                 <Td>{candidateData?.HighestEducation?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.HighestEducation?.status)
                                          ? (dataStatus?.HighestEducation?.status === 'reject') ? dataStatus?.HighestEducation?.status : dataStatus?.HighestEducation?.status
                                          : (candidateData?.HighestEducation?.status === 'reject') ? candidateData?.HighestEducation?.status : candidateData?.HighestEducation?.status

                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.HighestEducation?.status)
                                          ? (dataStatus?.HighestEducation?.status === 'reject') ? dataStatus?.HighestEducation?.rejectionReason : ""
                                          : (candidateData?.HighestEducation?.status === 'reject') ? candidateData?.HighestEducation?.rejectionReason : ""

                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('HighestEducation')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('HighestEducation')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>DOB</Td>
                                 <Td>{
                                    new Date(candidateData?.birthday?.data).toLocaleString('en-GB', {
                                       year: 'numeric', month: 'short', day: '2-digit',
                                    }).replace(/ /g, '-')
                                 }</Td>
                                 <Td>{
                                    (candidateData?.birthday?.unverifydata) &&
                                    new Date(candidateData?.birthday?.unverifydata).toLocaleString('en-GB', {
                                       year: 'numeric', month: 'short', day: '2-digit',
                                    }).replace(/ /g, '-')
                                 }</Td>
                                 <Td>
                                    {
                                       (dataStatus?.birthday?.status)
                                          ? (dataStatus?.birthday?.status === 'reject') ? dataStatus?.birthday?.status : dataStatus?.birthday?.status
                                          : (candidateData?.birthday?.status === 'reject') ? candidateData?.birthday?.status : candidateData?.birthday?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.birthday?.status)
                                          ? (dataStatus?.birthday?.status === 'reject') ? dataStatus?.birthday?.rejectionReason : ""
                                          : (candidateData?.birthday?.status === 'reject') ? candidateData?.birthday?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('birthday')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('birthday')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Nationality</Td><Td>{candidateData?.nationality?.data}</Td>
                                 <Td>{candidateData?.nationality?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.nationality?.status)
                                          ? (dataStatus?.nationality?.status === 'reject') ? dataStatus?.nationality?.status : dataStatus?.nationality?.status
                                          : (candidateData?.nationality?.status === 'reject') ? candidateData?.nationality?.status : candidateData?.nationality?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.nationality?.status)
                                          ? (dataStatus?.nationality?.status === 'reject') ? dataStatus?.nationality?.rejectionReason : ""
                                          : (candidateData?.nationality?.status === 'reject') ? candidateData?.nationality?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('nationality')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('nationality')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Height</Td>
                                 <Td>{candidateData?.height?.data}</Td>
                                 <Td>{candidateData?.height?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.height?.status)
                                          ? (dataStatus?.height?.status === 'reject') ? dataStatus?.height?.status : dataStatus?.height?.status
                                          : (candidateData?.height?.status === 'reject') ? candidateData?.height?.status : candidateData?.height?.status

                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.height?.status)
                                          ? (dataStatus?.height?.status === 'reject') ? dataStatus?.height?.rejectionReason : ""
                                          : (candidateData?.height?.status === 'reject') ? candidateData?.height?.rejectionReason : ""

                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('height')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('height')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Weight</Td>
                                 <Td>{candidateData?.weight?.data}</Td>
                                 <Td>{candidateData?.weight?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.weight?.status)
                                          ? (dataStatus?.weight?.status === 'reject') ? dataStatus?.weight?.status : dataStatus?.weight?.status
                                          : (candidateData?.weight?.status === 'reject') ? candidateData?.weight?.status : candidateData?.weight?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.weight?.status)
                                          ? (dataStatus?.weight?.status === 'reject') ? dataStatus?.weight?.rejectionReason : ""
                                          : (candidateData?.weight?.status === 'reject') ? candidateData?.weight?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('weight')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('weight')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Marital Status</Td>
                                 <Td>{candidateData?.maritalStatus?.data}</Td>
                                 <Td>{candidateData?.maritalStatus?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.maritalStatus?.status)
                                          ? (dataStatus?.maritalStatus?.status === 'reject') ? dataStatus?.maritalStatus?.status : dataStatus?.maritalStatus?.status
                                          : (candidateData?.maritalStatus?.status === 'reject') ? candidateData?.maritalStatus?.status : candidateData?.maritalStatus?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.maritalStatus?.status)
                                          ? (dataStatus?.maritalStatus?.status === 'reject') ? dataStatus?.maritalStatus?.rejectionReason : ""
                                          : (candidateData?.maritalStatus?.status === 'reject') ? candidateData?.maritalStatus?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('maritalStatus')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('maritalStatus')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Child Age</Td>
                                 <Td>
                                    <div className='d-flex flex-wrap justify-content-center'>
                                       {
                                          candidateData?.childAge?.data.map((childAge, index) => (
                                             <span key={index} className='ms-2 text-nowrap'>
                                                kid{index + 1}: {childAge}yrs
                                             </span>
                                          ))
                                       }
                                    </div>
                                 </Td>
                                 <Td>
                                    <div className='d-flex flex-wrap justify-content-center'>
                                       {
                                          candidateData?.childAge?.unverifydata.map((childAge, index) => (
                                             <span key={index} className='ms-2 text-nowrap'>
                                                kid{index + 1}: {childAge}yrs
                                             </span>
                                          ))
                                       }
                                    </div>
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.childAge?.status)
                                          ? (dataStatus?.childAge?.status === 'reject') ? dataStatus?.childAge?.status : dataStatus?.childAge?.status
                                          : (candidateData?.childAge?.status === 'reject') ? candidateData?.childAge?.status : candidateData?.childAge?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.childAge?.status)
                                          ? (dataStatus?.childAge?.status === 'reject') ? dataStatus?.childAge?.rejectionReason : ""
                                          : (candidateData?.childAge?.status === 'reject') ? candidateData?.childAge?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('childAge')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('childAge')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Skills</Td>
                                 <Td>{candidateData?.skills?.data.toString()}</Td>
                                 <Td>{candidateData?.skills?.unverifydata.toString()}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.skills?.status)
                                          ? (dataStatus?.skills?.status === 'reject') ? dataStatus?.skills?.status : dataStatus?.skills?.status
                                          : (candidateData?.skills?.status === 'reject') ? candidateData?.skills?.status : candidateData?.skills?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.skills?.status)
                                          ? (dataStatus?.skills?.status === 'reject') ? dataStatus?.skills?.rejectionReason : ""
                                          : (candidateData?.skills?.status === 'reject') ? candidateData?.skills?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('skills')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('skills')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Language</Td>
                                 <Td>{candidateData?.languages?.data.toString()}</Td>
                                 <Td>{candidateData?.languages?.unverifydata.toString()}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.languages?.status)
                                          ? (dataStatus?.languages?.status === 'reject') ? dataStatus?.languages?.status : dataStatus?.languages?.status
                                          : (candidateData?.languages?.status === 'reject') ? candidateData?.languages?.status : candidateData?.languages?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.languages?.status)
                                          ? (dataStatus?.languages?.status === 'reject') ? dataStatus?.languages?.rejectionReason : ""
                                          : (candidateData?.languages?.status === 'reject') ? candidateData?.languages?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('languages')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('languages')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Cooking</Td>
                                 <Td>{candidateData?.cooking?.data.toString()}</Td>
                                 <Td>{candidateData?.cooking?.unverifydata.toString()}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.cooking?.status)
                                          ? (dataStatus?.cooking?.status === 'reject') ? dataStatus?.cooking?.status : dataStatus?.cooking?.status
                                          : (candidateData?.cooking?.status === 'reject') ? candidateData?.cooking?.status : candidateData?.cooking?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.cooking?.status)
                                          ? (dataStatus?.cooking?.status === 'reject') ? dataStatus?.cooking?.rejectionReason : ""
                                          : (candidateData?.cooking?.status === 'reject') ? candidateData?.cooking?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('cooking')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('cooking')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Personality</Td>
                                 <Td>{candidateData?.personality?.data.toString()}</Td>
                                 <Td>{candidateData?.personality?.unverifydata.toString()}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.personality?.status)
                                          ? (dataStatus?.personality?.status === 'reject') ? dataStatus?.personality?.status : dataStatus?.personality?.status
                                          : (candidateData?.personality?.status === 'reject') ? candidateData?.personality?.status : candidateData?.personality?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.personality?.status)
                                          ? (dataStatus?.personality?.status === 'reject') ? dataStatus?.personality?.rejectionReason : ""
                                          : (candidateData?.personality?.status === 'reject') ? candidateData?.personality?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('personality')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('personality')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Current Country</Td>
                                 <Td>{candidateData?.currentCountry?.data}</Td>
                                 <Td>{candidateData?.currentCountry?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.currentCountry?.status)
                                          ? (dataStatus?.currentCountry?.status === 'reject') ? dataStatus?.currentCountry?.status : dataStatus?.currentCountry?.status
                                          : (candidateData?.currentCountry?.status === 'reject') ? candidateData?.currentCountry?.status : candidateData?.currentCountry?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.currentCountry?.status)
                                          ? (dataStatus?.currentCountry?.status === 'reject') ? dataStatus?.currentCountry?.rejectionReason : ""
                                          : (candidateData?.currentCountry?.status === 'reject') ? candidateData?.currentCountry?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('currentCountry')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('currentCountry')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Currently Working</Td>
                                 <Td>{candidateData?.currentlyWorking?.data}</Td>
                                 <Td>{candidateData?.currentlyWorking?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.currentlyWorking?.status)
                                          ? (dataStatus?.currentlyWorking?.status === 'reject') ? dataStatus?.currentlyWorking?.status : dataStatus?.currentlyWorking?.status
                                          : (candidateData?.currentlyWorking?.status === 'reject') ? candidateData?.currentlyWorking?.status : candidateData?.currentlyWorking?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.currentlyWorking?.status)
                                          ? (dataStatus?.currentlyWorking?.status === 'reject') ? dataStatus?.currentlyWorking?.rejectionReason : ""
                                          : (candidateData?.currentlyWorking?.status === 'reject') ? candidateData?.currentlyWorking?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('currentlyWorking')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('currentlyWorking')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Religion</Td>
                                 <Td>{candidateData?.religion?.data}</Td>
                                 <Td>{candidateData?.religion?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.religion?.status)
                                          ? (dataStatus?.religion?.status === 'reject') ? dataStatus?.religion?.status : dataStatus?.religion?.status
                                          : (candidateData?.religion?.status === 'reject') ? candidateData?.religion?.status : candidateData?.religion?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.religion?.status)
                                          ? (dataStatus?.religion?.status === 'reject') ? dataStatus?.religion?.rejectionReason : ""
                                          : (candidateData?.religion?.status === 'reject') ? candidateData?.religion?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('religion')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('religion')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>How soon can Start</Td>
                                 <Td>{candidateData?.howSoonCanStart?.data}</Td>
                                 <Td>{candidateData?.howSoonCanStart?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.howSoonCanStart?.status)
                                          ? (dataStatus?.howSoonCanStart?.status === 'reject') ? dataStatus?.howSoonCanStart?.status : dataStatus?.howSoonCanStart?.status
                                          : (candidateData?.howSoonCanStart?.status === 'reject') ? candidateData?.howSoonCanStart?.status : candidateData?.howSoonCanStart?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.howSoonCanStart?.status)
                                          ? (dataStatus?.howSoonCanStart?.status === 'reject') ? dataStatus?.howSoonCanStart?.rejectionReason : ""
                                          : (candidateData?.howSoonCanStart?.status === 'reject') ? candidateData?.howSoonCanStart?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('howSoonCanStart')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('howSoonCanStart')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>

                              <Tr>
                                 <Td>Expected Salary</Td>
                                 <Td>{candidateData?.expectedSalary?.data}</Td>
                                 <Td>{(candidateData?.expectedSalary?.unverifydata == 0) ? "" : candidateData?.expectedSalary?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.expectedSalary?.status)
                                          ? (dataStatus?.expectedSalary?.status === 'reject') ? dataStatus?.expectedSalary?.status : dataStatus?.expectedSalary?.status
                                          : (candidateData?.expectedSalary?.status === 'reject') ? candidateData?.expectedSalary?.status : candidateData?.expectedSalary?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.expectedSalary?.status)
                                          ? (dataStatus?.expectedSalary?.status === 'reject') ? dataStatus?.expectedSalary?.rejectionReason : ""
                                          : (candidateData?.expectedSalary?.status === 'reject') ? candidateData?.expectedSalary?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('expectedSalary')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('expectedSalary')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>

                              <Tr>
                                 <Td>No. of OFF Days</Td>
                                 <Td>{candidateData?.oFFDays?.data}</Td>
                                 <Td>{candidateData?.oFFDays?.unverifydata}</Td>
                                 <Td>
                                    {
                                       (dataStatus?.oFFDays?.status)
                                          ? (dataStatus?.oFFDays?.status === 'reject') ? dataStatus?.oFFDays?.status : dataStatus?.oFFDays?.status
                                          : (candidateData?.oFFDays?.status === 'reject') ? candidateData?.oFFDays?.status : candidateData?.oFFDays?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.oFFDays?.status)
                                          ? (dataStatus?.oFFDays?.status === 'reject') ? dataStatus?.oFFDays?.rejectionReason : ""
                                          : (candidateData?.oFFDays?.status === 'reject') ? candidateData?.oFFDays?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('oFFDays')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('oFFDays')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                              <Tr>
                                 <Td>Total Years Experience</Td>
                                 <Td>
                                    {candidateData?.yearOfExperience?.data?.year || 0} Yrs
                                    &nbsp;&nbsp;
                                    {candidateData?.yearOfExperience?.data?.month || 0} Mths
                                 </Td>
                                 <Td>
                                    {(candidateData?.yearOfExperience?.unverifydata?.year == 0 || candidateData?.yearOfExperience?.unverifydata?.year == undefined) ? "" : `${candidateData?.yearOfExperience?.unverifydata?.year} Yrs`}
                                    &nbsp;&nbsp;
                                    {(candidateData?.yearOfExperience?.unverifydata?.month == 0 || candidateData?.yearOfExperience?.unverifydata?.month == undefined) ? "" : `${candidateData?.yearOfExperience?.unverifydata?.month} Mths`}

                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.yearOfExperience?.status)
                                          ? (dataStatus?.yearOfExperience?.status === 'reject') ? dataStatus?.yearOfExperience?.status : dataStatus?.yearOfExperience?.status
                                          : (candidateData?.yearOfExperience?.status === 'reject') ? candidateData?.yearOfExperience?.status : candidateData?.yearOfExperience?.status
                                    }
                                 </Td>

                                 <Td>
                                    {
                                       (dataStatus?.yearOfExperience?.status)
                                          ? (dataStatus?.yearOfExperience?.status === 'reject') ? dataStatus?.yearOfExperience?.rejectionReason : ""
                                          : (candidateData?.yearOfExperience?.status === 'reject') ? candidateData?.yearOfExperience?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('yearOfExperience')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('yearOfExperience')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>

                              <Tr>
                                 <Td>Total Years Singapore Experience</Td>
                                 <Td>
                                    {candidateData?.singaporeExperience?.data?.year || 0} Yrs
                                    &nbsp;&nbsp;
                                    {candidateData?.singaporeExperience?.data?.month || 0} Mths
                                 </Td>
                                 <Td>
                                    {(candidateData?.singaporeExperience?.unverifydata?.year == 0 || candidateData?.singaporeExperience?.unverifydata?.year == undefined) ? "" : `${candidateData?.singaporeExperience?.unverifydata?.year} Yrs`}
                                    &nbsp;&nbsp;
                                    {(candidateData?.singaporeExperience?.unverifydata?.month == 0 || candidateData?.singaporeExperience?.unverifydata?.month == undefined) ? "" : `${candidateData?.singaporeExperience?.unverifydata?.month} Mths`}

                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.singaporeExperience?.status)
                                          ? (dataStatus?.singaporeExperience?.status === 'reject') ? dataStatus?.singaporeExperience?.status : dataStatus?.singaporeExperience?.status
                                          : (candidateData?.singaporeExperience?.status === 'reject') ? candidateData?.singaporeExperience?.status : candidateData?.singaporeExperience?.status
                                    }
                                 </Td>
                                 <Td>
                                    {
                                       (dataStatus?.singaporeExperience?.status)
                                          ? (dataStatus?.singaporeExperience?.status === 'reject') ? dataStatus?.singaporeExperience?.rejectionReason : ""
                                          : (candidateData?.singaporeExperience?.status === 'reject') ? candidateData?.singaporeExperience?.rejectionReason : ""
                                    }
                                 </Td>
                                 <Td>
                                    <div className='d-flex justify-content-center'>
                                       <div className="icon icon2 mx-2"
                                          onClick={() => handleApproveConfirmModal('singaporeExperience')}>
                                          <AiOutlineCheck className='text-success fs-3' />
                                          <span className="text">Approve</span>
                                       </div>
                                       <div className="icon icon3 mx-2"
                                          onClick={() => handleRejectConfirmModal('singaporeExperience')}>
                                          <AiOutlineClose className='text-danger fs-3' />
                                          <span className="text">Reject</span>
                                       </div>
                                    </div>
                                 </Td>
                              </Tr>
                           </Tbody>
                        </Table>
                     </div>
                  </div>

                  <div className="col-12 my-4">
                     <div className='table-responsive'>
                        <h5 className='title'>Work Experience details</h5>
                        <div className='d-flex'>
                           <div className='flex-grow-1 text-center'>
                              {
                                 (dataStatus?.workExperience?.status)
                                    ? (dataStatus?.workExperience?.status === 'reject') ? dataStatus?.workExperience?.status : dataStatus?.workExperience?.status
                                    : (candidateData?.workExperience?.status === 'reject') ? candidateData?.workExperience?.status : candidateData?.workExperience?.status
                              }
                           </div>
                           <div className='flex-grow-1 text-center'>
                              {
                                 (dataStatus?.workExperience?.status)
                                    ? (dataStatus?.workExperience?.status === 'reject') ? dataStatus?.workExperience?.rejectionReason : ""
                                    : (candidateData?.workExperience?.status === 'reject') ? candidateData?.workExperience?.rejectionReason : ""
                              }
                           </div>
                           <div>
                              <div className='d-flex justify-content-center'>
                                 <div className="icon icon2 mx-2"
                                    onClick={() => handleApproveConfirmModal('workExperience')}>
                                    <AiOutlineCheck className='text-success fs-3' />
                                    <span className="text">Approve</span>
                                 </div>
                                 <div className="icon icon3 mx-2"
                                    onClick={() => handleRejectConfirmModal('workExperience')}>
                                    <AiOutlineClose className='text-danger fs-3' />
                                    <span className="text">Reject</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {(candidateData?.workExperience?.data?.length > 0) && <Table className="table table-bordered table-striped m-0 tb">
                           <Thead className='text-center'>
                              <Tr>
                                 <Th className='py-3' scope="col">FROM</Th>
                                 <Th className='py-3' scope="col">TO</Th>
                                 <Th className='py-3' scope="col">COUNTRY</Th>
                                 <Th className='py-3' scope="col">DUTIES</Th>
                                 <Th className='py-3' scope="col">Any Reference Letter</Th>
                              </Tr>
                           </Thead>
                           <Tbody className="text-center">
                              {
                                 candidateData?.workExperience?.data?.map((work, i) => (
                                    <Tr key={i}>
                                       <Td>
                                          {
                                             new Date(work.from).toLocaleString('en-GB', {
                                                year: 'numeric', month: 'short',
                                             }).replace(/ /g, '-')
                                          }
                                       </Td>
                                       <Td>
                                          {
                                             (work.present) ? "Present" :
                                                new Date(work.to).toLocaleString('en-GB', {
                                                   year: 'numeric', month: 'short',
                                                }).replace(/ /g, '-')
                                          }
                                       </Td>
                                       <Td>{work.country}</Td>
                                       <Td>{work.duties}</Td>
                                       <Td>{
                                          (work?.refLetter) &&
                                          <div className='d-flex justify-content-center'>
                                             <div className="icon icon1" onClick={() => handleImgView(work?.refLetter)}>
                                                <TfiEye className='text-blue fs-3' />
                                                <span className="text icon-text">view</span>
                                             </div>
                                          </div>
                                       }
                                       </Td>
                                    </Tr>
                                 ))
                              }
                           </Tbody>
                        </Table>
                        }

                        <div className={`mt-4 ${(candidateData?.workExperience?.unverifydata?.length > 0) ? '' : 'd-none'}`}>
                           <h5 className='title'>Unverified Work Experience details</h5>
                           <Table className="table table-bordered table-striped m-0 tb">
                              <Thead className='text-center'>
                                 <Tr>
                                    <Th className='py-3' scope="col">FROM</Th>
                                    <Th className='py-3' scope="col">TO</Th>
                                    <Th className='py-3' scope="col">COUNTRY</Th>
                                    <Th className='py-3' scope="col">DUTIES</Th>
                                    <Th className='py-3' scope="col">Any Reference Letter</Th>
                                 </Tr>
                              </Thead>
                              <Tbody className="text-center">
                                 {
                                    candidateData?.workExperience?.unverifydata?.map((work, i) => (
                                       <Tr key={i}>
                                          <Td>
                                             {
                                                new Date(work.from).toLocaleString('en-GB', {
                                                   year: 'numeric', month: 'short',
                                                }).replace(/ /g, '-')
                                             }
                                          </Td>
                                          <Td>
                                             {
                                                (work.present) ? "Present" :
                                                   new Date(work.to).toLocaleString('en-GB', {
                                                      year: 'numeric', month: 'short',
                                                   }).replace(/ /g, '-')
                                             }
                                          </Td>
                                          <Td>{work.country}</Td>
                                          <Td>{work.duties}</Td>
                                          <Td>
                                             {(work?.refLetter) &&
                                                <div className='d-flex justify-content-center'>
                                                   <div className="icon icon1" onClick={() => handleImgView(work?.refLetter)}>
                                                      <TfiEye className='text-blue fs-3' />
                                                      <span className="text icon-text">view</span>
                                                   </div>
                                                </div>
                                             }
                                          </Td>
                                       </Tr>
                                    ))
                                 }
                              </Tbody>
                           </Table>
                        </div>
                     </div>
                  </div>

                  <div className='col-12 your-document table-responsive'>
                     <h3 className='text-center mt-2 text-blue'>Your Document</h3>
                     <Table className="table table-bordered table-striped m-0 tb">
                        <Thead className='text-center'>
                           <Tr>
                              <Th className='py-3' scope="col" rowSpan={2}>TITLE</Th>
                              <Th className='py-3' scope="col" rowSpan={2}>DATA</Th>
                              <Th className='py-3' scope="col" rowSpan={2}>UNVERIFIED DATA</Th>
                              <Th className='py-3' scope="col" rowSpan={2}>STATUS</Th>
                              <Th className='py-3' scope="col" rowSpan={2}>REASON OF REJECTION</Th>
                              <Th className='py-3' scope="col" >ACTION</Th>
                           </Tr>
                        </Thead>
                        <Tbody className="text-center">
                           <Tr>
                              <Td>Passport</Td>
                              <Td>
                                 {
                                    (candidateData?.passport?.data) ?
                                       <span className='d-flex flex-column align-items-center cursor-pointer' onClick={() => handleImgView(candidateData?.passport?.data)}>
                                          <img src={
                                             (typeof candidateData?.passport?.data === 'string')
                                                ? (candidateData?.passport?.data.includes("pdf"))
                                                   ? '/images/pdf-icon.jpeg' : candidateData?.docPassportPreview
                                                : ''} alt=""
                                             className='preview' />
                                          <span className='icon-text'><TfiEye className='icon-eye' /> view</span>
                                       </span>
                                       : <span style={{ width: '80px' }} />
                                 }
                              </Td>
                              <Td>
                                 {
                                    (candidateData?.passport?.unverifydata) ?
                                       <span className='d-flex flex-column align-items-center cursor-pointer' onClick={() => handleImgView(candidateData?.passport?.unverifydata)}>
                                          <img src={
                                             (typeof candidateData?.passport?.unverifydata === 'string')
                                                ? (candidateData?.passport?.unverifydata.includes("pdf"))
                                                   ? '/images/pdf-icon.jpeg' : candidateData?.docNewPassportPreview
                                                : ''} alt=""
                                             className='preview' />
                                          <span className='icon-text'><TfiEye className='icon-eye' /> view</span>
                                       </span>
                                       : <span style={{ width: '80px' }} />
                                 }
                              </Td>
                              <Td>
                                 {
                                    (dataStatus?.passport?.status)
                                       ? (dataStatus?.passport?.status === 'reject') ? dataStatus?.passport?.status : dataStatus?.passport?.status
                                       : (candidateData?.passport?.status === 'reject') ? candidateData?.passport?.status : candidateData?.passport?.status
                                 }

                              </Td>
                              <Td>{
                                 (dataStatus?.passport?.status)
                                    ? (dataStatus?.passport?.status === 'reject') ? dataStatus?.passport?.rejectionReason : ""
                                    : (candidateData?.passport?.status === 'reject') ? candidateData?.passport?.rejectionReason : ""
                              }

                              </Td>
                              <Td>
                                 <div className='d-flex justify-content-center'>
                                    <div className="icon icon2 mx-2"
                                       onClick={() => handleApproveConfirmModal('passport')}>
                                       <AiOutlineCheck className='text-success fs-3' />
                                       <span className="text">Approve</span>
                                    </div>
                                    <div className="icon icon3 mx-2"
                                       onClick={() => handleRejectConfirmModal('passport')}>
                                       <AiOutlineClose className='text-danger fs-3' />
                                       <span className="text">Reject</span>
                                    </div>

                                 </div>

                              </Td>
                           </Tr>

                           <Tr>
                              <Td>Education Certificates</Td>
                              <Td>
                                 {
                                    (candidateData?.educationCertificates?.data) ?
                                       <span className='d-flex flex-column align-items-center cursor-pointer' onClick={() => handleImgView(candidateData?.educationCertificates?.data)}>
                                          <img data={candidateData?.educationCertificates?.data.type} src={
                                             (typeof candidateData?.educationCertificates?.data === 'string')
                                                ? (candidateData?.educationCertificates?.data.includes("pdf"))
                                                   ? '/images/pdf-icon.jpeg' : candidateData?.doceduCertiPreview
                                                : ''} alt=""
                                             className='preview' />
                                          <span className='icon-text'><TfiEye className='icon-eye' /> view</span>
                                       </span>
                                       : <span style={{ width: '80px' }} />
                                 }
                              </Td>
                              <Td>
                                 {
                                    (candidateData?.educationCertificates?.unverifydata) ?
                                       <span className='d-flex flex-column align-items-center cursor-pointer' onClick={() => handleImgView(candidateData?.educationCertificates?.unverifydata)}>
                                          <img data={candidateData?.educationCertificates?.unverifydata.type} src={
                                             (typeof candidateData?.educationCertificates?.unverifydata === 'string')
                                                ? (candidateData?.educationCertificates?.unverifydata.includes("pdf"))
                                                   ? '/images/pdf-icon.jpeg' : candidateData?.docneweduCertiPreview
                                                : ''} alt=""
                                             className='preview' />
                                          <span className='icon-text'><TfiEye className='icon-eye' /> view</span>
                                       </span>
                                       : <span style={{ width: '80px' }} />
                                 }
                              </Td>
                              <Td>
                                 {
                                    (dataStatus?.educationCertificates?.status)
                                       ? (dataStatus?.educationCertificates?.status === 'reject') ? dataStatus?.educationCertificates?.status : dataStatus?.educationCertificates?.status
                                       : (candidateData?.educationCertificates?.status === 'reject') ? candidateData?.educationCertificates?.status : candidateData?.educationCertificates?.status
                                 }

                              </Td>
                              <Td>{
                                 (dataStatus?.educationCertificates?.status)
                                    ? (dataStatus?.educationCertificates?.status === 'reject') ? dataStatus?.educationCertificates?.rejectionReason : ""
                                    : (candidateData?.educationCertificates?.status === 'reject') ? candidateData?.educationCertificates?.rejectionReason : ""
                              }

                              </Td>
                              <Td>
                                 <div className='d-flex justify-content-center'>
                                    <div className="icon icon2 mx-2"
                                       onClick={() => handleApproveConfirmModal('educationCertificates')}>
                                       <AiOutlineCheck className='text-success fs-3' />
                                       <span className="text">Approve</span>
                                    </div>
                                    <div className="icon icon3 mx-2"
                                       onClick={() => handleRejectConfirmModal('educationCertificates')}>
                                       <AiOutlineClose className='text-danger fs-3' />
                                       <span className="text">Reject</span>
                                    </div>

                                 </div>

                              </Td>
                           </Tr>
                           <Tr>
                              <Td>Medical Report</Td>
                              <Td>
                                 {
                                    (candidateData?.medicalReport?.data) ?
                                       <span className='d-flex flex-column align-items-center cursor-pointer' onClick={() => handleImgView(candidateData?.medicalReport?.data)}>
                                          <img src={
                                             (typeof candidateData?.medicalReport?.data === 'string')
                                                ? (candidateData?.medicalReport?.data.includes("pdf"))
                                                   ? '/images/pdf-icon.jpeg' : candidateData?.docMedicalReportPreview
                                                : ''} alt=""
                                             className='preview' />
                                          <span className='icon-text'><TfiEye className='icon-eye' /> view</span>
                                       </span>
                                       : <span style={{ width: '80px' }} />
                                 }
                              </Td>
                              <Td>
                                 {
                                    (candidateData?.medicalReport?.unverifydata) ?
                                       <span className='d-flex flex-column align-items-center cursor-pointer' onClick={() => handleImgView(candidateData?.medicalReport?.unverifydata)}>
                                          <img src={
                                             (typeof candidateData?.medicalReport?.unverifydata === 'string')
                                                ? (candidateData?.medicalReport?.unverifydata.includes("pdf"))
                                                   ? '/images/pdf-icon.jpeg' : candidateData?.docNewMedicalReportPreview
                                                : ''} alt=""
                                             className='preview' />
                                          <span className='icon-text'><TfiEye className='icon-eye' /> view</span>
                                       </span>
                                       : <span style={{ width: '80px' }} />
                                 }
                              </Td>
                              <Td>
                                 {
                                    (dataStatus?.medicalReport?.status)
                                       ? (dataStatus?.medicalReport?.status === 'reject') ? dataStatus?.medicalReport?.status : dataStatus?.medicalReport?.status
                                       : (candidateData?.medicalReport?.status === 'reject') ? candidateData?.medicalReport?.status : candidateData?.medicalReport?.status
                                 }

                              </Td>
                              <Td>{
                                 (dataStatus?.medicalReport?.status)
                                    ? (dataStatus?.medicalReport?.status === 'reject') ? dataStatus?.medicalReport?.rejectionReason : ""
                                    : (candidateData?.medicalReport?.status === 'reject') ? candidateData?.medicalReport?.rejectionReason : ""
                              }

                              </Td>
                              <Td>
                                 <div className='d-flex justify-content-center'>
                                    <div className="icon icon2 mx-2"
                                       onClick={() => handleApproveConfirmModal('medicalReport')}>
                                       <AiOutlineCheck className='text-success fs-3' />
                                       <span className="text">Approve</span>
                                    </div>
                                    <div className="icon icon3 mx-2"
                                       onClick={() => handleRejectConfirmModal('medicalReport')}>
                                       <AiOutlineClose className='text-danger fs-3' />
                                       <span className="text">Reject</span>
                                    </div>

                                 </div>

                              </Td>
                           </Tr>
                        </Tbody>
                     </Table>

                  </div>

                  <div className='col-12'>
                     <div className='d-flex justify-content-center'>
                        <div className='d-flex flex-column'>
                           <div className='helperwish d-flex align-items-center gap-10 mt-3 px-3'>
                              <input className="form-check-input m-1" type="checkbox" value="" id="flexCheckDefault"
                                 onChange={handleAllApproveOnchange} />
                              <label className="form-check-label" htmlFor="flexCheckDefault">
                                 All Approve
                              </label>
                           </div>
                           <button className='btn btn-primary m-3' onClick={handleSave} disabled={isLoading}>
                              {(isLoading) && <Spinner animation="border" size="sm" className='me-1' />}
                              Save
                           </button>
                        </div>
                        <button className='btn btn-outline-secondary m-3 align-self-end' onClick={() => handleDetailsModal()}>Cancel</button>
                     </div>
                  </div>

               </div>
            </Modal.Body>
         </Modal>

         {/* viewModal   */}
         <Modal show={viewModal.show} onHide={() => setViewModal({ ...viewModal, show: false })}
            backdrop="static" id='viewModal' aria-labelledby="contained-modal-title-vcenter" centered scrollable dialogClassName="w-min-content"
         >
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={() => setViewModal({ ...viewModal, show: false })}
                     className='icon close-icon position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body className='p-0 min-h-100'>

               <div>
                  {
                     (viewModal?.type === 'image') &&
                     <div className=''>
                        <DocViewer
                           documents={[{ uri: viewModal?.url }]}
                           pluginRenderers={DocViewerRenderers}
                           config={{
                              header: {
                                 disableHeader: true,
                                 disableFileName: true,
                                 retainURLParams: true,
                              }
                           }}
                        />
                     </div>
                  }
                  {
                     (viewModal?.url?.type === 'application/pdf') &&
                     <div className='d-flex justify-content-center'>
                        <DocViewer
                           documents={[viewModal?.url].map((file) => ({
                              uri: window.URL.createObjectURL(file),
                              fileName: file.name,
                           }))}
                           pluginRenderers={DocViewerRenderers}
                           config={{
                              header: {
                                 disableHeader: true,
                                 disableFileName: true,
                                 retainURLParams: true,
                              }
                           }}
                        />
                     </div>
                  }
                  {
                     (typeof viewModal?.url === 'string' && viewModal?.url.includes('.pdf')) &&
                     <div className=''>
                        <DocViewer
                           documents={[{ uri: viewModal.url }]}
                           pluginRenderers={DocViewerRenderers}
                           config={{
                              header: {
                                 disableHeader: true,
                                 disableFileName: true,
                                 retainURLParams: true,
                              }
                           }}
                        />
                     </div>
                  }
               </div>
            </Modal.Body>
         </Modal>


         {/* ApproveConfirmModal   */}
         <Modal show={approveConfirmModal.show} onHide={() => handleApproveConfirmModal()}
            backdrop="static" id='approveConfirmModal' aria-labelledby="contained-modal-title-vcenter" centered scrollable
         >
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={() => handleApproveConfirmModal()}
                     className='icon close-icon position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body className='p-5'>
               <p className='mb-4'>Are you sure you would like to verify ?</p>
               <div className='d-flex justify-content-center gap-10'>
                  <button className='btn btn-outline-secondary' onClick={() => handleApproveConfirmModal()}> CANCEL</button>
                  <button className='btn btn-primary' onClick={handleDocApprove}> YES</button>
               </div>
            </Modal.Body >
         </Modal >

         {/* RejectConfirmModal   */}
         <Modal show={rejectConfirmModal.show} onHide={() => handleRejectConfirmModal()}
            backdrop="static" id='approveConfirmModal' aria-labelledby="contained-modal-title-vcenter" centered scrollable
         >
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={() => handleRejectConfirmModal()}
                     className='icon close-icon position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body className='p-5'>
               <div className='mb-4'>
                  <div className="floating-control m-0">
                     <input type="text" name="name" id='name'
                        className={`floating-input ${(rejectConfirmModal?.error && 'invalid')}`}
                        placeholder="none" autoComplete='off'
                        value={rejectConfirmModal.value}
                        onChange={(e) => setRejectConfirmModal({ ...rejectConfirmModal, value: e.target.value, error: '' })} />
                     <label htmlFor="name" className="floating-label">Reason of Rejection</label>
                  </div>
               </div>
               <div className='d-flex justify-content-between'>
                  <button className='btn btn-outline-secondary' onClick={() => handleRejectConfirmModal()}> CANCEL</button>
                  <button className='btn btn-primary' onClick={handleDocRejection}> YES</button>
               </div>
            </Modal.Body >
         </Modal >


         {/* message modal   */}
         <Modal show={messageModal} onHide={() => setMessageModal(false)}
            backdrop="static" id='messageModal' aria-labelledby="contained-modal-title-vcenter" centered
         >
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={() => setMessageModal(false)}
                     className='icon close-icon position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body className='p-5'>
               <p className='mb-4'>The action can not be performed because the data has been verified.</p>
               <div className='d-flex justify-content-center'>
                  <button className='btn btn-primary' onClick={() => setMessageModal(false)}> Ok</button>
               </div>
            </Modal.Body >
         </Modal >

      </div >
   )
}

export default Dashboard