import React, { useState } from 'react';
import { Navbar } from 'react-bootstrap';

import { Link, NavLink, Outlet } from 'react-router-dom';

import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { BsSpeedometer2 } from "react-icons/bs";

import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../hooks/useLogout';

import './adminLayout.css'


const AdminLayout = () => {

   const { logout } = useLogout()
   const { user } = useAuthContext()

   const [sidebar, setSidebar] = useState(false);
   const showSidebar = () => setSidebar(!sidebar)

   const sideBarData = [
      {
         title: 'Dashboard',
         path: '/admin/dashboard',
         icon: <BsSpeedometer2 className='me-2 fs-5' />
      },
      {
         title: 'Interviews',
         path: '/admin/interviews',
         icon: <BsSpeedometer2 className='me-2 fs-5 invisible' />
      },
      {
         title: 'Offers',
         path: '/admin/offer',
         icon: <BsSpeedometer2 className='me-2 fs-5 invisible' />
      },
      {
         title: 'Onboard',
         path: '/admin/onboard',
         icon: <BsSpeedometer2 className='me-2 fs-5 invisible' />
      },
      {
         title: 'User',
         path: '/admin/user',
         icon: <BsSpeedometer2 className='me-2 fs-5 invisible' />
      },
   ]

   return (
      <div className='adminLayout h-100 d-flex flex-column'>
         <header className='bg-white d-flex align-items-center justify-content-between'>
            <div className="p-3">
               <Navbar.Brand as={Link} to="/">
                  <span>Helper</span> <span>Wish</span>
               </Navbar.Brand>

               {
                  sidebar ? <span className='ms-3 close-sidebar fs-3' onClick={() => setSidebar(false)}><AiOutlineClose /></span>
                     : <span className='ms-3 open-sidebar fs-3' onClick={() => setSidebar(true)}><FaBars /></span>
               }
            </div>
            {user && (
               <div className='p-3'>
                  <button className='btn btn-outline-primary' type='button' onClick={() => logout()}>
                     logout
                  </button>
               </div>
            )}
         </header>
         <main className='flex-grow-1 d-flex bg-white position-relative'>
            <div className={`sidebar bg-blue text-white ${(sidebar) && 'active'}`}>
               <ul className="list-group p-2" onClick={showSidebar}>
                  {
                     sideBarData.map((item, index) => {
                        return (
                           <li key={index} className='list-item p-2 mb-1'>
                              <NavLink to={item.path} className='list-link'>
                                 {item.icon}<span className='fs-5'>{item.title}</span>
                              </NavLink>
                           </li>
                        )
                     })
                  }
               </ul>
            </div>

            <div className="content flex-grow-1 p-2 mb-3">
               <Outlet />
            </div>
         </main>
      </div>
   )
}

export default AdminLayout