import React, { createContext, useState } from "react";
import PhoneNumber from "../components/PhoneNumber";

// Create a context
export const RegisterContext = createContext();

// Create a provider component
export const RegisterProvider = ({ children }) => {
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [dialingCode, setDialingCode] = useState('')


    const value = {
        name, setName,
        phoneNumber, setPhoneNumber,
        dialingCode, setDialingCode
      };

  return (
    <RegisterContext.Provider value={value}>
      {children}
    </RegisterContext.Provider>
  );
};
