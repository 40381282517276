import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import './homeNavbar.css'


const HomeNavbar = () => {
   const { logout } = useLogout()
   const { user } = useAuthContext()

   return (
      <div className='navbar-wrapper'>
         <Navbar bg="light" expand="lg">
            <Container>
               <Navbar.Brand as={Link} to="/">
                  <img src="/images/HelperWish_Logo.svg" className="Helperwishlogo" alt="Image" />
                  <img src="/images/HelperWish_CompnayName.svg" className="HelperwishlogoText" alt="Image" />
               </Navbar.Brand>
            </Container>
         </Navbar>
      </div>
   )
}

export default HomeNavbar
