

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import getBaseUrl from './http';


export const useWhatsAppHelper = () => {
   const navigate = useNavigate();

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)


   const header = {
      headers: {
         Authorization: `Bearer ${process.env.REACT_APP_WHATSAPP_TOKEN}`,
         Accept: "application/json"
      }
   }



   const sendMsg = (phoneNumber, otp, redirect_to = null) => {
      setError(null)
      setIsLoading(null)
      const veriAbleToPass = { phoneNumber, otp}

      axios.post(`${getBaseUrl}/candidates/sendOTP`, veriAbleToPass)
      .then((res) => {
         if (res.status === 200) {
            setIsLoading(true);
            //redirect to 
            if (redirect_to) {
               navigate(`${redirect_to}`)
            }
         } else {
         }
      })
      .catch((error) => {
         // handle error
         console.log(error);
      })

   }

   const WAmsgAfterPhoneChange = (phoneData) => {
      setError(null)
      setIsLoading(null)

      const FBURL = process.env.REACT_APP_FACEBOOK_URL
      const phoneNumber = phoneData.dialingCode + phoneData.phoneNumber
      const body = {
         "messaging_product": "whatsapp",
         "to": `${phoneNumber}`,
         "type": "template",
         "template": {
            "name": "user_mobile_changed_confirmation",
            "language": {
               
               "code": "en"
            },
            "components": [
               {
                  "type": "body",
                  "parameters": [
                     {
                        "type": "text",
                        "text": `${phoneData.name}`
                     },
                     {
                        "type": "text",
                        "text": `support@helperwish.com`
                     },
                  ]

               }
            ]
         }
      }


      /*axois.post(FBURL, body, header)
         .then((res) => {
            console.log(res);
            setIsLoading(true);
            //redirect to 


         }).catch((err) => {
            console.log(err)
            setError('Something went wrong.Try again.');
            setIsLoading(false);
         })*/
   }

   return { sendMsg, WAmsgAfterPhoneChange, isLoading, error };
}

