import React, { useEffect,useContext } from 'react'
import { useIpDetails } from '../hooks/useIpDetails'
import './phoneNumber.css'

import { RegisterContext } from '../context/RegisterContext';

function PhoneNumber(props) {

   const registerContextData = useContext(RegisterContext);
   const contextDialingCode= registerContextData.dialingCode;



   // get props 
   const { phoneNumber, dialingCode } = props.value
   const { setPhoneNumber, setDialingCode } = props.onChange
   const inputDataType = props.inputDataType
   const labelText = props.labelText


   //user ip details
   const { countryCallingCode, getUserIpDetails, countries } = useIpDetails()
   useEffect(() => {
      getUserIpDetails(); console.log('PhoneNumber');
   }, [])

   //handle input change 
   const handleChange = (e) => {
      const regex = /^\d+$/;

      if (inputDataType === 'number') {
         if (e.target.value === '' || regex.test(e.target.value)) {
            setPhoneNumber(e.target.value)
         }
      } else {
         setPhoneNumber(e.target.value)
      }
   }

   //--------------------------------------------------------------------------
   //show dialing code dropdown 
   const showDialingCode = () => {

      setDialingCode(countryCallingCode);
      // get element 
      const dialingCode = document.querySelector(".dialing-code");
      const dialingCodeInput = document.querySelector(".phoneNumber-input");
      const regex = /^\d+$/;

      if (regex.test(phoneNumber)) {
         dialingCode.classList.remove('d-none')
         dialingCodeInput.classList.add("pl-45");
      } else {
         dialingCode.classList.add("d-none");
         dialingCodeInput.classList.remove("pl-45");
      }

   };
   //show Country List
   const showCountryList = () => {
      const selectCountry = document.querySelector('.select-country');
      const dialingCode = document.querySelector('.dialing-code');

      selectCountry.classList.toggle('d-none')
      dialingCode.classList.toggle('select-arrow-active')
   }
   //selectCountry
   const selectCountry = (ccode) => {
      const dialingCode = document.querySelector('.dialing-code');
      const country = document.querySelector('.select-country');

      // dialingCode.innerHTML = e.target.value;
      setDialingCode(ccode)

      country.classList.add('d-none')
      dialingCode.classList.remove('select-arrow-active')
   }

   return (
      <div className='phoneNumber'>

         <div className="floating-control border-0">
            <div className='d-flex align-items-center w-100 h-100 phoneNumber-wrapper'>
               <span className={`dialing-code ${contextDialingCode=="" ? "d-none" : ""}`} onClick={showCountryList} >
                  {dialingCode === '' ? countryCallingCode : dialingCode}
               </span>
               <input type="text" name="phoneNumber" className="phoneNumber-input flex-grow-1 h-100 floating-input"
                  placeholder=" " onChange={handleChange} value={phoneNumber}
                  onKeyUp={showDialingCode} required />
            </div>

            <label htmlFor="phoneNumber" className="floating-label phoneNumber-label">{labelText}</label>

            <ul className='position-absolute w-100 select-country d-none'>
               {
                  countries.map((country, index) => {
                     return (
                        <li className={`${(dialingCode === country.dial_code) ? 'active' : ''}`}
                           onClick={() => selectCountry(country.dial_code)} key={`ph-${index}`}>
                           {country.name} ({country.dial_code})
                        </li>
                     )
                  })
               }
            </ul>

         </div>

      </div>
   )
}


export default React.memo(PhoneNumber);