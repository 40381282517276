import React from "react";
import './sendVerificationCode.css';

import { BsWhatsapp } from "react-icons/bs";
import { useState } from "react";
import { useEffect } from "react";
import { useWhatsAppHelper } from "../../../hooks/useWhatsAppHelper";
import { useNavigate } from "react-router-dom";



function EmpSendVerificationCode() {
   const navigate = useNavigate();
   

   const { sendMsg } = useWhatsAppHelper();
   const [phoneNumber, setPhoneNumber] = useState('')

   

   useEffect(() => {
      const phoneNumber = localStorage.getItem("phoneNumber");
      setPhoneNumber(phoneNumber);
   }, [])

   

   // Function to generate OTP
   const generateOTP = (len) => {
      // Declare a digits variable which stores all digits
      var digits = '0123456789';
      let OTP = '';
      for (let i = 0; i < len; i++) {
         OTP += digits[Math.floor(Math.random() * 10)];
      }
      return OTP;
   }
   
   //handle Send Click
   const handleSendClick = () => {
      //replace (+) to '' from no.
      const to_Number = phoneNumber.replace(/[+()]/g, "");
      const otp = generateOTP(6)
      //store the otp to localstorage
      localStorage.setItem('otp', otp)
      // send otp to whatsapp
      const redirect_to = '/employer/verify-code';
      sendMsg(to_Number, otp,redirect_to);
   }
  return (
   <div className="employee_sendVerificationCode">
   <div className="wrapper">
   <h2 className='mb-4 text-end'>Sign Up</h2>
      <p className="message text-center">
         You will receive a WhatsApp verification Code sent to
         <br />
         <BsWhatsapp color="#25D366" /> {phoneNumber.replace(/\)/g, ")-")}
      </p>
      <div className="d-flex flex-wrap justify-content-evenly mt-5">
         <button className="btn btn-outline-secondary mb-3"  onClick={() => { navigate('/employer/register'); }}>CANCEL</button>
         <button className="btn btn-blue mb-3" onClick={handleSendClick}>SEND</button>
      </div>
   </div>
</div>
  )
}

export default EmpSendVerificationCode