import React, { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useLogin } from './useLogin';
import PhoneNumber from '../../../components/PhoneNumber';
import { TfiEye } from "react-icons/tfi";
import { FaRegEyeSlash } from "react-icons/fa";

import "./login.css";



function EmpLogin() {

   let { candiateId } = useParams();
   const navigate = useNavigate();
   const { login, error, setError, isLoading } = useLogin()

   const [phoneNumber, setPhoneNumber] = useState('')
   const [dialingCode, setDialingCode] = useState('')
   const [password, setPassword] = useState('')
   //Password inp type
   const [isShown, setIsSHown] = useState(false);
   const togglePassword = () => {
      setIsSHown((isShown) => !isShown);
   };

   // handle login click
   const handleSubmit = async (e) => {
      e.preventDefault();

      const usr = JSON.parse(localStorage.getItem('user'))
      const tabsOpen = localStorage.getItem('tabsOpen')

      // define increment counter part and set win name
      if (tabsOpen == null) {
         localStorage.setItem('tabsOpen', 1)
         window.name = "main";
      } else if (usr !== null) {
         localStorage.setItem('tabsOpen', parseInt(tabsOpen) + parseInt(1))
         window.name = "child";
      }

      //auth user
      /*if (!!usr?.typ && tabsOpen >= 1) {
         navigate("/login-error");
      } else {
         await login(dialingCode, phoneNumber, password, candiateId)
      }*/
      await login(dialingCode, phoneNumber, password, candiateId)
   }




   return (

      <div className='form-login-wrapper'>
         <form className='form-login' onSubmit={handleSubmit}>
            <h2 className='mb-4'>Login</h2>
            
            <div className="mb-3 requiredMark">
               <PhoneNumber value={{ phoneNumber, dialingCode }}
                  onChange={{ setPhoneNumber, setDialingCode }} inputDataType="alphaNumber"
                  labelText="Phone Number / Email" />
            </div>
            <div className="mb-3">
               <div className="d-flex">
                  <div className="floating-control requiredMark">
                     <input type={isShown ? "text" : "password"} name="password" className="floating-input" id='password' placeholder="none" autoComplete='off' required onChange={(e) => { setPassword(e.target.value); setError(null); }} value={password} />
                     <label htmlFor="password" className="floating-label">Password</label>
                  </div>
                  <span className="input-group-text fs-4 cursor-pointer" onClick={togglePassword}>
                     {isShown ? <FaRegEyeSlash /> : <TfiEye />}
                  </span>
               </div>
            </div>
            <button disabled={isLoading} type="submit" className="btn btn-blue btn-loginEmp mt-1 mb-3">Login</button>
            {error && <p className='text-danger text-center'>{error}</p>}
            <p className='text-center text-sm-end'>
               <span className='text-decoration-underline forgotPasswordText'
                  onClick={() => navigate("/employer/forget-password")}>Forgot Password?
               </span>
            </p>
         </form>
      </div>
   )
}

export default EmpLogin
