import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useVerify } from '../../../hooks/useVerify';

import "./verifyRegister.css";



function EmpVerifyRegister() {
    const navigate = useNavigate();
   const { sendOtp, verifyOtp, error, success  } = useVerify()

   const [otp, setOtp] = useState('')

   const handleInputChange = (e) =>{
      const val = e.target.value;
      sendOtp(val)
   }
   

   const submitOtp = () => {
      verifyOtp(otp)
      setOtp('')
      // document.querySelector('.verifyRegister-wrapperr').classList.add('d-none');
      // document.querySelector('.success-msg-verifyRegister').classList.remove('d-none');
   }
  return (
    <>
         <div className='verifyRegister-wrapperr'>
            <p className='text-center fw-bold'>To verify New Registration needs OTP</p>
            <p className='text-center'>Send me OTP by</p>
            <div className='bnt-verify-wrapper text-center mb-5'>
               <input type="radio" name="verify" id='verifyByEmail' value='email' 
               onChange={handleInputChange} />
               <label htmlFor="verifyByEmail" className="btn btn-outline-dark">Email</label>
               <input type="radio" name="verify" id='verifyByMobile' value='mobile' 
               onChange={handleInputChange}/>
               <label htmlFor="verifyByMobile" className="btn btn-outline-dark">Mobile</label>
               {/* <p className='text-danger text-center'>OTP send to Mobile.</p> */}
               {error && <div className='error'>{error}</div>}
               {success && <div className='success'>{success}</div>}
            </div>


            <div className="">
               <div className='d-flex  justify-content-center flex-wrap key-in-otp-wrapper'>
                  <p className='key-in-otp text-center'>Key-in OTP</p>
                  <div className="otp-inp-wrapper">
                     <div className="floating-control">
                        <input type="text" name="otp" className="floating-input"
                           placeholder="none" required value={otp} onChange={e=>setOtp(e.target.value)} />
                        <label htmlFor="name" className="floating-label">OTP</label>
                     </div>
                     <button type="button" className="btn btn-danger btn-red btn-verifyEmp"
                        onClick={submitOtp}>Verify</button>
                  </div>
               </div>

            </div>
         </div>
         <div className='success-msg-verifyRegister text-center d-none  mt-5'>
            <p className='fs-4'>OTP verification completed</p>
            <p className='fs-4'>Please login to complete the final verification.</p>
            <button type="button" className="btn btn-primary btn-blue w-50"
               onClick={() => { navigate('/candidate/login') }}>Ok</button>
         </div>
      </>
  )
}

export default EmpVerifyRegister