import { useMemo, useState } from "react";
import axios from "axios";
import getBaseUrl from "../../../../hooks/http";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { io } from "socket.io-client";


export const usePersonalDetails = () => {
   const naviagte = useNavigate();
   const { user } = useAuthContext();

   const [success, setSuccess] = useState(null);
   const [error, setError] = useState(null);
   const [isLoading, setIsLoading] = useState(null);
   const [isVisible, setIsVisible] = useState(null);

   //realtime data upadete in dashborad
   const adminDashboardUpdata = (cndId) => {
      const url = `${getBaseUrl}`.replace(/\/api/g, '');
      const socket = io.connect(url);
      socket.emit('cndPersonalDeatilsUpdate', { cndId: cndId });
   }

   /* --------------------- save PersonalDetails --------------------- */
   const savePersonalDetails = async (formData) => {
      setSuccess(null);
      setError(null);
      setIsLoading(true);


      let config = {
         headers: {
            Authorization: `Bearer ${user.token}`,
            'Content-Type': 'multipart/form-data',
         },
      };
      axios.post(`${getBaseUrl}/candidates/personalDetails`, formData, config)
         .then((res) => {
            if (res.status === 200) {
               setIsLoading(true);
               console.log(res.data);
               setSuccess(res.data.success);
               /* rediredt to interview */
               naviagte("/candidate/profile/interview");
               //realtime data update
               adminDashboardUpdata(res.data.cndId)
            } else {
               // updateState(false);
               setError(res?.data?.error);
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false);
            setError(error?.response?.data?.error);
         });
   };

   /* --------------------- save Personal UnverifyDetails --------------------- */
   const savePersonalUnverifyDetails = async (formData) => {
      setSuccess(null);
      setError(null);
      setIsLoading(true);


      let config = {
         headers: {
            Authorization: `Bearer ${user.token}`,
            'Content-Type': 'multipart/form-data',
         },
      };
      axios.post(`${getBaseUrl}/candidates/unverifypersonalDetails`, formData, config)
         .then((res) => {
            if (res.status === 200) {
               setIsLoading(true);
               console.log(res.data);
               setSuccess(res.data.success);
               /* rediredt to interview */
               naviagte("/candidate/profile/interview");
               //realtime data update
               adminDashboardUpdata(res.data.cndId)
            } else {
               // updateState(false);
               setError(res?.data?.error);
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false);
            setError(error?.response?.data?.error);
         });
   };

   /* --------------------- deletePersonalDetails --------------------- */

   const deletePersonalDetails = async () => {
      let config = {
         headers: {
            Authorization: `Bearer ${user.token}`,
         },
      };

      axios
         .delete(`${getBaseUrl}/candidates/personalDetails`, config)
         .then((res) => {
            if (res.status === 200) {
               /* rediredt to interview */
               console.log(res.data);
               setIsLoading(true);
               setSuccess(res.data.success);
               naviagte("/candidate/profile/interview");
            } else {
               setIsLoading(false);
               setError(res.data.error);
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false);
            setError(error?.response?.data?.error);
         });
   };


   return {
      savePersonalDetails,
      deletePersonalDetails,
      savePersonalUnverifyDetails,
      isLoading,
      error,
      success,

   };
};
