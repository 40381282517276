import React, { useState, useEffect, useRef, useCallback } from 'react'
import styles from './select.module.css'
//props discription is below ths code; goto the end of the code
const Select = ({ options, optionsArrayObject, onChange, selectedValue, multiple, arrow, classNamePrefix }) => {
   const [isOpen, setIsOpen] = useState(false)
   const [highlightedIndex, setHighlightedIndex] = useState(0)
   const containerRef = useRef(null)

   function clearOptions() {
      multiple ? onChange([]) : onChange(undefined)
   }

   function selectOption(option) {
      if (optionsArrayObject) {
         if (multiple) {
            if (selectedValue.some(v => v.value === option.value)) {
               return;
            } else {
               onChange([...selectedValue, option])
            }
         } else {
            if (option !== selectedValue) onChange(option)
         }
      } else {
         if (multiple) {
            if (selectedValue?.includes(option)) {
               return;
            } else {
               onChange([...selectedValue, option])
            }
         } else {
            if (option !== selectedValue) onChange(option)
         }
      }
   }

   function removeOption(option) {
      if (optionsArrayObject) {
         if (multiple) {
            if (selectedValue.some(v => v.value === option.value)) {
               onChange(selectedValue.filter(o => o.value !== option.value)) //delete option
            }
         }
      } else {
         if (multiple) {
            if (selectedValue?.includes(option)) {
               onChange(selectedValue.filter(o => o !== option)) //delete option
            }
         }
      }
   }

   function isOptionSelected(option) {
      if (optionsArrayObject) {
         return multiple ? selectedValue?.some(v => v.value === option.value) : option?.value === selectedValue?.value
      } else {
         return multiple ? selectedValue?.includes(option) : selectedValue === option
      }

   }

   useEffect(() => {
      if (isOpen) setHighlightedIndex(0)
   }, [isOpen])

   useEffect(() => {
      const handler = (e) => {
         if (e.target != containerRef.current) return
         switch (e.code) {
            case "Enter":
            case "Space":
               setIsOpen(prev => !prev)
               if (isOpen) selectOption(options[highlightedIndex])
               break
            case "ArrowUp":
            case "ArrowDown": {
               if (!isOpen) {
                  setIsOpen(true)
                  break
               }

               const newValue = highlightedIndex + (e.code === "ArrowDown" ? 1 : -1)
               if (newValue >= 0 && newValue < options.length) {
                  setHighlightedIndex(newValue)
               }
               break
            }
            case "Escape":
               setIsOpen(false)
               break
         }
      }
      containerRef.current?.addEventListener("keydown", handler)

      return () => {
         containerRef.current?.removeEventListener("keydown", handler)
      }
   }, [isOpen, highlightedIndex, options])

   return (
      <div
         ref={containerRef}
         onBlur={() => setIsOpen(false)}
         onClick={() => setIsOpen(prev => !prev)}
         tabIndex={0}
         className={`${styles.container} ${classNamePrefix && classNamePrefix + '-container'}`}
      >
         <div className={`${styles.value} ${classNamePrefix && classNamePrefix + '-value'}`}>
            {multiple
               ? selectedValue?.map((v, i) => (
                  <span
                     key={`v${i}`}
                     className={`${styles["option-badge"]} ${classNamePrefix && classNamePrefix + '-option-badge'}`}
                  >
                     {(optionsArrayObject) ? v.label : v}
                     <span className={`${styles["remove-btn"]} ${classNamePrefix && classNamePrefix + '-remove-btn'}`}
                        onClick={e => {
                           e.stopPropagation()
                           removeOption(v)
                        }}>&times;</span>
                  </span>
               ))
               : (optionsArrayObject) ? selectedValue?.label : selectedValue
            }
            {(multiple || !selectedValue) && <span className={`${styles.placeholder} ${classNamePrefix && classNamePrefix + '-placeholder'}`}>Select</span>}
         </div>
         <button
            type='button'
            onClick={e => {
               e.stopPropagation()
               clearOptions()
            }}
            className={`${styles["clear-btn"]} ${classNamePrefix && classNamePrefix + '-clear-btn'}`}
         >
            &times;
         </button>
         <div className={`${styles.divider} ${classNamePrefix && classNamePrefix + '-divider'}`}></div>
         {arrow ? arrow : <div className={`${styles.caret} ${classNamePrefix && classNamePrefix + '-caret'}`}></div>}
         <ul className={`${styles.options} ${isOpen ? styles.show : ""}`}>
            {options?.map((option, index) => (
               <li
                  onClick={e => {
                     e.stopPropagation()
                     selectOption(option)
                     setIsOpen(false)
                  }}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  key={`option${index}`}
                  className={`${styles.option} 
                  ${isOptionSelected(option) ? styles.selected : ""} 
                  ${index === highlightedIndex ? styles.highlighted : ""}
                  ${classNamePrefix && classNamePrefix + '-option'}`}
               >
                  {(optionsArrayObject) ? option?.label : option}
               </li>
            ))}
         </ul>
      </div >
   )
}

export default React.memo(Select);

/* this is component is custom single and multi select component
   ** Props **
   -options : pass Array or array of object
      //Array
      const arr = ['option1','oprion2','option3']; 
      //if u want label is different and value is different
      const arrObj = [
         {label : 'option1' ,value : '1'},
         {label : 'option2' ,value : '2'},
         {label : 'option3' ,value : '3'},
      ]; 
   - optionsArrayObject : this is boolean - if option is arrayofobject set this props true else set false;
   -onChange: return select and removed values
   - selectedValue : pass selectd value  
   -multiple : this is for multi select functionality ; this is boolean 
   -arrow : pass custom arrow 
   -classNamePrefix - this for custom css
   if u want to chnage any thing first undertand code take copy of code and then make changes.

*/
