import React, { useState, useRef, useEffect } from "react";
import { BsFillCircleFill } from 'react-icons/bs';
import { FaPlay } from 'react-icons/fa';
import { IoStop, IoPause } from "react-icons/io5";
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import './videoRecorder.css'



const VideoRecorder = (props) => {

   const mediaRecorder = useRef(null);
   const liveVideoFeed = useRef(null);
   const myVideoFeed = useRef(null);

   const [permission, setPermission] = useState(false);
   const [recordingStatus, setRecordingStatus] = useState("inactive");
   const [stream, setStream] = useState(null);
   const [videoChunks, setVideoChunks] = useState([]);
   const [recordedVideo, setRecordedVideo] = useState(null);
   const [preview, setPreview] = useState(false);

   const [videoStatus, setVideoStatus] = useState('pause');
   const [permissionErrorModal, setPermissionErrorModal] = useState(false)


   //timer count down states
   const [second, setSecond] = useState(60);
   const [startTimer, setStartTimer] = useState(false)
   const [timerId, setTimerId] = useState(false)
   const [spinner, setSpinner] = useState(false)


   const getCameraPermission = async () => {
      setSecond(60);
      setPreview(false);
      setRecordedVideo(null);
      setVideoChunks([]);

      if ("MediaRecorder" in window) {
         try {
            const videoConstraints = {
               audio: false,
               video: true,
            };
            const audioConstraints = { audio: true };
            // create audio and video streams separately
            const audioStream = await navigator.mediaDevices.getUserMedia(
               audioConstraints
            );
            const videoStream = await navigator.mediaDevices.getUserMedia(
               videoConstraints
            );

            setPermission(true);

            //combine both audio and video streams
            const combinedStream = new MediaStream([
               ...videoStream.getVideoTracks(),
               ...audioStream.getAudioTracks(),
            ]);

            setStream(combinedStream);
            //set videostream to live feed player
            liveVideoFeed.current.srcObject = videoStream;
            //startRecording
            await startRecording(combinedStream)
         } catch (err) {
            setPermissionErrorModal(true);
            // alert('mohsin'+err.message);
         }
      } else {
         alert("The MediaRecorder API is not supported in your browser.");
      }
   };

   const startRecording = async (stream) => {
      setStartTimer(true);
      setRecordingStatus("recording");
      setPreview(false)
      setRecordedVideo(null)
      myVideoFeed.current.pause(); //pause preview video
      //start media recording code
      const media = new MediaRecorder(stream);
      mediaRecorder.current = media;
      mediaRecorder.current.start(1000);
      let localVideoChunks = [];
      mediaRecorder.current.ondataavailable = (event) => {
         if (typeof event.data === "undefined") return;
         if (event.data.size === 0) return;
         localVideoChunks.push(event.data);
      };
      setVideoChunks(localVideoChunks);
   };

   const stopRecording = () => {
      setPermission(false);
      setRecordingStatus("inactive");
      setStartTimer(false);
      setPreview(true);

      mediaRecorder.current.stop();
      mediaRecorder.current.onstop = () => {
         // const videoBlob = new Blob(videoChunks, { type: mimeType });
         const videoBlob = new Blob(videoChunks, { type: 'Video/mp4' });
         const videoUrl = URL.createObjectURL(videoBlob);
         setRecordedVideo(videoUrl);
         props.onRecordingComplete(videoBlob, videoUrl)
         // setVideoChunks([]);
      };
   };

   const recordingPreview = () => {
      setPreview(true);

      if (myVideoFeed.current !== null) {
         if (myVideoFeed.current.paused) { myVideoFeed.current.play(); setVideoStatus('play'); }
         else { myVideoFeed.current.pause(); setVideoStatus('pause'); }

         myVideoFeed.current.onplay = () => { setVideoStatus('play'); }
         myVideoFeed.current.onpause = () => { setVideoStatus('pause'); }
         myVideoFeed.current.onended = () => { setVideoStatus('pause'); }

      } else {
         setTimeout(() => {
            myVideoFeed.current.play(); setVideoStatus('play');
            myVideoFeed.current.onpause = () => { setVideoStatus('pause'); }
            myVideoFeed.current.onended = () => { setVideoStatus('pause'); }
            myVideoFeed.current.onplay = () => { setVideoStatus('play'); }
         }, 500);
      }
   }

   // handle done button click event
   const uploadVideo = async () => {
      stopCamera(); //stop Camera
      close(); // close camera
      props.hide() //hide modal
   }

   const close = () => {
      clearInterval(timerId);

      if (stream) {
         // Get the video and audio tracks from the media stream
         const videoTrack = stream.getVideoTracks()[0];
         const audioTrack = stream.getAudioTracks()[0];
         // Turn off the camera
         videoTrack.enabled = false;
         // Turn off the microphone
         audioTrack.enabled = false;
      }

      //set initial value 
      setPermission(false);
      setRecordingStatus("inactive");
      setStream(null);
      setVideoChunks([]);
      setRecordedVideo(null);
      setPreview(false);

      setSecond(60);
      setTimerId(null)
   }

   // turn off camera
   const stopCamera = () => {
      if (stream) {
         stream.getTracks().forEach(function (track) {
            track.stop();
         });
      }
   }


   useEffect(() => {
      //timer stuff
      let timer = null;
      if (startTimer) {
         timer = setInterval(() => {
            setSecond((prev) => {
               if (prev <= 1) stopRecording();
               return prev -= 1;
            })
         }, 1000);

         setTimerId(timer)
      } else {
         clearInterval(timerId);
      }

   }, [startTimer])


   const modalHide = () => {
      props.hide();
      close();
   }

   //handle permissionErrorModal Retry
   const handleRetry = () => {
      getCameraPermission();
      setPermissionErrorModal(false);
   }





   return (

      <div className="video-recorder">
         <main>
            {/* <!-- Modal --> */}
            <Modal size="lg" show={props.show} onHide={modalHide}
               backdrop="static" id='VideoRecorderModal' aria-labelledby="contained-modal-title-vcenter" centered
            >
               <Modal.Header className='p-0 justify-content-end'>
                  <div className="position-relative">
                     <AiOutlineClose onClick={modalHide}
                        className='icon close-icon-blue position-absolute' />
                  </div>
               </Modal.Header>
               <Modal.Body >
                  <div className='w-100 h-100'>
                     <div className="video-container">
                        <div className="video-player d-flex justify-content-center gap-5">
                           <div className={`video-card-wrapper ${(recordedVideo && preview) && 'flip'}`}>
                              <div className="single-card">
                                 <div className="front">
                                    {/* {liveVideoFeed && !preview ? ( */}
                                    <div className="live-player position-relative">
                                       <video className="w-100" ref={liveVideoFeed} autoPlay muted playsInline
                                          poster="/images/videoPosterImg.png"></video>
                                       <div className="countDown">
                                          <span className="digits minute">
                                             00:
                                          </span>
                                          <span className="digits sec">
                                             {second < 10 ? `0${second}` : second}
                                          </span>
                                       </div>
                                    </div>
                                    {/* ) : null} */}

                                 </div>
                                 <div className="back">
                                    {/* {recordedVideo && preview ? ( */}
                                    <div className="recorded-player">
                                       <video className="w-100" src={recordedVideo} ref={myVideoFeed} controls playsInline ></video>
                                    </div>
                                    {/* ) : null} */}
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="video-controls d-flex justify-content-evenly  pt-2 pb-1">
                           {/* {!permission ? (
                                    <button onClick={getCameraPermission} type="button">
                                       Get Camera
                                    </button>
                                    ) : null}  
                              */}
                           {!permission && recordingStatus === "inactive" ? (
                              <button className="border-0 bg-transparent text-danger" onClick={getCameraPermission} type="button" >
                                 <BsFillCircleFill className="icons icon-start" /> <p className="m-0">Start</p>
                              </button>
                           ) : null}
                           {recordingStatus === "recording" ? (
                              <button className="border-0 bg-transparent text-danger" onClick={stopRecording} type="button">
                                 <IoStop className="icons icon-stop" /> <p className="m-0">Stop</p>
                              </button>
                           ) : null}
                           {recordedVideo && (<>
                              <button className="border-0 bg-transparent text-success" onClick={recordingPreview} type="button">
                                 {
                                    (videoStatus === 'pause')
                                       ? <FaPlay className="icons icon-preview" />
                                       : <IoPause className="icons icon-preview" />
                                 }
                                 <p className="m-0">Preview</p>
                              </button>
                              <button className="btn btn-dark btn-sm btn-video__upload" onClick={uploadVideo} type="button">
                                 {
                                    spinner && <span className="spinner-border spinner-border-sm text-light me-1" role="status"></span>
                                 }
                                 {/* Upload Recording */}
                                 Done
                              </button>

                           </>)}
                        </div>
                     </div>
                  </div>

               </Modal.Body>
            </Modal>

            {/* permission Error Modal  */}
            <Modal show={permissionErrorModal} onHide={() => setPermissionErrorModal(false)}
               id='selectCandidateModal'>
               <Modal.Header className='p-0 justify-content-end'>
                  <div className="position-relative">
                     <AiOutlineClose onClick={() => setPermissionErrorModal(false)}
                        className='icon close-icon-blue position-absolute' />
                  </div>
               </Modal.Header>
               <Modal.Body>
                  <h5 className='text-center'>You have blocked Camera and Microphone access for Recording, please update your browser settings to allow access.</h5>
               </Modal.Body>
               <Modal.Footer className='flex-wrap justify-content-center'>
                  <div className="">
                     <button className='btn btn-outline-secondary mx-1' onClick={() => setPermissionErrorModal(false)}>
                        Close
                     </button>
                     <button className='btn btn-primary mx-1' type="button" onClick={handleRetry} >
                        Retry
                     </button>
                  </div>
               </Modal.Footer>
            </Modal>
         </main>
      </div >
   );
};
export default VideoRecorder;