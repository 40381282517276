import axois from 'axios';
import getBaseUrl from './http';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


export const useMailHelper = () => {
   const navigate = useNavigate();
   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)



   const sendMail = (toMail, otp, redirect_to = null) => {
      setIsLoading(true)
      setError(null)


      const body = { toMail, otp }
      axois.post(`${getBaseUrl}/mail/sendOTPMail`, body)
         .then((res) => {
            console.log(res);
            //set state
            setIsLoading(true);
            setError(null);
            //redirect to 
            if (redirect_to) {
               navigate(`${redirect_to}`)
            }
         }).catch((err) => {
            console.log(err)
            //set state
            setError('Something went wrong.Try again.');
            setIsLoading(false);
         })
   }

   const mailAfterEmailChange = (mailData) => {
      setIsLoading(true)
      setError(null)


      const body = mailData
      axois.post(`${getBaseUrl}/mail/afterEmailChange`, body)
         .then((res) => {
            console.log(res);
            //set state
            setIsLoading(true);
            setError(null);
         }).catch((err) => {
            console.log(err)
            //set state
            setError('Something went wrong.Try again.');
            setIsLoading(false);
         })
   }

   return { sendMail, mailAfterEmailChange, error, isLoading }
}