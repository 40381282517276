import React, { createContext, useMemo } from "react";
import { io } from "socket.io-client";
import getBaseUrl from '../hooks/http'


export const SocketContext = createContext(null)


export const SocketContextProvider = ({ children }) => {
   const url = `${getBaseUrl}`.replace(/\/api/g, '');
   const socket = useMemo(() => io.connect(url), []);

   return (
      <SocketContext.Provider value={socket}>
         {children}
      </SocketContext.Provider>
   )
}

