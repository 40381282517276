import React, { useState,useEffect } from 'react'
import "./register.css";
import EmpRegisterForm from './EmpRegisterForm';

import { useLocation } from 'react-router-dom';

function EmpRegister() {
   const INITIAL_DATA = {
      password: '',
      confirmPassword: ''
   }

   const {state} = useLocation();
const { errorMsg } = state || {}; // Read values passed on state


   const [data, setData] = useState(INITIAL_DATA)
   const [currentStepIndex, setcurrentStepIndex] = useState(0)

   
   const updateFields = (fields) => {
      console.log(fields);
      setData(prev => {
         return { ...prev, ...fields }
      })
   }
   const updateStepIndex = (index) => {
      setcurrentStepIndex(index)
   }

   




   const pages = [
      <EmpRegisterForm data={data} updateFields={updateFields} updateStepIndex={updateStepIndex}  errorMsg={errorMsg}/>
   ]




   return (
      <>
         {pages[currentStepIndex]}
      </>
   )
}

export default EmpRegister