import React from 'react';
import { TfiEmail } from "react-icons/tfi";
import { BsWhatsapp } from "react-icons/bs";
import { useWhatsAppHelper } from '../../../../hooks/useWhatsAppHelper'
import { useMailHelper } from '../../../../hooks/useMailHelper'
import generateOTP from '../../../../utils/GenerateOTP';



const EmpConfirmForm = ({ data, changeField, setcurrentStepIndex }) => {

   const { sendMail } = useMailHelper();
   const { sendMsg } = useWhatsAppHelper();


   //handleSendClick ----------------------
   const handleSendClick = async () => {
      //get otp
      const newOtp = generateOTP(6);
      //store the otp to localstorage
      localStorage.setItem('otp', newOtp)
      if (changeField === 'phone') {
         const to_Number = `${data.dialingCode}${data.phoneNumber}`;
         await sendMsg(to_Number, newOtp);
         setcurrentStepIndex(2)

      } else {
         const to_mail = data.email;
         await sendMail(to_mail, newOtp)
         setcurrentStepIndex(2)
      }
   }





   return (
      <div>
         {
            (changeField === 'phone') ?
               <p className="message text-center">
                  One time Password is sent by WhatsApp to
                  <br />
                  <BsWhatsapp color="#25D366" />({data.dialingCode})-{data.phoneNumber}
               </p>
               :
               <p className="message text-center">
                  One time Password is sent by Email to
                  <br />
                  <TfiEmail color="#25D366" /> {data.email}
               </p>
         }
         <div className="d-flex flex-wrap justify-content-evenly mt-5">
            <button className="btn btn-outline-primary mb-3" onClick={() => setcurrentStepIndex(0)}>
               CANCEL
            </button>
            <button className="btn btn-primary mb-3" onClick={handleSendClick}>SEND</button>
         </div>
      </div>
   )
}

export default EmpConfirmForm