import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import SignaturePad from 'react-signature-canvas'
import Datetime from 'react-datetime';
import { Container, Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Document, Page, pdfjs } from "react-pdf";
import { AiOutlineClose } from 'react-icons/ai';
import { FaCalendar } from 'react-icons/fa';
import { TfiEye } from "react-icons/tfi";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { PDFDocument, rgb } from "pdf-lib";

import Select from '../../../../components/select/Select.jsx';
import CustomModal from "../../../../components/customModal/CustomModal.jsx";
import getBaseUrl from '../../../../hooks/http'
import { useAuthContext } from '../../../../hooks/useAuthContext'
import { useSocketContext } from '../../../../hooks/useSocketContext.js';
import { CountContext } from '../../../../context/CountContext.js';
import DialogAlert from '../../../../components/customModal/customAlertModal';
import './empOffer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




const initialState = {
   show: false,
   error: '',
   candidateName: '',
   candidateGender: '',
   candidateCountry: '',
   employerName: '',
   offerId: '',
   salary: '',
   offDays: '',
   startDate: null,
   address: '',
   duties: '',
   nric: ''
}



const EmpOffer = () => {
   const { user } = useAuthContext();
   const socket = useSocketContext()
   const { updateOfferCount, updateSelectedCanCount } = useContext(CountContext)

   const [offers, setOffers] = useState([])
   const [sign, setSign] = useState()
   const [url, setUrl] = useState()
   const [minTime, setMinTime] = useState(null);
   const [formData, setFormData] = useState(initialState)
   const [CandId, setCandId] = useState('');
   const [error, setError] = useState({
      signaturePad: false,
      document: false
   })
   //pdf ------------------------------------------
   const [pageNum, setPageNum] = useState(1);
   const [totalPages, setTotalPages] = useState(0);
   const [pageDetails, setPageDetails] = useState(null);

   //make Payment Modal -----------------------------------
   const [makePaymentModal, setMakePaymentModal] = useState(false);
   const [paymentStep, setPaymentStep] = useState(1); //1 make payment ,2 card deatils, 3 succesfull
   const [creditCardNumber, setCreditCardNumber] = useState(null)
   const [creditCardError, setCreditCardError] = useState(false)
   const [selectedIndex, setSelectedIndex] = useState('');// selected candidate index for payment

   const [open, setOpen] = React.useState(false);
   const [dialogMessage, setDialogMessage] = useState('');

   const handleMakePaymentModalOpen = (i = '') => {
      setMakePaymentModal(true);
      setSelectedIndex(i);
   }
   const handleMakePaymentModalClose = () => {
      setMakePaymentModal(false);
      setTimeout(() => {
         setPaymentStep(1);
      }, 1000);
   }
   //handle credit cardNumber input onchange
   const handleCreditCardNumber = (e) => {
      const regx = /^[0-9\b]+$/;
      if (regx.test(e.target.value)) {
         setCreditCardNumber(e.target.value);
         setCreditCardError(false);
      }
   }
   //handle pay click
   const handlePayClick = async () => {
      try {
         //credit Card Number validation (not null, empty, undefined)
         if (!creditCardNumber) { setCreditCardError(true); return; }
         // api -----------------------------
         const { candidateId, employerId, offerId } = offers[selectedIndex];
         const data = { candidateId, employerId, offerId, creditCardNumber }
         let config = {
            headers: { Authorization: `Bearer ${user.token}`, },
         };

         const res = await axios.post(`${getBaseUrl}/onboarding/payment/`, data, config);

         if (res.status === 200) {
            setPaymentStep(3);
            setTimeout(() => {
               getOffers();
               handleMakePaymentModalClose();
            }, 2000);
            try {
               let config = {
                  headers: { Authorization: `Bearer ${user.token}`, },
               };
               const getOfferCount = await axios.get(`${getBaseUrl}/interviews/EmployergetCount`, config)

               updateOfferCount(getOfferCount.data.offerCount)
               updateSelectedCanCount(getOfferCount.data.selectedCandidateCount)
            } catch (e) {

            }
         }

      } catch (error) {
         console.log(error);
      }

   }
   //modal -----------------------------------
   const [offerDoc, setOfferDoc] = useState({
      show: false,
      file: ''
   });
   //modal -----------------------------------
   const [offerPdfViewer, setOfferPdfViewer] = useState({
      show: false,
      file: ''
   });
   //Modal ----------------------------------------------
   const [signModal, setSignModal] = useState(false);

   //offer form modal ----------------------------------------------------------
   const handleOfferFormClose = () => setFormData({ ...formData, show: false });
   const handleOfferFormShow = (i) => {
      const candidateName = offers[i].name;
      const candidateGender = offers[i].candidateGender;
      const candidateCountry = offers[i].candidateCountry;
      const offerId = offers[i].offerId;
      setFormData({
         ...formData, show: true,
         candidateName: candidateName,
         candidateGender: candidateGender,
         candidateCountry: candidateCountry,
         employerName: user.name,
         offerId: offerId,
         salary: '',
         offDays: '',
         startDate: null,
         address: '',
         duties: '',
         nric: ''
      });
   }
   // handle Continue Click --------------------------
   const handleContinueClick = () => {
      const { address, salary, offDays, startDate, duties, nric } = formData;
      if (!salary || !offDays || !startDate || !address || (duties.length === 0) || !nric) {
         setFormData({ ...formData, error: 'All fields are required.' });
      } else {
         setTimeout(() => {
            setFormData({ ...formData, show: false });
         }, 1000);
         //call generate pdf func
         generatePdf()
      }
   }
   // handle Form Input ----------------------------------
   const handleFormInput = (e) => {
      //only number
      const regx1 = /^[0-9\b]+$/;
      if (e.target.value !== '' && e.target.name === 'salary') {
         if (regx1.test(e.target.value))
            setFormData({ ...formData, [e.target.name]: e.target.value, error: '' })
      } else {
         setFormData({ ...formData, [e.target.name]: e.target.value, error: '' })
      }
      setUrl('')
   }
   const handleSatrtDate = (date) => {
      setFormData({ ...formData, startDate: date })
   }


   //offer doc modal -----------------------------------
   const handleOfferDocShow = () => setOfferDoc({ ...offerDoc, show: true });
   const handleOfferDocClose = () => setOfferDoc({ ...offerDoc, show: false });

   //sign modal ------------------------------------------
   const handleSignModalShow = () => {
      setSignModal(true);
      setError({ ...error, document: false });
   }
   const handleSignModalClose = () => setSignModal(false);

   const saveSign = async () => {
      if (!sign.isEmpty()) {
         const abc = trimLeftWhiteSpace();
         setUrl(abc.toDataURL('image/png'))
         setSignModal(false)
         //signature png image
         embeded(abc.toDataURL('image/png'))
      } else {
         setError({ ...error, signaturePad: true })
      }
   }

   const clearSign = () => {
      sign.clear()
   }

   const strokeBegin = () => {
      setError({ ...error, signaturePad: false })
   }

   //handle save Click -----------------------------
   const saveDocument = async () => {
      try {
         if (!!url) { //check doc is signed or not
            let data = new FormData()
            data.append('pdf', offerDoc.file)
            data.append('offerId', formData.offerId)
            data.append('offerAction', 'make')
            data.append('salary', formData.salary)
            data.append('startDate', formData.startDate)
            data.append('offDays', formData.offDays)

            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };

            const res = await axios.post(`${getBaseUrl}/offers/makeOffer/`, data, config);

            if (res.status === 200) {
               handleOfferDocClose();
               getOffers();
            }
         } else {
            setError({ ...error, document: true })
         }

      } catch (error) {
         console.log(error);
      }

   }

   //trim left singnature canvas side
   const trimLeftWhiteSpace = () => {
      const canvas = sign.getCanvas();

      // Find the bounding box of the signature
      const ctx = canvas.getContext('2d');
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const { data, width, height } = imageData;
      let minX = width;
      let minY = height;
      let maxX = 0;
      let maxY = 0;

      for (let y = 0; y < height; y++) {
         for (let x = 0; x < width; x++) {
            const pixelIndex = (y * width + x) * 4;
            if (data[pixelIndex + 3] > 0) { // Check if pixel is not transparent
               minX = Math.min(minX, x);
               minY = Math.min(minY, y);
               maxX = Math.max(maxX, x);
               maxY = Math.max(maxY, y);
            }
         }
      }
      // Create a new canvas for trimmed signature
      const trimmedWidth = maxX - minX + 1;
      const trimmedHeight = maxY - minY + 1;
      const trimmedCanvas = document.createElement('canvas');
      trimmedCanvas.width = trimmedWidth;
      trimmedCanvas.height = trimmedHeight;
      const trimmedCtx = trimmedCanvas.getContext('2d');

      // Draw the trimmed signature onto the new canvas
      trimmedCtx.drawImage(canvas, minX, minY, trimmedWidth, trimmedHeight, 0, 0, trimmedWidth, trimmedHeight);

      // Set the trimmed canvas as the reference for display
      return trimmedCanvas;
   };

   //handle embeded btn Click -----------------------------
   const embeded = async (signImg) => {

      const arrayBuffer = await fetch(offerDoc.originalFile).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(arrayBuffer)

      const pages = pdfDoc.getPages();
      const firstPage = pages[pageNum - 1];

      const pngImage = await pdfDoc.embedPng(signImg);
      const pngDims = pngImage.scale(.2);

      firstPage.drawImage(pngImage, {
         x: 55,
         y: 400,
         width: pngDims.width,
         height: pngDims.height,
      });

      const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });
      setOfferDoc({ ...offerDoc, file: pdfBytes });
   }



   //set offer pdf -------------------------
   const handleOfferPdf = (i) => {
      console.log(offers[i]);
      const offerPdf = offers[i].offerPdf;
      const employerId = offers[i].employerId;
      const filePath = `${offerPdf}`;
      setOfferPdfViewer({ ...offerPdfViewer, show: true, file: filePath });
   }

   // handleOfferPdfViewerClose -------------------------------
   const handleOfferPdfViewerClose = () => setOfferPdfViewer({ ...offerPdfViewer, show: false });
   const handleOfferPdfViewerShow = () => setOfferPdfViewer({ ...offerPdfViewer, show: true });


   //pdf generate code start ---------------------------------
   const generatePdf = async () => {
      try {
         let config = {
            headers: { Authorization: `Bearer ${user.token}`, },
         };

         const startDate = new Date(formData.startDate).toLocaleString('en-GB', {
            year: 'numeric', month: '2-digit', day: '2-digit',
         })
         const currentDate = new Date().toLocaleString('en-GB', {
            year: 'numeric', month: '2-digit', day: '2-digit',
         })
         const data = {
            candidateName: formData.candidateName,
            candidateGender: formData.candidateGender,
            candidateCountry: formData.candidateCountry,
            employerName: formData.employerName,
            address: formData.address,
            salary: formData.salary,
            offDays: formData.offDays,
            offerId: formData.offerId,
            startDate: startDate,
            duties: formData.duties,
            nric: formData.nric,
            singnature: url,
            currentDate: currentDate,
         }

         const res = await axios.post(`${getBaseUrl}/offers/create-pdf/`, data, config);
         console.log(res);
         if (res.status === 200) {
            const filePath = `/${res.data.destinationPath}/${res?.data?.fileName}`;
            setOfferDoc({ ...offerDoc, show: true, originalFile: filePath, file: filePath });
         }

      } catch (error) {
         console.log(error);
      }
   }
   //pdf generate code End -----------------------------------

   const checkFileExist = (url) => {
      var http = new XMLHttpRequest();
      if (url.length === 0) {
         return false;
      } else {
         http.open('HEAD', url, false);
         http.send();
         if (http.status === 200) {
            return true;
         } else {
            return false;
         }
      }
   }




   const [dataType, setDataType] = useState('currentOffer')
   const [classActiveName, setClassName] = useState('active')
   const [offersHistory, setOffersHistory] = useState([])

   //deleteConfirmationModal ----------------------------------------------
   const [formErrors, setFormErrors] = useState({});
   const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
      show: false,
      index: ''
   });

   const getOffers = async () => {
      try {
         if (user !== null) {
            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };

            const res = await axios.get(`${getBaseUrl}/offers/employer/`, config);

            updateOfferCount(res.data.offers.length);
            const data = await res.data.offers;
            console.log("offers", data);
            if (res.status === 200) {
               const offers = data.map(dt => {
                  const candidateName = dt?.candidateDetails[0]?.name ?? 'Unknown';
                  const candidatePhoto = dt.candidateDetails[0]?.photo;
                  const candidateGender = dt?.candidateDetails[0]?.gender;
                  const candidateCountry = dt?.candidateDetails[0]?.currentCountry;
                  const interviewDate = dt?.interview_date_time;
                  const status = dt?.status;
                  const offerId = dt?._id;
                  const candidateId = dt?.candidate_id
                  const employerId = dt?.employer_id

                  const baseUrl = (`${process.env.NODE_ENV}` === 'production') ? process.env.REACT_APP_API_PROD_URI : process.env.REACT_APP_API_DEV_URI;
                  const offerPdf = (dt?.offerPdf) ? `${getBaseUrl}/candidates/getMediaFileFromDb/${dt?.offerPdf}` : '';
                  const Is_OfferCreated = dt.Is_OfferCreated;
                  const Sender = dt.Sender;
                  const Candidate_Type = dt.Candidate_Type;

                  // const stateOfModel = (arrayOfIdAction[0] === we.Candidate_UserID && arrayOfIdAction[1] === "Reschedule" && action === "") ? true : false;

                  return {
                     name: candidateName,
                     photo: candidatePhoto,
                     candidateGender: candidateGender,
                     candidateCountry: candidateCountry,
                     interviewConductedDate: new Date(interviewDate),
                     reschedule: {
                        date: new Date(interviewDate),
                        isShow: false
                     },
                     status: status,
                     offerId: offerId,
                     offerPdf: offerPdf,
                     candidateId: candidateId,
                     employerId: employerId,
                     Is_OfferCreated: Is_OfferCreated,
                     Sender: Sender,
                     Candidate_Type: Candidate_Type
                  }
               })

               setOffers(offers)
               setOffersHistory([]);
            }
         }

      } catch (error) {
         console.log(error);
      }

   }


   // get Offers History data
   const getOffersHistory = async () => {
      try {
         if (user !== null) {
            let config = {
               headers: {
                  Authorization: `Bearer ${user.token}`,
               },
            };

            const res = await axios.get(`${getBaseUrl}/offers/history/employer/`, config);
            const interviewHistoryData = await res.data.interviewHistoryData;

            if (res.status === 200) {
               const data = interviewHistoryData.map(ihd => {
                  const InterDate = new Date(ihd?.Interview_Date_Time);
                  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                  const newDateTime = InterDate.toLocaleString('en-US', { timeZone: `${timezone}` })
                  const Interview_Date_Time = (newDateTime) ? new Date(newDateTime) : '';

                  return {
                     CandidateTempName: ihd?.candidateDetails[0]?.name ?? 'Unknown',
                     date: new Date(Interview_Date_Time),
                     interviewStatus: ihd?.Status,
                     reasonForCancel: ihd?.Reason_For_Cancel,
                  }
               });

               setOffers([]);
               setOffersHistory(data);
            }
         }
      } catch (error) {
         console.log(error);
      }
   }



   const handleEmpOfferStatusUpdate = data => {
      if (user.EmpId === data.empId) {
         console.log("event fire", data);
         getOffers(data.status);
      }
   }



   useEffect(() => {
      //Datapicker changes
      const currentDateTime = new Date();
      currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
      setMinTime(currentDateTime);
      const id = localStorage.getItem("candId");
      const arrayOfIdAction = id.split("_");
      setCandId(arrayOfIdAction[0]);
      if (dataType === 'currentOffer') getOffers();
      else getOffersHistory();



      //socket io stuff
      socket.on('empOfferStatusUpdate', handleEmpOfferStatusUpdate);

      return () => {
         socket.off('empOfferStatusUpdate', handleEmpOfferStatusUpdate);
         socket.disconnect();
      };
   }, [dataType, user])



   const handleStatusUpdate = (cndId, status) => {
      socket.emit('employerOfferUpdate', { cndId: cndId, status: status });
   };

   // open Rescheduled DatePickier
   const openRescheduledDatePickier = (e, i) => {

      const { top, left } = position(e);
      const list = [...offers];
      const scheduleDate = list[i]['interviewConductedDate'];
      const currentDateTime = new Date();
      if (new Date(scheduleDate).toDateString() === currentDateTime.toDateString()) {
         // If a future date is selected, clear the excluded times
         //setExcludedTimes([]);
         currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
         setMinTime(currentDateTime);
      } else {
         // If the current date or a past date is selected, update the excluded times
         const newd = new Date(
            currentDateTime.getFullYear(),
            currentDateTime.getMonth(),
            currentDateTime.getDate(),
            0, // Hours (midnight)
            0  // Minutes
         );
         setMinTime(newd);
      }

      for (let index = 0; index < list.length; index++) {
         if (index !== i) {
            list[index]['reschedule']['isShow'] = false;
            list[i]['reschedule']['error'] = false;
            list[i]['reschedule']['error2'] = false;
         }
      }
      list[i]['reschedule']['isShow'] = (list[i].reschedule.isShow) ? false : true;
      list[i]['reschedule']['top'] = top;
      list[i]['reschedule']['left'] = left;
      if (list[i]['Sender'] == "") {
         list[i]['reschedule']['date'] = new Date();
      } else {
         list[i]['reschedule']['date'] = list[i]['interviewConductedDate'];
      }

      setOffers(list)
   }
   // closeRescheduledDatePickier
   const closeRescheduledDatePickier = (i) => {
      const list = [...offers];
      list[i]['reschedule']['isShow'] = false;
      list[i]['reschedule']['error'] = false;
      list[i]['reschedule']['error2'] = false;

      const lc_id = localStorage.getItem('candId');
      const arrayOfIdAction = lc_id.split("_");
      /*localStorage.setItem('candId', arrayOfIdAction[0]);*/
      setClassName('false');
      localStorage.setItem('candId', null);
      setOffers(list);

   }
   //handle Rescheduled Date Change
   const handleRescheduledDateChange = (date, i) => {
      const currentDateTime = new Date();
      if (new Date(date).toDateString() === currentDateTime.toDateString()) {
         // If a future date is selected, clear the excluded times
         //setExcludedTimes([]);
         currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
         setMinTime(currentDateTime);

      } else {
         // If the current date or a past date is selected, update the excluded times
         const newd = new Date(
            currentDateTime.getFullYear(),
            currentDateTime.getMonth(),
            currentDateTime.getDate(),
            0, // Hours (midnight)
            0  // Minutes
         );
         setMinTime(newd);

      }
      const list = [...offers];
      list[i]['reschedule']['date'] = new Date(date);
      list[i]['reschedule']['error'] = false;
      list[i]['reschedule']['error2'] = false;
      setOffers(list)
   }
   // handleRescheduleClick
   const handleRescheduleClick = (i) => {
      const list = [...offers];

      var preDate = new Date(list[i].date);
      var newDate = new Date(list[i].reschedule.date);
      var currentDate = new Date();
      const timeDifference = newDate - currentDate;
      const timeDifference2 = Math.round((timeDifference / 1000) / 60);

      if (preDate.getTime() === newDate.getTime()) {
         list[i]['reschedule']['error'] = true;
         setOffers(list);
      } else if (timeDifference2 <= 30) {
         list[i]['reschedule']['error2'] = true;
         setOffers(list);
      } else {
         list[i]['reschedule']['isShow'] = false;
         setOffers(list);
         //WA msg
         sendMeetingWhatsapp(i);
      }
   }

   const sendMeetingWhatsapp = async (index) => {
      try {
         const listData = offers[index];
         const res = await axios.post(`${getBaseUrl}/employer/offerMeetingInvitationToCnd`, listData);
         console.log(res);
         if (res.status === 200) {
            //getInterviews('reshedule')
            //closeRescheduledDatePickier
            getOffers('reshedule');
            closeRescheduledDatePickier(index)
            handleStatusUpdate(listData.candidateId, 'reshedule')
         }
      } catch (error) {
         console.log(error);
      }
   };


   const position = (e, sl = false) => {
      const target = (sl) ? e : e.target;
      let bodyRect = document.body.getBoundingClientRect();
      let btnRect = target.getBoundingClientRect();
      //reschedule calendar popup width and height
      const popupWidth = 320;
      const popupHeight = 430;

      const tableWrapper = document.querySelector(".box");
      const tableWrapperReact = tableWrapper.getBoundingClientRect();

      let btnOffsetTop = btnRect.top - bodyRect.top;
      let btnOffsetLeft = btnRect.left - bodyRect.left;

      let scroll = document.documentElement.scrollTop || document.body.scrollTop;
      let top = (btnOffsetTop - popupHeight - 10) - scroll;
      let left = (btnOffsetLeft + (target.offsetWidth / 2) - (popupWidth / 2));

      if (tableWrapperReact.width < btnRect.left) left = btnRect.left - popupWidth; //left - 40;
      if (btnRect.top < popupHeight) top = (btnRect.top / 2); left = btnRect.left - popupWidth;
      if (btnRect.left < popupWidth + 2) left = 'calc(50% - 160px)';

      return { top, left };
   }

   //Cancel Offers
   const handleCancelOfferClick = (i) => {
      //set reasonforcancel value null
      offers[i]['reasonforcancel'] = '';
      //show modal
      setDeleteConfirmationModal({
         ...deleteConfirmationModal,
         show: true, index: i
      });
   }

   const handleClose = (i) => {
      //set reasonforcancel value null
      offers[i]['reasonforcancel'] = '';
      //hide modal
      setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false });
      //set error null
      setFormErrors("")
   };

   const handleReasonOfCancal = (cancelreason, i) => {
      const list = [...offers];
      setFormErrors("")
      list[i]['reasonforcancel'] = cancelreason.target.value;
      setOffers(list)

   }
   const cancelOffer = async (index) => {
      const listData = offers[index];
      try {
         if (!!listData.reasonforcancel) { //code by mohsin
            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };
            const res = await axios.post(`${getBaseUrl}/offers/offercancelByEmployer`, listData, config);
            if (res.status === 200) {
               getOffers();
               setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false });
               handleStatusUpdate(listData.candidateId, 'cancel')
               try {
                  let config = {
                     headers: { Authorization: `Bearer ${user.token}`, },
                  };
                  const getOfferCount = await axios.get(`${getBaseUrl}/interviews/EmployergetCount`, config)
                  updateOfferCount(getOfferCount.data.offerCount)
               } catch (e) {

               }

            }
         } else {
            let errors = {};
            errors.msg = "This is required field";
            setFormErrors(errors);
         }

      } catch (error) {
         console.log(error);
      }
   }


   //download pdf --------------------------------------------
   const downloadPdf = async () => {
      try {
         if (user !== null) {
            const response = await axios.get(`${getBaseUrl}/agreement/download-pdf/${offerPdfViewer.file.split('/').pop()}`, {
               responseType: 'blob', // Important: tells Axios to treat the response as binary data (file)
            });

            // Create a blob from the file data and download it
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // Extract the file name from the URL
            const originalFileName = offerPdfViewer.file.split('/').pop().split('?')[0];
            // Get the file extension
            const extension = originalFileName.split('.').pop();
            let fileName = `Offers.${extension}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Set the filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up
         }

      } catch (error) {
         console.log(error);
      }
   }

   //print doc ------------------------------------------------
   const printPdf = async () => {

      const url = `${getBaseUrl}`.replace(/\/api/g, '')
      // Replace 'path-to-your-pdf.pdf' with the actual path to your PDF file
      const pdfUrl = `${offerPdfViewer.file}`;

      // Open the PDF in a new tab
      const newTab = window.open(pdfUrl, '_blank');

      // Wait for the PDF to load, then trigger the print dialog
      newTab.onload = () => {
         newTab.print();
      };
   }

   //pdf -----------------------------------------------
   const pdfPrevPage = () => {
      setPageNum(pageNum - 1)
   }
   const pdfNextPage = () => {
      setPageNum(pageNum + 1)
   }

   const handleAlertClose = () => {
      setOpen(false);
   };

   //handle confirm Interview click
   const confirmMeeting = (index) => {
      const listData = offers[index];
      axios.post(`${getBaseUrl}/interviews/meetConfirmByEmployer`, listData)
         .then(async (res) => {
            console.log(res.data);
            if (res.status === 200) {
               getOffers('confirm');
               handleStatusUpdate(listData.candID, 'confirm')
            }
         })
         .catch((error) => {
            // handle error
            console.log(error);
            // Handle error response
            if (error.response && error.response.status === 400) {
               setOpen(true);
               setDialogMessage(error.response.data.message)
               // Display error message from backend
            } else {
            }
         })
   }

   //handle cancle interview click
   const handleCancelMeeting = (index) => {
      const listData = offers[index];
      axios.post(`${getBaseUrl}/interviews/meetCancelByEmployer`, listData)
         .then(async (res) => {

         }).catch((error) => {
            // handle error
            console.log(error);
         })

   }

   const renderInput = (props, openCalendar) => {
      return (
         <div className='d-flex align-items-center'>
            <input {...props} onClick={openCalendar} className='flex-grow-1 border-0 p-0 h-100' />
            <FaCalendar onClick={openCalendar} style={{ cursor: 'pointer' }} />
         </div>
      );
   };



   return (
      <div className='empOffer'>
         <Container>
            <Row className='my-5'>
               <Col sm={12} className='pb-2'>
                  <div className="text-center pb-5">
                     <div className="form-check form-check-inline mb-3 mb-sm-0">
                        <input className=" d-none" type="radio" name="offer" id="currentOffer" value="currentOffer"
                           checked={(dataType === 'currentOffer') ? true : false} onChange={(e) => setDataType(e.target.value)} />
                        <label className="form-check-label btn btn-outline-blue" htmlFor="currentOffer">Offer</label>
                     </div>
                     <div className="form-check form-check-inline">
                        <input className="d-none" type="radio" name="offer" id="offersHistory" value="offersHistory"
                           checked={(dataType === 'offersHistory') ? true : false} onChange={(e) => setDataType(e.target.value)} />
                        <label className="form-check-label btn btn-outline-blue" htmlFor="offersHistory">Offer History</label>
                     </div>
                  </div>
               </Col>
               <Col>

                  <div className="table-responsive interview-list box">
                     {

                        (dataType === 'currentOffer') &&

                        <Table className="table table-striped m-0 tb">
                           <Thead className='table-dark text-center'>
                              <Tr>
                                 <Th className='py-3 hash' scope="col">#</Th>
                                 <Th></Th>
                                 <Th className='py-3' scope="col">Candidate</Th>
                                 <Th className='py-3' scope="col">Candidate Type</Th>
                                 <Th className='py-3' scope="col">Interview Conducted on</Th>
                                 <Th className='py-3' scope="col">Status</Th>
                                 <Th className='py-3' scope="col">Action</Th>
                              </Tr>
                           </Thead>
                           <Tbody className="text-center">
                              {
                                 offers.map((offer, index) => {
                                    return (
                                       <Tr key={index}
                                          className={`${CandId === offer.candidateId && classActiveName}`}>
                                          <Td className='srNo'>{index + 1}</Td>
                                          <Td className='profile-wrapper'>
                                             <div className="profile-picture">
                                                {offer.name.charAt(0).toUpperCase()}
                                             </div>
                                          </Td>
                                          <Td>{offer?.name?.length > 20 ? `${offer?.name?.slice(0, 20)}...` : offer?.name}</Td>
                                          <Td>{offer?.Candidate_Type}</Td>
                                          <Td>{
                                             offer.interviewConductedDate.toLocaleString('en-GB', {
                                                year: 'numeric', month: '2-digit', day: '2-digit',
                                                hour: '2-digit', minute: '2-digit', hour12: true
                                             }).replace(/,/g, ' on ').replace(/00:/g, '12:')
                                          }
                                             <br />
                                             {
                                                `${offer.interviewConductedDate}`.substring(
                                                   `${offer.interviewConductedDate}`.indexOf("("),
                                                   `${offer.interviewConductedDate}`.lastIndexOf(")") + 1
                                                )
                                             }
                                          </Td>
                                          <Td>{offer.status}</Td>
                                          <Td className=''>
                                             <div className="d-flex ">
                                                <div className="d-flex flex-column align-self-center mx-2 my-2 flex-sm-row">
                                                   {
                                                       (offer.status === 'Candidate Accepted Offer') &&
                                                      <button className='btn btn-green btn-makePayment mx-auto mt-1' onClick={() => { handleMakePaymentModalOpen(index) }}>Make Payment</button>
                                                   }
                                                </div>
                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                   <div className='icon icon1 mx-1 mx-sm-3' onClick={() => handleOfferFormShow(index)}>
                                                      <img src="/images/makeOffer.png" alt="" />
                                                      <span className="text">MAKE OFFER</span>
                                                   </div>
                                                   <div className='icon icon2 mt-3 mx-1 mx-sm-3' onClick={(event) => openRescheduledDatePickier(event, index)}>
                                                      <img src="/images/schedule.png" alt="" />
                                                      <span className="text" >SCHEDULE MEETING</span>
                                                   </div>

                                                </div>
                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                   <div className={`icon icon1 mx-1 mx-sm-3 ${(offer?.Is_OfferCreated === 'Yes') ? "NoStyle" : "disabled-image"}`} onClick={() => handleOfferPdf(index)}>
                                                      <TfiEye className='fs-4' />
                                                      <span className="text">View OFFER</span>
                                                   </div>
                                                   <div className={`icon icon2 mt-3  mx-sm-3 ${(offer?.status === 'Reschedule - Pending Employer Confirmation for Offer Meeting' || offer?.status === "Pending Employer Confirmation for Offer Meeting") ? "NoStyle" : "pe-none"}`} onClick={() => confirmMeeting(index)}>
                                                      <img src="/images/confirm.png" alt=""
                                                         className={`${(offer?.status === 'Reschedule - Pending Employer Confirmation for Offer Meeting' || offer?.status === "Pending Employer Confirmation for Offer Meeting") ? "NoStyle" : "disabled-image"}`}
                                                      />
                                                      <span className={`${(offer?.status === 'Reschedule - Pending Employer Confirmation for Offer Meeting' || offer?.status === "Pending Employer Confirmation for Offer Meeting") ? "" : "text-secondary"} text`}>CONFIRM MEETING</span>
                                                   </div>
                                                </div>
                                                <div className="d-flex flex-column align-items-center justify-content-center">

                                                   <div className={`icon icon1 mx-1 mx-sm-3 ${(offer?.Is_OfferCreated === 'Yes') ? "NoStyle" : "disabled-image"}`} onClick={() => handleCancelOfferClick(index)}>
                                                      <img src="/images/cancelOffer.png" />
                                                      <span className="text">CANCEL OFFER</span>
                                                   </div>
                                                   <div className='icon icon3 mt-3 mx-1 mx-sm-3' onClick={() => handleCancelMeeting(index)}>
                                                      <img src="/images/cancel.png" alt="" />
                                                      <span className="text">CANCEL MEETING</span>
                                                   </div>


                                                </div>
                                             </div>


                                          </Td>
                                       </Tr>
                                    )
                                 })
                              }
                              {
                                 (offers.length === 0) && <Tr>
                                    <Th colSpan='7'>Record Not Found.</Th>
                                 </Tr>
                              }
                           </Tbody>
                        </Table>
                     }


                     {
                        (dataType === 'offersHistory') &&
                        <Table className="table table-striped m-0">
                           <Thead className='table-dark text-center'>
                              <Tr>
                                 <Th className='py-3 hash' scope="col">#</Th>
                                 <Th></Th>
                                 <Th className='py-3' scope="col">Candidate</Th>
                                 <Th className='py-3' scope="col">Offer Process Ended on</Th>
                                 <Th className='py-3' scope="col">Status</Th>
                                 <Th className='py-3' scope="col">Remarks</Th>

                              </Tr>
                           </Thead>
                           <Tbody className="text-center">
                              {offersHistory.map((offers, index) => (
                                 <Tr key={index}>
                                    <Td className='px-3 srNo'>{index + 1}</Td>
                                    <Td className='profile-wrapper'>
                                       <div className="profile-picture">
                                          {offers.CandidateTempName.charAt(0).toUpperCase()}
                                       </div>
                                    </Td>
                                    <Td>{offers?.CandidateTempName?.length > 20 ? `${offers?.CandidateTempName?.slice(0, 20)}...` : offers?.CandidateTempName}</Td>
                                    <Td className="interview-on">
                                       {
                                          offers.date.toLocaleString('en-GB', {
                                             year: 'numeric', month: '2-digit', day: '2-digit',
                                             hour: '2-digit', minute: '2-digit', hour12: true
                                          }).replace(/,/g, ' on ').replace(/00:/g, '12:')
                                       }
                                       <br />
                                       {
                                          `${offers.date}`.substring(
                                             `${offers.date}`.indexOf("("),
                                             `${offers.date}`.lastIndexOf(")") + 1
                                          )
                                       }
                                    </Td>
                                    <Td className="status">{offers.interviewStatus}</Td>
                                    <Td className="pb-4 pb-sm-0">{offers.reasonForCancel}</Td>
                                 </Tr>

                              ))}

                              {
                                 (offersHistory.length === 0) && <Tr>
                                    <Th colSpan='6'>Record Not Found.</Th>
                                 </Tr>
                              }

                           </Tbody>
                        </Table>
                     }

                  </div>
               </Col>
            </Row>



            {/* view pdf Modal   */}
            <Modal
               size="lg"
               show={offerPdfViewer.show}
               onHide={handleOfferPdfViewerClose}
               backdrop="static"
               keyboard={false}
            >
               <Modal.Header className='p-0 justify-content-end border-0'>
                  <div className="position-relative">
                     <AiOutlineClose onClick={handleOfferPdfViewerClose}
                        className='icon close-icon-blue position-absolute' />
                  </div>
               </Modal.Header>
               <Modal.Body>
                  <div>
                     <div className='d-flex justify-content-center'>
                        <div className='icon mx-1 mx-sm-3' onClick={downloadPdf}>
                           <img src="/images/download.png" alt="" className='download' />
                        </div>
                        <div className='icon mx-1 mx-sm-3' onClick={printPdf}>
                           <img src="/images/printer.png" alt="" className='printer' />
                        </div>
                     </div>
                     <div>
                        <Document
                           file={offerPdfViewer.file}
                           onLoadSuccess={(data) => {
                              setTotalPages(data.numPages);
                           }}
                        >
                           <Page
                              renderTextLayer={false}
                              renderAnnotationLayer={false}
                              customTextRenderer={false}
                              pageNumber={pageNum}
                              className='w-100'
                              onLoadSuccess={(data) => {
                                 setPageDetails(data);
                              }}
                           />
                        </Document>
                        {
                           (totalPages > 1) &&
                           <div className="page-controls d-flex justify-content-center align-items-center">
                              <button type="button" className='btn btn-primary' onClick={pdfPrevPage}
                                 disabled={(pageNum === 1) ? true : false}>‹</button>
                              <span className='mx-2'>{pageNum} of {totalPages}</span>
                              <button type="button" className='btn btn-primary' onClick={pdfNextPage}
                                 disabled={(pageNum === totalPages) ? true : false}>›</button>
                           </div>
                        }

                     </div>
                  </div>
               </Modal.Body>
            </Modal>




            {/* offer form modal */}
            <Modal
               show={formData.show}
               onHide={handleOfferFormClose}
               backdrop="static"
               keyboard={false}
               id='offerFormModal'
            >
               <Modal.Header className='p-0 justify-content-end border-0'>
                  <div className="position-relative">
                     <AiOutlineClose onClick={handleOfferFormClose}
                        className='icon close-icon-blue position-absolute' />
                  </div>
               </Modal.Header>
               <Modal.Body>
                  <div>
                     <h5 className='mb-4 text-center'>Please provide below details for Offer Letter</h5>
                     {(formData.error !== '') && <p className="text-danger">{formData.error}</p>}
                     <div>
                        <Row>
                           <Col sm={12}>
                              <div className="mb-3 d-flex flex-wrap align-items-center gap-10">
                                 <label htmlFor="address" className=''>Complete home Address where Helper will work :</label>
                                 <div className="floating-control m-0 w-100">
                                    <input type="text" name="address" className="floating-input"
                                       placeholder="none" autoComplete='off' required
                                       onChange={handleFormInput} value={formData.address} />
                                 </div>
                              </div>
                           </Col>
                           <Col sm={12}>
                              <div className="mb-3 d-flex flex-wrap align-items-center gap-10">
                                 <label htmlFor="salary" className='white-space-nowrap w-106'>Salary to Offer : </label>
                                 <div className="floating-control m-0 w-160">
                                    <input type="text" name="salary" className="floating-input"
                                       placeholder="none" autoComplete='off' required
                                       onChange={handleFormInput} value={formData.salary} />
                                 </div>
                                 <span className='white-space-nowrap'>Singapore Dollars</span>
                              </div>
                           </Col>
                           <Col sm={12}>
                              <div className="mb-3 d-flex align-items-center gap-10">
                                 <label htmlFor="startDate" className='white-space-nowrap w-106'>Start Date : </label>
                                 <div className="floating-control m-0 w-160">
                                    <div className='floating-input'>
                                       <Datetime
                                          className=''
                                          value={formData.startDate}
                                          onChange={handleSatrtDate}
                                          dateFormat="DD/MM/YYYY"
                                          timeFormat={false}
                                          renderInput={renderInput}
                                          inputProps={{ placeholder: "Select" }}
                                          closeOnSelect={true}
                                          isValidDate={(current) => current.isAfter(new Date()) || current.isSameOrAfter(new Date().setDate(new Date().getDate() - 1))} // Set the minimum date
                                       />
                                    </div>
                                 </div>
                              </div>

                           </Col>
                           <Col sm={12}>
                              <div className='mb-3 d-flex align-items-center gap-10'>
                                 <label htmlFor="offDays" className='white-space-nowrap'>No.of Weekly Off Days : </label>
                                 <div className="floating-control m-0 w-120">
                                    <select className='floating-input'
                                       name='offDays' id='offDays' onChange={handleFormInput} value={formData.offDays}>
                                       <option value="">Select</option>
                                       <option value="1">1</option>
                                       <option value="2">2</option>
                                       <option value="3">3</option>
                                       <option value="4">4</option>
                                       <option value="All">All</option>
                                    </select>
                                    <IoIosArrowDown className='arrowDown' />
                                 </div>
                              </div>
                           </Col>
                           <Col sm={12}>
                              <div className='mb-3 d-flex align-items-center gap-10'>
                                 <label htmlFor="duties" className='white-space-nowrap w-106'>Work Duties:</label>
                                 <div className="m-0 flex-grow-1">
                                    <div className="floating-control m-0">
                                       <input className="floating-input" type="text" name="duties" value={formData.duties}
                                          placeholder="none" autoComplete='off' required
                                          onChange={handleFormInput} />
                                    </div>
                                 </div>
                              </div>
                           </Col>
                           <Col sm={12}>
                              <div className='d-flex align-items-center gap-10'>
                                 <label htmlFor="nric" className='white-space-nowrap w-106'>NRIC:</label>
                                 <div className="m-0 flex-grow-1">
                                    <div className="floating-control m-0">
                                       <input className="floating-input" type="text" name="nric" value={formData.nric}
                                          placeholder="none" autoComplete='off' required
                                          onChange={handleFormInput} />
                                    </div>
                                 </div>
                              </div>
                           </Col>
                        </Row>
                     </div>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <button className='btn btn-primary' onClick={handleContinueClick}>Continue</button>
               </Modal.Footer>
            </Modal>

            <Modal
               size="lg"
               show={offerDoc.show}
               onHide={handleOfferDocClose}
               backdrop="static"
               keyboard={false}
            >
               <Modal.Header className='p-0 justify-content-end border-0'>
                  <div className="position-relative">
                     <AiOutlineClose onClick={handleOfferDocClose}
                        className='icon close-icon-blue position-absolute' />
                  </div>
               </Modal.Header>
               <Modal.Body>
                  <span className='btn-back d-flex align-items-center ps-4' onClick={() => { handleOfferDocClose(); setFormData({ ...formData, show: true }); }}><IoArrowBackOutline /> Back to edit data</span>
                  <div className='d-flex justify-content-center'>
                     <button className='btn btn-outline-primary mx-2' onClick={handleSignModalShow}>Add sign</button>
                     <button className='btn btn-outline-primary mx-2' onClick={() => saveDocument()}>Save Document</button>
                  </div>
                  {
                     (error.document) && <div className='text-danger text-center p-2'>Document can not be saved. Signature is required.</div>
                  }
                  <div>
                     <Document
                        file={offerDoc.file}
                        onLoadSuccess={(data) => {
                           setTotalPages(data.numPages);
                        }}
                     >
                        <Page
                           renderTextLayer={false}
                           renderAnnotationLayer={false}
                           customTextRenderer={false}
                           pageNumber={pageNum}
                           className='w-100'
                           onLoadSuccess={(data) => {
                              setPageDetails(data);
                           }}
                        />
                     </Document>
                  </div>
               </Modal.Body>
            </Modal>


            <Modal
               size="lg"
               show={signModal}
               onHide={handleSignModalClose}
               backdrop="static"
               keyboard={false}
               id="signaturePadModal"
            >
               <Modal.Header className='p-0 justify-content-end border-0'>
                  <div className="position-relative">
                     <AiOutlineClose onClick={handleSignModalClose}
                        className='icon close-icon-blue position-absolute' />
                  </div>
               </Modal.Header>
               <Modal.Body>
                  <div className='scontainer mt-1 mt-sm-3'>
                     <div className='sigContainer'>
                        <label className="sign-label mb-1">Signature</label>
                        <SignaturePad velocityFilterWeight={0.8} minWidth={0.5} maxWidth={2.5} dotSize={2} throttle={0} onBegin={strokeBegin} canvasProps={{ height: 300, className: 'sigPad' }}
                           ref={data => setSign(data)} />
                     </div>
                     {
                        (error.signaturePad) && <div className='text-danger text-center'>Signature can not be blank.</div>
                     }
                  </div>
                  <div className='d-flex flex-wrap justify-content-center'>
                     <button className='btn btn-outline-secondary mt-2 mx-2' onClick={clearSign}>Clear Signature</button>
                     <button className='btn btn-primary mt-2 mx-2' onClick={saveSign}>Save Signature</button>
                  </div>
               </Modal.Body>
            </Modal>


         </Container>
         {/* reschedule-popup */}
         {
            offers.map((offer, index) =>
               <CustomModal key={index} isOpen={offer.reschedule.isShow} onClose={() => closeRescheduledDatePickier(index)}>
                  <div className={`reschedule-popup`}
                     style={{ top: offer.reschedule.top, left: offer.reschedule.left }}
                  >
                     <div className='reschedule-popup-date bg-blue mb-2 text-center py-2'>
                        Current Meeting : <br />
                        {offer.reschedule.date.toLocaleString('en-GB', {
                           year: 'numeric', month: '2-digit', day: '2-digit',
                           hour: '2-digit', minute: '2-digit', hour12: true
                        }).replace(/00:/g, '12:')
                        }
                        <br />
                        {
                           `${offer.reschedule.date}`.substring(
                              `${offer.reschedule.date}`.indexOf("("),
                              `${offer.reschedule.date}`.lastIndexOf(")") + 1
                           )
                        }
                     </div>

                     <Datetime
                        className=''
                        value={offer.reschedule.date}
                        onChange={(date) => handleRescheduledDateChange(date, index)}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="hh:mm A" // Set time format to AM/PM
                        isValidDate={(current) => current.isAfter(new Date()) || current.isSameOrAfter(new Date().setDate(new Date().getDate() - 1))} // Set the minimum date
                        input={false}
                     />
                     {offer.reschedule.error &&
                        <div className='bg-white text-danger fs-6 mb-2 text-center'>Please select Date and Time</div>
                     }
                     {offer.reschedule.error2 &&
                        <div className='bg-white text-danger fs-6 mb-2 text-center'>Time selected should be <br /> later than Current Time</div>
                     }
                     <div className="d-flex justify-content-center mt-2">
                        <button className="btn btn-primary btn-sm me-1"
                           onClick={() => handleRescheduleClick(index)}>SCHEDULE
                        </button>
                        <button className='btn btn-secondary btn-sm ms-1'
                           onClick={() => closeRescheduledDatePickier(index)}>
                           cancel
                        </button>
                     </div>
                  </div>
               </CustomModal>
            )
         }

         {/* delete confoirmation modal  */}
         <Modal
            show={deleteConfirmationModal.show}
            onHide={() => handleClose(deleteConfirmationModal.index)}
            id='deleteConfirmationModal' className="confirmationBox"
         >
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={() => handleClose(deleteConfirmationModal.index)}
                     className='icon close-icon-blue position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body>
               <h3 className="text-center mb-3">Confirmation</h3>
               <Form>
                  <Form.Group
                     className="mb-3"
                     controlId="exampleForm.ControlTextarea1"
                  >
                     <Form.Label>Reason for Cancellation?</Form.Label>
                     <Form.Control as="textarea" rows={3} name="cancelreason"
                        value={offers[deleteConfirmationModal.index]?.reasonforcancel}
                        onChange={(cancelreason) => handleReasonOfCancal(cancelreason, deleteConfirmationModal.index)}
                        required />
                     {formErrors.msg && <div className='text-danger'>{formErrors.msg}</div>}
                  </Form.Group>
               </Form>
               <h5>Are you sure you want to cancel offer?</h5>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="outline-secondary" onClick={() => handleClose(deleteConfirmationModal.index)}>
                  NO
               </Button>
               <Button variant="danger" type="button" onClick={() => cancelOffer(deleteConfirmationModal.index)} >
                  YES
               </Button>
            </Modal.Footer>
         </Modal>



         {/* ---- MAKE PAYMENT MODAL ----   */}

         <Modal
            size="lg"
            show={makePaymentModal}
            onHide={handleMakePaymentModalClose}
            backdrop="static"
            keyboard={false}
         >
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={handleMakePaymentModalClose}
                     className='icon close-icon-blue position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body>
               {/* -------------- PAYMENT DETAILS ------------------- */}
               <div className={`paymentDetails-Wrapper ${(paymentStep !== 1) && 'd-none'}`}>
                  <h3 className='paymentDetails-title text-center'>Payment Details</h3>
                  <p className='paymentDetails-note px-md-5'>Nonrefundable Deposit payment to be made before proceed to view
                     Candidate details such as Passport, Medical reports, Contact details, etc.
                     for applying the Work Permit for Candidate.</p>

                  <div className='paymentDetails-slip px-md-5'>
                     <table className='table'>
                        <thead>
                           <th>Description</th>
                           <th>Amount to be Paid</th>
                        </thead>
                        <tbody>
                           <tr>
                              <td>Nonrefundable deposit</td>
                              <td>200S$</td>
                           </tr>
                           <tr>
                              <th className='text-end'>Payment Due </th>
                              <th>200S$ (incl. GST)</th>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <p className='text-danger px-md-5'>
                     <span className='fw-bold me-1'>IMPORTANT :</span>
                     Balance payment of 150$ (incl. GST) to be paid once Candidate starts the work singapore.
                  </p>
                  <div className='d-flex flex-wrap justify-content-center pt-3'>
                     <button className='btn btn-green m-1' onClick={() => setPaymentStep(2)}> PROCEED PAYMENT </button>
                     <button className='btn btn-secondary rounded-pill m-1' onClick={handleMakePaymentModalClose}> CANCEL </button>
                  </div>
               </div>

               {/* ---------------  PAYMENT CARD DETAILS !!   -------------- */}
               <div className={`paymentCardDetails-wrapper ${(paymentStep !== 2) && 'd-none'}`}>
                  <h3 className='paymentCardDetails-title text-center'>PAYMENT CARD DETAILS</h3>
                  <div className='paymentCardDetails-inputWrapper mt-3'>
                     <div className={`floating-control m-0 ${(creditCardError) && 'invalid'}`} >
                        <div className={`input-container d-flex `} >
                           <div className='flex-grow-1'>
                              <input type="text" name="name" id='name'
                                 className={`floating-input`}
                                 placeholder="none" autoComplete='off'
                                 value={creditCardNumber} onChange={handleCreditCardNumber} />
                              <label htmlFor="name" className="floating-label bg-white">CREDIT CARD NUMBER</label>
                           </div>
                        </div>
                     </div >
                  </div>
                  <div className='d-flex flex-wrap justify-content-center pt-3'>
                     <button className='btn btn-green m-1' onClick={handlePayClick}> PAY </button>
                     <button className='btn btn-secondary rounded-pill m-1' onClick={() => setPaymentStep(1)}> CANCEL </button>
                  </div>
               </div >

               {/* ---------------  PAYMENT SUCCESSFULL !!   -------------- */}
               <div className={`paymentSuccessfull-wrapper ${(paymentStep !== 3) && 'd-none'}`}>
                  <div className='my-4'>
                     <h2 className='paymentSuccessfull-title text-center mb-3'>PAYMENT SUCCESSFULL !!</h2>
                     <p className='px-md-5 paymentSuccessfull-note'>
                        Candidate Record is moved to Selected Candidate.
                        Please download details from section Selected
                        Candidate and Please proceed to Work Permit and onboard process.
                     </p>
                  </div>
               </div>
            </Modal.Body >
         </Modal >
         <DialogAlert
            open={open}
            handleClose={handleAlertClose}
            message={dialogMessage}
            title="Warning"
         />

      </div >
   )
}

export default EmpOffer