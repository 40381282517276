import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import getBaseUrl from '../../../hooks/http';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import './adminOnboarding.css';


const AdminOnboarding = () => {
  const { user } = useAuthContext();

  const [onboardings, setOnboardings] = useState([]);
  useEffect(() => {
    getOnboarding();
  }, [user])

  /*-----------------------------------------------*/
  const getOnboarding = async () => {
    if (user !== null) {

      let config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      try {
        const response = await axios.get(`${getBaseUrl}/admin/onboarding`, config);
        console.log(response.data);
        if (response.status === 200) {
          const data = await response.data.onboarding;

          const onboardingData = data.map(dt => {

            return {
              id: dt._id,
              date: dt.onboarding_date_time || '',
              beforeArrivalStep: dt.beforeArrivalSteps.value || '-',
              onArrivalSteps: dt.onArrivalSteps.value || '-',
              cndName: dt.candidateDetails[0].name,
              empName: dt.employerDetails[0].name,
            }
          });

          setOnboardings(onboardingData);
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const dateFormating = (date) => {
    return <>{date.toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</>
  }

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'empName',
        header: 'Employer Name',
        size: 150,
      },
      {
        accessorKey: 'cndName',
        header: 'Candidate Name',
        size: 150,
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.date),
        header: 'Selected for onboarding on',
        filterVariant: 'date-range',
        Cell: ({ cell }) => (
          <>{dateFormating(cell.getValue())}</>
        ),
      },
      {
        accessorKey: 'beforeArrivalStep',
        header: 'Before Arrival Step',
        size: 200,
        filterVariant: 'multi-select',
        filterFn: (row, id, filterValue) => {
          return (filterValue.length > 0) ? filterValue.includes(row.getValue(id)) : ''
        },
      },
      {
        accessorKey: 'onArrivalSteps',
        header: 'on Arrival Step',
        size: 200,
        filterVariant: 'multi-select',
        filterFn: (row, id, filterValue) => {
          return (filterValue.length > 0) ? filterValue.includes(row.getValue(id)) : ''
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    data: onboardings,
    columns,
    enableFacetedValues: true,
    initialState: { showColumnFilters: true },
    columnFilterDisplayMode: 'popover',
    muiTableHeadCellProps: { align: 'center', },
    muiTableBodyCellProps: { align: 'center', },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(4n) > td': { backgroundColor: '#ccc', },
        '& tr:nth-of-type(4n - 1) > td': { backgroundColor: '#ccc', },
      },
    },

  });


  return (
    <div className='adminOnboarding'>
      <div className="content">
        <h3 className='title mb-4'>Onboardings</h3>
        <div className="onboardingList container">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div>
  )
}

export default AdminOnboarding