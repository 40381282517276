import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { TfiEye } from "react-icons/tfi";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { RiDeleteBinFill } from "react-icons/ri";
import getBaseUrl from '../../../../hooks/http'

const VerifyWorkExprienceList = ({ data, onDelete, deletedWorkExperience }) => {
    const [viewModal, setViewModal] = useState({
        show: false,
        url: '',
        type: ''
    })

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
        show: false,
        id: "",
        tempId: ""
    })

    //handle doc img view model
    const handleImgView = (field) => {
        const validImageTypes = ['jpg', 'jpeg', 'png', 'webp'];
        const validOtherTypes = ['pdf'];
        let imgView;

        if (validImageTypes.includes(field)) {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'image' })
        } else if (validOtherTypes.includes(field) || field.includes('.pdf')) {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'pdf' })
        } else {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'image' })
        }
    }

    const handleDelete = (id, tempId) => {
        onDelete(id, tempId);
        setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false, id: "" });
    }

    const handleShow = (id, tempId) => {
        setDeleteConfirmationModal({ ...deleteConfirmationModal, show: true, tempId: tempId, id: id })
    }


    return (
        <div>
            <Table className="table table-bordered table-striped m-0 tb">
                {
                    (data.length > 0) &&
                    <Thead className='text-center bg-blue'>
                        <Tr>
                            <Th className='py-3' scope="col">FROM</Th>
                            <Th className='py-3' scope="col">TO</Th>
                            <Th className='py-3' scope="col">COUNTRY</Th>
                            <Th className='py-3' scope="col">DUTIES</Th>
                            <Th className='py-3' scope="col">Any Reference Letter</Th>
                            <Th className='py-3' scope="col">Action</Th>
                        </Tr>
                    </Thead>
                }
                <Tbody className="text-center">
                    {
                        data?.map((work, i) => (
                            <Tr key={work.id} className={`${(deleteConfirmationModal.id === work.id) && 'deleteHighlight'} `}>
                                <Td>
                                    {
                                        new Date(work.from).toLocaleString('en-GB', {
                                            year: 'numeric', month: 'short',
                                        }).replace(/ /g, '-')
                                    }
                                </Td>
                                <Td>
                                    {
                                        (work.present) ? "Present" :
                                            new Date(work.to).toLocaleString('en-GB', {
                                                year: 'numeric', month: 'short',
                                            }).replace(/ /g, '-')
                                    }
                                </Td>
                                <Td>{work.country}</Td>
                                <Td>{work.duties.join(', ')}</Td>
                                <Td>
                                    {(work?.refLetter) &&
                                        <div className='d-flex justify-content-center'>
                                            <div className="icon icon1" onClick={() => handleImgView(work?.refLetter)}>
                                                <TfiEye className='text-blue fs-3' />
                                                <span className="text icon-text">view</span>
                                            </div>
                                        </div>
                                    }
                                </Td>
                                <Td>
                                    <button type="button" onClick={() => handleShow(work.id, work.tempId)} className="btn btn-danger btn-sm" title="Delete"><RiDeleteBinFill /></button>
                                </Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
            {/* viewModal   */}
            <Modal show={viewModal.show} onHide={() => setViewModal({ ...viewModal, show: false })}
                backdrop="static" id='viewModal' aria-labelledby="contained-modal-title-vcenter" centered scrollable dialogClassName="w-min-content"
            >
                <Modal.Header className='p-0 justify-content-end border-0'>
                    <div className="position-relative">
                        <AiOutlineClose onClick={() => setViewModal({ ...viewModal, show: false })}
                            className='icon close-icon position-absolute' />
                    </div>
                </Modal.Header>
                <Modal.Body className='p-0 min-h-100'>
                    <div>
                        {
                            (viewModal?.type === 'image') &&
                            <div className=''>
                                <DocViewer
                                    documents={[{ uri: viewModal?.url }]}
                                    pluginRenderers={DocViewerRenderers}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: true,
                                            retainURLParams: true,
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            (viewModal?.url?.type === 'application/pdf') &&
                            <div className='d-flex justify-content-center'>
                                <DocViewer
                                    documents={[viewModal?.url].map((file) => ({
                                        uri: window.URL.createObjectURL(file),
                                        fileName: file.name,
                                    }))}
                                    pluginRenderers={DocViewerRenderers}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: true,
                                            retainURLParams: true,
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            (typeof viewModal?.url === 'string' && viewModal?.url.includes('.pdf')) &&
                            <div className=''>
                                <DocViewer
                                    documents={[{ uri: viewModal.url }]}
                                    pluginRenderers={DocViewerRenderers}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: true,
                                            retainURLParams: true,
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/*Delete confirmation Modal*/}
            <Modal backdrop="static" id='deleteModal' size="sm" show={deleteConfirmationModal.show} onHide={() => setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false, index: "" })}>
                <Modal.Header className='p-0 justify-content-end border-0'>
                    <div className="position-relative">
                        <AiOutlineClose onClick={() => setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false, index: "" })}
                            className='icon close-icon position-absolute' />
                    </div>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <p>Do you want to delete?</p>
                    <div className='d-flex justify-content-center gap-10 mt-4'>
                        <Button variant="outline-secondary" onClick={() => setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false, index: "" })}>
                            Cancel
                        </Button>&nbsp;&nbsp;
                        <Button variant="primary" type="button" onClick={() => handleDelete(deleteConfirmationModal?.id, deleteConfirmationModal?.tempId)}>
                            Yes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default React.memo(VerifyWorkExprienceList)