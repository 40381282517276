import React from 'react'
import { Navigate, Outlet } from "react-router-dom";


const ProtectedRoute = ({ children }) => {

   let Sessiondata = sessionStorage.getItem("ssid");
   const usr = JSON.parse(localStorage.getItem('user'))

     

   if (usr === null) {
      return <Navigate to={"/"} />;
   }
   if(usr!==null && Sessiondata==null)
   {
      return <Navigate to={"/MultiTabError"} />;
   }


   if(usr?.sessionId!==Sessiondata)
   {
      return <Navigate to={"/MultiBrowserError"} />;
   }


   return children ? children : <Outlet />;
};


export default ProtectedRoute