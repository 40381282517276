
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import getBaseUrl from '../../../hooks/http.js'
import axios from "axios";


export const useForgotPassword = () => {
   const navigate = useNavigate()

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)

   /*-- forgotPassword function --*/
   const forgotPassword = async (dialingCode, phoneNumber,email) => {
      setError(null)
      setIsLoading(true)

      /*regex only digit*/
      const regex = /^\d+$/;
      let inputValue = '';

      if (regex.test(phoneNumber)) {
         /*save the phoneNumber to localStorage */
         localStorage.setItem('phoneNumber', `(${dialingCode})${phoneNumber}`);
         inputValue = `${dialingCode}${phoneNumber}`.replace(/[+()]/g, "");
      } else {
         /*save the email to localStorage */
         localStorage.setItem('email', email);
         inputValue = email;
      }
      
      // axios stuff
      const body = { inputValue }
      axios.post(`${getBaseUrl}/employer/forgotPassword`, body)
         .then((res) => {
            console.log(res.data);
            if (res.status === 200) {
               setIsLoading(true);
               /*save the user to local storsge */
               localStorage.setItem('sentOtpTo', res.data.inputValue)
               /*redirect to reset password*/
               navigate('/employer/reset-password-verification-code')
            } else {
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false)
            console.log(error);
            setError(error.response.data.error);
         })
   }


   return { forgotPassword, isLoading, error }
}