import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";
import getBaseUrl from './http'


export const useLogout = () => {
   const naviagte = useNavigate()
   const { dispatch } = useAuthContext();
   const { user } = useAuthContext();

   const logout = () => {
      //console.log("user logout",user);
      //remove user from storage
      //localStorage.clear();

      //dispatch logout action
      // dispatch({type:'LOGOUT'})

      //rediredt to home
      //naviagte('/')

      //get localstorage data
      const user = JSON.parse(localStorage.getItem('user'))

      //set headers for api
      let config = {
         headers: {
            Authorization: `Bearer ${user.token}`,
         },
      };
      //check type of user
      let type = "";
      if (user.typ == "adm") {
         localStorage.clear(); //remove user from storage
         dispatch({ type: 'LOGOUT' }) //dispatch logout action
         naviagte('/')  //rediredt to home
         return 0;
      } else if (user.typ == "emp") {
         type = "Employer";
      } else {
         type = "Candidate";
      }


      const data = { type: type }
      axios.post(`${getBaseUrl}/logout`, data, config)
         .then(async (res) => {
            if (res.status === 200) {
               //remove user from storage
               localStorage.clear();
               //dispatch logout action
               dispatch({ type: 'LOGOUT' })
               //rediredt to home
               naviagte('/')
            }
         })
         .catch((error) => {
            // handle error
            console.log(error);
         })
   }

   return { logout }

} 