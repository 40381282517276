import React, { useState } from 'react'
import DatePicker from "react-datepicker";

const Reschedule = () => {

   const [date, setDate] = useState(new Date())


   const handleSubmitClick = (e) => {
      e.preventDefault();

      const RescheduledDateTime = new Date(date).toLocaleString();

      var url = "https://wa.me/6588953465?text="
         + "Rescheduled DateTime: " + RescheduledDateTime + "%0a";

      window.open(url, '_blank').focus();
   }



   return (
      <div className='reschedule '>
         <div className="container py-5">
            <div className="row bg-white">
               <div className="col-12 py-5">
                  <div className="floating-control w-50 mx-auto">
                     <div className='floating-input'>
                        <DatePicker name='InterviewDate' id='InterviewDate' className='h-100 w-100 border-0 outline-0' autoComplete='off'
                           placeholderText="Select"
                           dateFormat="dd/MM/yyyy h:mm aa"
                           isClearable
                           showTimeInput
                           showMonthDropdown
                           showYearDropdown
                           dropdownMode="select"
                           selected={date}
                           onChange={(date) => setDate(date)}
                        />
                     </div>
                     <label htmlFor="birthday" className="floating-label">Reschedule Interview</label>
                  </div>
               </div>
               <div className="col-12 text-center pb-5">
                  <button className='btn btn-primary' onClick={handleSubmitClick}>Sumbit</button>
               </div>
               <div className="col-12 text-center">
                  <label htmlFor="">Just for browser testing</label> <br /><br />
                  <input type="datetime-local" /><br /><br />
                  <input type = "date" /><br />
                  <br /><br /><br />
               </div>
            </div>
         </div>
      </div>
   )
}

export default Reschedule