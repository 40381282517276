
import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import getBaseUrl from '../../../hooks/http.js'
import axios from "axios";
import { io } from "socket.io-client";


export const useLogin = () => {

   const handleStatusUpdate = (sessionId, admId) => {
      const url = `${getBaseUrl}`.replace(/\/api/g, '')
      const socket = io.connect(url);
      socket.emit('adminLogin', { sessionId: sessionId, userId: admId });
      console.log('adminLogin');
   };


   const naviagte = useNavigate()
   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)
   const { dispatch } = useAuthContext();

   const login = async (email, password) => {
      setError(null)
      setIsLoading(true)
      const body = { email, password }
      axios.post(`${getBaseUrl}/admin/login`, body)
         .then((res) => {
            console.log(res.data);
            if (res.status === 200) {
               /* save the user to local storsge */
               dispatch({ type: 'LOGIN', payload: res.data })
               /* save the user info to local/session storage */
               sessionStorage.setItem("ssid", res.data.sessionId);
               /* rediredt to profile */
               naviagte('/admin/dashboard');
               //multi browser login handler
               handleStatusUpdate(res.data.sessionId, res.data.admId);

               setIsLoading(true)
            } else {
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false)
            setError(error?.response?.data?.error);
         })
   }

   return { login, isLoading, error }
}