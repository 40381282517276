import React, { useEffect } from 'react'
import { useLogout } from '../../../../hooks/useLogout'
import { useMailHelper } from '../../../../hooks/useMailHelper'
import { useWhatsAppHelper } from "../../../../hooks/useWhatsAppHelper";


const AccountSuccess = ({ data, candidate, changeField }) => {
   const { logout } = useLogout()
   const { mailAfterEmailChange } = useMailHelper();
   const { WAmsgAfterPhoneChange } = useWhatsAppHelper();



   useEffect(() => {
      const mailData = {
         toMail: candidate.email,
         name: candidate.name,
         newMail: data.email
      }
      if (changeField === 'email') mailAfterEmailChange(mailData);
      
      const phoneData = {
         dialingCode: data.dialingCode,
         phoneNumber: data.phoneNumber,
         name: candidate.name
      }
      if (changeField === 'phone') WAmsgAfterPhoneChange(phoneData);
   }, [])

   return (
      <div>
         {
            (changeField === 'phone')
               ? <h3 className='text-center'>Phone Number Changes Successfully.</h3>
               : <h3 className='text-center'>Email Changes Successfully.</h3>
         }
         <h4 className='text-center mb-4 text-blue'>Please Login Again.</h4>
         <div className='text-center'>
            <button className='btn btn-primary' onClick={() => logout()}>Ok</button>
         </div>

      </div>
   )
}

export default AccountSuccess