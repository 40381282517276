import { useState } from "react";
import { useNavigate } from "react-router-dom";
import getBaseUrl from '../../../hooks/http.js'
import axios from 'axios';


export const useRegister = () => {
   const navigate = useNavigate()

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)

   //check candidate already exists
   const check = (countryCallingCode, phoneNumber, password) => {
      setError(null)
      setIsLoading(true)

      phoneNumber = `(${countryCallingCode})${phoneNumber}`;

      const body = { phoneNumber }
      axios.post(`${getBaseUrl}/candidates/check`, body)
         .then((res) => {
            if (res.status === 200) {
               /*save the some info to local storsge */
               localStorage.setItem('dialingCode', countryCallingCode)
               localStorage.setItem('phoneNumber', res.data.phoneNumber)
               localStorage.setItem('password', password)
               /*rediredt to send-verification-code*/
               navigate('/candidate/send-verification-code')
            } else {
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            console.log(error);
            setError(error.response.data.error)
            setIsLoading(false)
         })
   }

   return { check, isLoading, error, setError }
}