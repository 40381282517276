import React, { useEffect } from "react";
import { useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { useWhatsAppHelper } from '../../../hooks/useWhatsAppHelper'
import { useNavigate } from "react-router-dom";



function SendOtpByWhatsApp() {
   const { sendMsg, isLoading, error } = useWhatsAppHelper();

   const navigate = useNavigate();
   const [phoneNumber, setPhoneNumber] = useState('')

   useEffect(() => {
      const phoneNumber = localStorage.getItem("phoneNumber");
      setPhoneNumber(phoneNumber);
   }, [])



   // Function to generate OTP
   const generateOTP = (len) => {
      // Declare a digits variable which stores all digits
      var digits = '0123456789';
      let OTP = '';
      for (let i = 0; i < len; i++) {
         OTP += digits[Math.floor(Math.random() * 10)];
      }
      return OTP;
   }

   //handle Send Click
   const handleSendClick = () => {
      //send msg to Number
      const to_Number = localStorage.getItem("sentOtpTo");
      //get otp
      const otp = generateOTP(6)
      //store the otp to localstorage
      localStorage.setItem('otp', otp)
      // send otp through whatsapp
      const redirect_to = '/employer/reset-password-verify-code';
      sendMsg(to_Number, otp, redirect_to);
   }





   return (
      <div className="employee_sendVerificationCode">
         <div className="wrapper">
            <h2 className='mb-4 text-center'>Reset Password</h2>
            {error && <p className='text-danger text-center'>{error}</p>}
            <p className="message text-center">
               One time Password is sent by WhatsApp to
               <br />
               <BsWhatsapp color="#25D366" /> {phoneNumber}
            </p>

            <div className="d-flex flex-wrap justify-content-evenly mt-5">
               <button className="btn btn-outline-secondary mb-3"
                  onClick={() => { navigate('/employer/login'); }}
               >CANCEL</button>
               <button disabled={isLoading} className="btn btn-blue mb-3" onClick={handleSendClick}>SEND</button>
            </div>
         </div>
      </div>
   );
}




export default SendOtpByWhatsApp