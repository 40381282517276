// program to convert first letter of a string to uppercase
export function capitalizeFirstLetter(str) {
   // converting first letter to uppercase
   if (typeof str === 'string') {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
   }
}

//program to check email is in valid format
export const validateEmail = (email) => {
   const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
   return regex.test(email.toLowerCase());
};

//program to check url Exists or not
export const urlExists = (url = '') => {
   if (url) {
      var http = new XMLHttpRequest();
      http.open('HEAD', url, false);
      http.send();
      return http.status !== 404;
   }
}
