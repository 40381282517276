import React, { memo } from 'react';
import { TfiEye } from "react-icons/tfi";
import { IoIosCloseCircle } from "react-icons/io";
import getBaseUrl from '../../../../hooks/http';


const DocumentPreview = ({ document, viewDocument, cancelDocument }) => {
   const { name, file, type } = document;
   return (<>
      {
         (file)
            ? <div className='d-flex flex-column align-items-center justify-content-end cursor-pointer'>
               {(type === 'unverified') &&
                  <span>
                     {(name === 'passport') && 'New Passport'}
                     {(name === 'educationCertificates') && 'New Certificate'}
                     {(name === 'medicalReport') && 'New Report'}
                  </span>
               }
               <img src={
                  (typeof file === 'string')
                     ? ((file.includes('pdf')) ? '/images/pdf-icon.jpeg' : `${getBaseUrl}/candidates/getMediaFileFromDb/${file}`)
                     : ((file.type === 'application/pdf') ? '/images/pdf-icon.jpeg' : URL.createObjectURL(file))
               } alt="document" className='preview' />
               <div className='d-flex gap-2'>
                  <span className='icon-text d-flex flex-column align-items-center' onClick={() => viewDocument(file)}>
                     <TfiEye className='icon-eye' />view
                  </span>
                  {(type === 'unverified') && <span className='icon-text d-flex flex-column align-items-center'>
                     <IoIosCloseCircle className='icon-close' onClick={() => cancelDocument(name)} />Cancel
                  </span>}
               </div>
            </div>
            : <span style={{ width: '80px' }} />
      }
   </>
   )
}

export default memo(DocumentPreview);