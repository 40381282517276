import React, { useEffect, useState } from 'react';
import './rangeSlider.css'


const RangeSlider = (props) => {


   const minRange = props.minValue;
   const maxRange = props.maxValue;
   const step = props.step;
   const gap = props.gap;


   const [min, setMin] = useState(minRange);
   const [max, setMax] = useState(maxRange);
   const [left, setLeft] = useState(0)
   const [right, setRight] = useState(0)

   const [zIndexMin, setZIndexMin] = useState('unset');
   const [zIndexMax, setZIndexMax] = useState('unset');





   const handleRangeChange = (e) => {

      let minVal = parseInt(e.target.name === 'min' ? e.target.value : min);
      let maxVal = parseInt(e.target.name === 'max' ? e.target.value : max);


      if ((maxVal - minVal) < gap) {
         if (e.target.name === 'min') {
            setMin(maxVal - gap)
         } else {
            setMax(minVal + gap)
         }

      } else {
         setMin(minVal);
         setMax(maxVal);

         //z-index for range
         if (e.target.name === 'min') {
            setZIndexMin(1); setZIndexMax('unset');
         } else {
            setZIndexMin('unset'); setZIndexMax(1);
         }

         //progressbar css
         if (minRange !== 0) {
            const left = (((minVal - minRange) / (maxRange - minRange)) * 100) + "%";
            setLeft(left)
            const right = 100 - ((maxVal - minRange) / (maxRange - minRange)) * 100 + "%";
            setRight(right)
         } else {
            const left = ((minVal / maxRange) * 100) + "%";
            setLeft(left)
            const right = 100 - (maxVal / maxRange) * 100 + "%";
            setRight(right)
         }
      }
   }


   useEffect(() => {
      if (props.onChange !== undefined) {
         props.onChange(min, max);
      }
   }, [min, max])





   return (
      <div className='rangeSlider'>
         <div className="slider">
            <div className="progress" style={{ left: left, right: right }}></div>
         </div>
         <div className="range-input">
            <input type="range" name='min' className="range-min p-0 m-0" value={min} min={minRange} max={maxRange} step={step}
               onChange={(e) => handleRangeChange(e)} style={{ zIndex: zIndexMin }} />
            <input type="range" name='max' className="range-max p-0 m-0" value={max} min={minRange} max={maxRange} step={step}
               onChange={(e) => handleRangeChange(e)} style={{ zIndex: zIndexMax }} />
         </div>
         <div className='range-value d-flex justify-content-between mt-2'>
            <span className="min">{minRange} Yrs</span>
            <div className='changed-value'>
               <span>{min}</span> - <span>{max}</span>
            </div>
            <span className="max">{maxRange} Yrs</span>
         </div>
      </div>
   )
}

export default React.memo(RangeSlider);