import { useState } from "react";
import getBaseUrl from '../../../hooks/http.js'
import axios from "axios";



export const useVerifyCode = () => {

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)
   const [success, setSuccess] = useState(null)


   const register = async (phoneNumber, password, name) => {

      setSuccess(null)
      setError(null);
      setIsLoading(true)

      phoneNumber = phoneNumber.replace(/[+()]/g, "");

      const body = { phoneNumber, password, name }

      axios.post(`${getBaseUrl}/employer/register`, body)
         .then((res) => {
            if (res.status === 200) {
               setSuccess(true)
               setIsLoading(true)
               //redirect verification success
               // navigate('/candidate/verification-success')
            } else {
               setSuccess(false)
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            console.log(error);
         })
   }

   return { register, error, isLoading, success }
}