import { useState } from "react";
import axios from "axios";
import getBaseUrl from "../../../../hooks/http";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../../hooks/useAuthContext";


export const useCandidateOnboarding = () => {
   const naviagte = useNavigate();
   const { user } = useAuthContext();

   const [success, setSuccess] = useState(null);
   const [error, setError] = useState(null);
   const [isLoading, setIsLoading] = useState(null);
   const [isVisible, setIsVisible] = useState(null);


   const saveStatus = async (data) => {
      setSuccess(null);
      setError(null);
      setIsLoading(true);


      let config = {
         headers: {
            Authorization: `Bearer ${user.token}`,
            // 'Content-Type': 'multipart/form-data',
         },
      };
      axios.post(`${getBaseUrl}/onboarding/status`, data, config)
         .then((res) => {
            if (res.status === 200) {
               setIsLoading(true);
               setSuccess(res.data.status);
            } else {
               setError(res?.data?.msg);
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false);
            setError(error?.response?.data?.error);
         });
   };




   return {
      saveStatus,
      error,
      success,
      isLoading,
      isVisible
   };
};
