
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import App from './App';

import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "react-datetime/css/react-datetime.css";

import './index.css';


import { AuthContextProvider } from './context/AuthContext';
import { SocketContextProvider } from './context/SocketContext';
import {CountProvider} from './context/CountContext.js'

import { RegisterProvider } from './context/RegisterContext.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <AuthContextProvider>
    <SocketContextProvider>
      <RegisterProvider>
      <CountProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router>
            <App />
          </Router>
        </LocalizationProvider>
       </CountProvider>
      </RegisterProvider>
    </SocketContextProvider>
  </AuthContextProvider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

