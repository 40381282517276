import React, { useState, useEffect } from 'react';
import axios from "axios";
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';
import { MdLocationPin } from 'react-icons/md';
import getBaseUrl from '../../hooks/http';
import { useAuthContext } from '../../hooks/useAuthContext';
import './slider.css';
import 'react-multi-carousel/lib/styles.css';



const Slider = () => {
   const navigate = useNavigate()
   const { user } = useAuthContext();
   const [candidates, setCandidates] = useState(null);
   //fetch candidate data
   const getCandidates = async () => {
      if (user !== null) {
         const { token } = user;
         let config = {
            headers: {
               Authorization: `Bearer ${user.token}`,
            },
         };

         try {
            const response = await axios.get(`${getBaseUrl}/employer/browse-candidates/`, config);
            if (response.status === 200) {
               const candidates = response.data.personalDetail;
               const cndData = candidates.map(cnd => {
                  const profilePhoto = cnd.photo.data ? `${getBaseUrl}/candidates/getMediaFileFromDb/${cnd?.photo?.data}` : '/uploads/userIcon.png';
                  return {
                     photo: profilePhoto,
                     name: cnd.name.data,
                     age: cnd.age,
                     availability: cnd.howSoonCanStart.data,
                     country: cnd.currentCountry.data,

                  }
               });

               setCandidates(cndData)
            }
         } catch (error) {
            console.log(error.message);
         }
      }
   }
   ////handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };

   useEffect(() => {
      if (user !== null) {
         getCandidates();
      }
   }, [user])




   const responsive = {
      superLargeDesktop: {
         // the naming can be any, depends on you.
         breakpoint: { max: 4000, min: 3000 },
         items: 5
      },
      desktop: {
         breakpoint: { max: 3000, min: 1400 },
         items: 4
      },
      tablet: {
         breakpoint: { max: 1400, min: 1199 },
         items: 3
      },
      mobile: {
         breakpoint: { max: 1199, min: 991 },
         items: 2.5
      },
      smallMobile: {
         breakpoint: { max: 991, min: 0 },
         items: 1
      }
   };

   return (
      <>
         {
            candidates && <>
               <Carousel responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1700}
               >
                  {
                     candidates && candidates.map((cnd, index) => {
                        return (
                           <div className='slider-container' key={index}>
                              <div className="slider-wrapper">
                                 <div className="slider-card" >
                                    <img className="profile-img" src={cnd.photo} onError={imageOnError} alt='slider-img' />
                                    <div className="name d-flex justify-content-center justify-content-sm-start">
                                       <span>{cnd.name}</span>
                                    </div>
                                    <div className='summary flex-grow-1'>
                                    <p>Age: {cnd.age}yrs</p>
                                    <p>Availability: {cnd.availability}</p>
                                    <div className="country">
                                       <MdLocationPin className='icon' />{cnd.country}
                                    </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        )
                     })
                  }
               </Carousel>
               {
                  candidates &&
                  <div className='text-center py-3'>
                     <button className='btn-browse-candidates'
                        onClick={() => { navigate('/employer/profile/browse-candidates') }}>
                        Browse Candidates
                     </button>
                  </div>
               }
            </>
         }
      </>

   )
}

export default Slider