import React, { useState, useEffect } from 'react';
import axios from "axios";
import { RiFilter2Fill } from 'react-icons/ri';
import { IoIosArrowDown } from "react-icons/io";
import { AiFillCloseCircle } from 'react-icons/ai'

import Select from '../../../../components/select/Select';
import CandidateCard from '../../../../components/candidateCard/CandidateCard';
import { RangeSlider } from '../../../../components/rangeSlider/';

import getBaseUrl from '../../../../hooks/http';
import { useAuthContext } from '../../../../hooks/useAuthContext';

import './browseCandidates.css'



//options values  for multi select ----------------------
const currentCountry = ['India', 'Malaysia', 'Sinagpore']
const nationality = ['Indian', 'Malaysian', 'Singaporean']
const skills = ['lderly Care', 'Infant care', 'Pet care']
const languages = ['English', 'Hindi', 'Chinese', 'Tamil', 'Bahasa Indonesia']


const initialFilterState = {
   location: [],
   workExperience: { max: 30, min: 0 },
   languages: [],
   nationality: [],
   age: { max: 50, min: 23 },
   skills: []
}


const BrowseCandidates = () => {
   const { user } = useAuthContext();
   const [candidates, setCandidates] = useState(null);

   const [filter, setFilter] = useState(initialFilterState)

   //open or close filter ----------------------------
   const [openFilter, setOpenFilter] = useState(false)
   const toggleFilter = () => {
      setOpenFilter((prev) => !prev)
   }
   //reset Filer -------------
   const resetFiler = () => {
      setFilter(initialFilterState)
   }

   //fetch candidate data
   const getCandidates = async () => {
      if (user !== null) {
         let config = {
            headers: {
               Authorization: `Bearer ${user.token}`,
            },
         };
         const workExperience = JSON.stringify(filter.workExperience);
         const age = JSON.stringify(filter.age);
         const search = `location=${filter.location}&languages=${filter.languages}&nationality=${filter.nationality}&skills=${filter.skills}&workExperience=${workExperience}&age=${age}`;

         try {
            const response = await axios.get(`${getBaseUrl}/employer/browse-candidates/?${search}`, config);
            if (response.status === 200) {
               const candidates = response.data.personalDetail;
               let cndData = candidates.map(cnd => {
                  const profilePhoto = cnd.photo?.data ? cnd.photo?.data : '/uploads/userIcon.png';
                  const fullPhoto = cnd.fullPhoto?.data ? cnd.fullPhoto?.data : '/uploads/userIcon.png';
                  let hasworkedInSingapore = 'No';
                  if (cnd.singaporeExperience?.data) {
                     if (cnd.singaporeExperience?.data.year || cnd.singaporeExperience?.data.month) hasworkedInSingapore = 'Yes';
                  }

                  const dob = new Date(cnd.birthday?.data).toLocaleString('en-GB', {
                     year: 'numeric', month: 'short', day: '2-digit',
                  }).replace(/ /g, '-')
                  let  OnArrivalsteps ="";
                  if (cnd.onboardings && cnd.onboardings.length > 0) {
                  if(['CandidateWorkStarted', 'CandidateSelectionCancelled','BeforeArrivalStepsCompleted'].includes(cnd.onboardings[0].beforeArrivalSteps.value)
                  && ['CandidateWorkStarted', 'CandidateSelectionCancelled'].includes(cnd.onboardings[0].onArrivalSteps.value)){
                     OnArrivalsteps ="No";
                  }else{
                     OnArrivalsteps ="YES";
                  }
               }
                  return {
                     candID: cnd.candidateId,
                     EmpId: localStorage.getItem('empId'),
                     photo: profilePhoto,
                     fullPhoto: fullPhoto,
                     video: cnd.video?.data,
                     name: cnd.name?.data,
                     dob: dob,
                     age: cnd?.age,
                     availability: cnd.howSoonCanStart?.data,
                     country: cnd.currentCountry?.data,
                     languages: cnd.languages?.data,
                     skills: cnd.skills?.data,
                     cooking: cnd.cooking?.data || '-',
                     expectedSalary: cnd.expectedSalary?.data || '-',
                     hasworkedInSingapore: hasworkedInSingapore?.data,
                     date: new Date(),
                     reschedule: {
                        date: new Date(),
                        isShow: false
                     },
                     nationality: cnd.nationality?.data,
                     maritalStatus: cnd.maritalStatus?.data,
                     childAge: cnd.childAge?.data,
                     yearOfExperience: cnd.yearOfExperience?.data,
                     workExperience: cnd.workExperience?.data,
                     personality: cnd.personality?.data,
                     inProgress: cnd?.interviews[0]?.InProgress || cnd?.offers[0]?.InProgress || OnArrivalsteps || ''
                  }
               });

               setCandidates(cndData)
            }
         } catch (error) {
            console.log("Error", error);
         }
      }
   }

   useEffect(() => {
      if (user !== null) {
         getCandidates();
      }
   }, [user, filter])


   //set Filter data --------------------------
   const handleMultiselect = (value, name) => {
      setFilter({ ...filter, [name]: value });
   }

   const handleWorkExperience = (min, max) => {
      setFilter({ ...filter, workExperience: { min, max } });
   }

   const handleAge = (min, max) => {
      setFilter({ ...filter, age: { min, max } });
   }





   return (
      <div className='emp-browse-candidates'>
         <div className='text-center pt-2'>
            <button className={`btn-filter ${(openFilter === true) ? 'opacity-0' : ''}`} onClick={toggleFilter}>
               Filter <img src="/images/filter-icon.jpg" alt="filter" />
            </button>
         </div>
         <div className="container py-4">
            <div className="d-flex browse-candidate-wrapper">
               <div className={`candidate-filter me-5 ${(openFilter === true) ? 'd-block' : ''}`}>
                  <div className="candidate-filter-wrapper p-2">
                     <div className='closeFilter'>
                        <AiFillCloseCircle className='close' onClick={toggleFilter} />
                     </div>
                     {/* <h3 className='text-center f-25'>I would like to Filter:</h3>*/}
                     <div className="filter d-flex justify-content-between">
                        <div >
                           <h4 className='text-red'>Filter by:</h4>
                        </div>
                        <div >
                           <span className='filter-container d-inline-flex flex-column align-items-center cursor-pointer' onClick={resetFiler}>
                              <RiFilter2Fill className='filter-icon' />
                              <span className='text fs-13'>Reset Filter</span>
                           </span>
                        </div>
                     </div>
                     <div className="candidateLocation my-4">
                        <h3 className='f-20 text-red'>
                           Candidate’s Location:
                           <p className='m-0 fs-13 text-grey'>Filter candidates by their current location</p>
                        </h3>
                        <Select selectedValue={filter.location} options={currentCountry} optionsArrayObject={false}
                           onChange={(value) => handleMultiselect(value, 'location')} arrow={<IoIosArrowDown />} multiple
                           classNamePrefix='filterMultiSelect' />
                     </div>
                     <div className="workExperience my-4">
                        <h3 className='f-20 text-red'>Work Experience:</h3>
                        <RangeSlider minValue={0} maxValue={30} step={1} gap={0} onChange={handleWorkExperience} />
                     </div>
                     <div className="languages my-4">
                        <h3 className='f-20 text-red'>Can speak Languages:</h3>
                        <Select selectedValue={filter.languages} options={languages} optionsArrayObject={false}
                           onChange={(value) => handleMultiselect(value, 'languages')} arrow={<IoIosArrowDown />} multiple
                           classNamePrefix='filterMultiSelect' />
                     </div>

                     <div className="nationality my-4">
                        <h3 className='f-20 text-red'>Nationality:</h3>
                        <Select selectedValue={filter.nationality} options={nationality} optionsArrayObject={false}
                           onChange={(value) => handleMultiselect(value, 'nationality')} arrow={<IoIosArrowDown />} multiple
                           classNamePrefix='filterMultiSelect' />
                     </div>
                     <div className="candidateAge my-4">
                        <h3 className='f-20 text-red'>Candidate’s Age:</h3>
                        <RangeSlider minValue={23} maxValue={50} step={1} gap={0} onChange={handleAge} />
                     </div>
                     <div className="skills my-4">
                        <h3 className='f-20 text-red'>Skills:</h3>
                        <Select selectedValue={filter.skills} options={skills} optionsArrayObject={false}
                           onChange={(value) => handleMultiselect(value, 'skills')} arrow={<IoIosArrowDown />} multiple
                           classNamePrefix='filterMultiSelect' />
                     </div>
                  </div>

               </div>
               <div className="candidates-list flex-grow-1">
                  <CandidateCard value={{ candidates }} onChange={{ setCandidates }} />
               </div>
            </div>
         </div>
      </div>
   )
}

export default React.memo(BrowseCandidates)