import React, { useState, useEffect, useContext } from 'react';
//Showing Popup
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from '@mui/material/DialogContentText';
import { Container, Row, Col, Button, Table, Modal, Spinner, } from 'react-bootstrap';

const customAlertModal = ({ open, handleClose, message, title }) => {
    return (
        <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
     >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
           <h5><img src="/images/REASON.png" id="DataAlert"></img> Attention Required</h5>
        </DialogTitle>
        <IconButton
           aria-label="close"
           onClick={handleClose}
           sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
           })}
        >
           <CloseIcon />
        </IconButton>
        <DialogContent>
           <DialogContentText id="alert-dialog-description">
              {message}
           </DialogContentText>
        </DialogContent>
        <DialogActions>
           <Button onClick={handleClose}>OK</Button>

        </DialogActions>
     </Dialog>
    );
  };
  
  export default customAlertModal;