
import {React , useEffect ,useState} from 'react'
import { Navigate,Outlet } from 'react-router-dom'
import EmployeeNavbar from '../components/employeeNavbar/EmployeeNavbar';
import { useNavigate } from "react-router-dom";


const EmployeeLayout= () => {
   const naviagte = useNavigate();
   const handleClick = (e) => {
      let Sessiondata = sessionStorage.getItem("ssid");
      const usr = JSON.parse(localStorage.getItem('user'))
      if(usr?.sessionId!==Sessiondata)
      {
         naviagte('/MultiBrowserError')
        // return <Navigate to={"/"} />;
      }
     
    }


    
    return (
        <div className='candidateLayout bg-white h-100' onClick={handleClick}>
           <EmployeeNavbar/>
           <div className='EmployerBody'>
              <Outlet/>
           </div>
        </div>
     )
}

export default EmployeeLayout