import React, { useState } from 'react'
import { useLogin } from './useAdminLogin';
import './adminLogin.css';




function AdminLogin() {

   const { login, error, isLoading } = useLogin()


   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')


   // handle login click
   const handleSubmit = async (e) => {
      e.preventDefault();
      await login(email, password)
   }



   return (
      <div className='Admin-Login bg-white'>
         <div className="form-wrapper">
            <form className='form-login px-4 px-sm-5 py-4 ' onSubmit={handleSubmit}>
               <h2 className='mb-4 text-white'>Login</h2>

               {error && <div className='error'>{error}</div>}

               <div className="mb-3">
                  <div className="floating-control">
                     <input type="email" name="email" id="email" className="floating-input"
                        placeholder="none" autoComplete='off' required
                        onChange={(e) => setEmail(e.target.value)} value={email} />
                     <label htmlFor="email" className="floating-label">Email</label>
                  </div>
               </div>

               <div className="mb-3">
                  <div className="floating-control">
                     <input type="password" name="password" className="floating-input"
                        placeholder="none" autoComplete='off' required
                        onChange={(e) => setPassword(e.target.value)} value={password} />
                     <label htmlFor="password" className="floating-label">Password</label>
                  </div>
               </div>
               <button disabled={isLoading} type="submit" className="btn btn-light w-50 mt-1 mb-3">Login</button>
            </form>
         </div>
      </div>
   )
}

export default AdminLogin;
