import React, { useEffect } from 'react'
import './verifyCode.css';

import OTPInput from "otp-input-react";
import { useState } from 'react';
import { BsWhatsapp } from "react-icons/bs";
import { useVerifyCode } from './useVerifyCode';
import { useWhatsAppHelper } from '../../../hooks/useWhatsAppHelper';
import { useNavigate } from "react-router-dom";
import { useSocketContext } from  '../../../hooks/useSocketContext';


import VerificationSuccess from './VerificationSuccess';


function VerifyCode() {
   const navigate = useNavigate();
   const socket = useSocketContext()

   const { register, success } = useVerifyCode();
   const { sendMsg } = useWhatsAppHelper();

   const [error, setError] = useState(null)
   const [errorMessage, setErrorMessage] = useState('');
   const [OTP, setOTP] = useState("");
   const [phoneNumber, setPhoneNumber] = useState('')

   const handleOtpStatus = data => {
      const phoneNumber = localStorage.getItem("phoneNumber");
      const cleanedNumber = phoneNumber.replace(/[()+-]/g, '');
       if (data.status === 'failed') {
         if(data.phoneNumber===cleanedNumber)
         {
            //setErrorMessage('Not a valid number on WhatsApp');
            navigate('/candidate/register',{ state: { errorMsg: 'Not a valid number on WhatsApp' } })
         }
     } else {
         //setErrorMessage(''); // Clear any previous error message
     }
   }

   useEffect(() => {
      const phoneNumber = localStorage.getItem("phoneNumber");
      setPhoneNumber(phoneNumber);
      socket.on('sendOtpStatusVerified', handleOtpStatus);
   }, [])




   const handelVerifyClick = () => {
      const password = localStorage.getItem('password')
      const confirm_otp = localStorage.getItem("otp");
      const dialingCode = localStorage.getItem("dialingCode");
      const name=localStorage.getItem("Name");

      if (OTP === '') {
         setError('OTP is required')
      } else {
         if (confirm_otp === OTP) {
            register(name,dialingCode,phoneNumber, password)
         } else {
            setError('Invalid OTP')
         }
      }
   }



   // Function to generate OTP
   const generateOTP = (len) => {
      // Declare a digits variable which stores all digits
      var digits = '0123456789';
      let OTP = '';
      for (let i = 0; i < len; i++) {
         OTP += digits[Math.floor(Math.random() * 10)];
      }
      return OTP;
   }

   const handelResendClick = () => {
      //replace (+) to '' from no.
      const to_Number = phoneNumber.replace(/[+()]/g, "");
      const otp = generateOTP(6)
      //store the otp to localstorage
      localStorage.setItem('otp', otp)
      // send otp to whatsapp
      sendMsg(to_Number, otp);
   }

   return (
      <>
         {(!success) ?
            <div className='verifyCode text-center'>
               <h3 className='title mb-4'>Enter verification code</h3>
               <p className='message'>
                  Your verification code is sent by WhatsApp to
                  <br />
                  <BsWhatsapp color="#25D366" />  {phoneNumber.replace(/\)/g, ")-")}
               </p>

               <div className="d-flex justify-content-center my-5 otp-wrapper h4">
                  <OTPInput value={OTP} onChange={setOTP} autoFocus="true" OTPLength={6}
                     otpType="number" disabled={false} />
               </div>
               <p className='resend-msg'>
                  Did not receive the code?
                  <span className='resend-span px-2'>
                     <button onClick={handelResendClick}>Resend</button>
                  </span>
               </p>
               <button className='btn btn-red btn-verify' onClick={handelVerifyClick}>VERIFY</button>
               {error && <p className='text-danger text-center'>{error}</p>}
            </div>
            :
            <VerificationSuccess />
         }
      </>

   )
}

export default VerifyCode;